import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import { Button, Modal } from './styles';
import CreditCard from '../CreditCard';

import addCard from '~/assets/icons/add-card-icon.svg';
import closeIcon from '~/assets/icons/close.svg';
import { useFunnel } from '~/hooks/Funnel';

interface ICreditCard {
  id?: string;
  name: string;
  number: string;
  expiration: string;
  cvc: string;
  flag: string;
  primary_card?: boolean;
}

interface IReponseCreditCard {
  id: string;
  name: string;
  number: string;
  expiration: string;
  flag: string;
  primary_card: boolean;
}

interface IAddCreditCardProps {
  className?: string;
  onCreatedCard?(data: IReponseCreditCard): void;
  active?: boolean;
  onOpen?(): void;
  onClose?(active: boolean): void;
}

const AddCreditCard: React.FC<IAddCreditCardProps> = ({
  className,
  onCreatedCard,
  active,
  onOpen,
  onClose,
}) => {
  const { language } = useLanguage();
  const { user } = useFunnel();
  const formRef = useRef<FormHandles>(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(active as boolean);
  }, [active]);

  const handleShow = useCallback(() => {
    if (onOpen) {
      onOpen();
    } else {
      setShow(true);
    }
  }, [onOpen]);
  const handleClose = useCallback(() => {
    setShow(false);
    if (onClose) {
      onClose(active as boolean);
    }
  }, [active, onClose]);

  const handleSubmit = useCallback(
    async (data: ICreditCard) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required(language.add_credit_card.schema_name),
          number: Yup.string().required(language.add_credit_card.schema_number),
          expiration: Yup.string().required(
            language.add_credit_card.schema_expiration
          ),
          cvc: Yup.string().required(language.add_credit_card.schema_cvc),
          flag: Yup.string().required(language.add_credit_card.schema_flag),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { name, number, expiration, cvc, flag } = data;
        const [exp_month, exp_year] = expiration.split('/');

        const formData = {
          student_id: user.id,
          name,
          number: number.replace(/ /g, ''),
          exp_month,
          exp_year,
          cvc,
          primary_card: false,
        };

        const response = await api.post(
          'payments/students/credit-card',
          formData
        );

        if (response.data) {
          const numberMask = `****-${number.substr(-4)}`;
          const creditCardForm: IReponseCreditCard = {
            id: response.data.card.id,
            name,
            number: numberMask,
            expiration,
            flag,
            primary_card: false,
          };
          if (onCreatedCard) {
            onCreatedCard(creditCardForm);
          }
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Opss...', language.add_credit_card.swal_error, 'error');
        }
      }
    },
    [
      language.add_credit_card.schema_cvc,
      language.add_credit_card.schema_expiration,
      language.add_credit_card.schema_flag,
      language.add_credit_card.schema_name,
      language.add_credit_card.schema_number,
      language.add_credit_card.swal_error,
      onCreatedCard,
      user.id,
    ]
  );

  return (
    <>
      <Button type="button" className={`${className}`} onClick={handleShow}>
        <img src={addCard} alt="Add Card" className="mr-3 width-add-card" />
        {language.add_credit_card.button}
      </Button>
      <Modal
        className="modal-sales-funnel"
        centered
        size="lg"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="border-0 p-4">
          <Modal.Title className="px-2">
            {language.add_credit_card.title}
          </Modal.Title>
          <button
            type="button"
            className="close-button border-0 bg-transparent"
            onClick={handleClose}
          >
            <img src={closeIcon} alt="Close" />
          </button>
        </Modal.Header>
        <Modal.Body className="mx-auto w-100">
          <Form ref={formRef} onSubmit={handleSubmit}>
            <CreditCard className="d-lg-flex align-items-center" />
            <div className="row mt-2 justify-content-end">
              <div className="col-lg-6">
                <button type="submit" className="mt-5 btn-black border-0 w-100">
                  <span className="text-white font-weight-600">
                    {language.add_credit_card.button_span}
                  </span>
                </button>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="border-0" />
      </Modal>
    </>
  );
};

export default AddCreditCard;
