import React, { useEffect, useState } from 'react';

import { Container, Menu } from './styles';

import star from '~/assets/icons/star.svg';

const HeaderLp: React.FC = () => {
  const [active, setActive] = useState(false);
  const [mode, setMode] = useState('students');

  useEffect(() => {
    setActive(false);
  }, []);

  return (
    <>
      <Container className="menu-header py-2 py-lg-3">
        <div className="container-fluid container-lg container-xxl">
          <div className="row">
            <div className="col-12 d-flex justify-content-between align-items-center text-center">
              <div className="logo">Wiserr</div>

              <Menu active={active}>
                <button
                  type="button"
                  className="bg-transparent d-lg-none border-0 mx-3"
                  onClick={() => setActive(!active)}
                >
                  <span />
                  <span />
                  <span />
                </button>
                <div className="position overflow overflow-auto menu-group active">
                  {false && (
                    <div className="d-flex menu">
                      <div>
                        <button
                          type="button"
                          className={`${
                            mode === 'students' && 'active-students-creators'
                          }`}
                          onClick={() => setMode('students')}
                        >
                          Students
                        </button>
                        <hr
                          className={`${
                            mode === 'students'
                              ? 'active-hr mt-1 mb-0'
                              : 'd-none'
                          }`}
                        />
                      </div>

                      <div className="ml-lg-5">
                        <button
                          type="button"
                          className={`${
                            mode === 'creators' && 'active-students-creators'
                          }`}
                          onClick={() => setMode('creators')}
                        >
                          Creators
                        </button>
                        <hr
                          className={`${
                            mode === 'creators'
                              ? 'active-hr mt-1 mb-0'
                              : 'd-none'
                          }`}
                        />
                      </div>
                    </div>
                  )}
                  <div className="d-lg-none w-75 w-sm-50 mx-auto mt-5">
                    <a
                      href="https://app.wiserr.io/sign-up"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="create justify-content-center"
                    >
                      <img src={star} alt="Star" /> Create Account
                    </a>
                  </div>
                </div>
              </Menu>
              <div className="d-none d-lg-block">
                <a
                  href="https://app.wiserr.io/sign-up"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="create"
                >
                  <img src={star} alt="Star" /> Create Account
                </a>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default HeaderLp;
