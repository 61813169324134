import styled from 'styled-components';
import { lighten } from 'polished';

import bgContact from '~/assets/banners/bg-contact1.png';

export const Container = styled.div`
  p {
    color: #6e6e6e;
  }

  label {
    color: #606060;
  }

  h2 {
    color: #242526;
    span {
      background: #fb5a43;
      background: linear-gradient(to left, #fb5a43 0%, #c341ae 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  button {
    background-color: #000;
    color: #fff;
    border-radius: 15px;
    height: 56px;

    :hover {
      background-color: ${lighten(0.1, '#000')};
      color: #fff;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3) !important;
    }
  }

  @media screen and (min-width: 992px) {
    background-image: url(${bgContact});
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    height: 65vw;
  }

  @media screen and (min-width: 1900px) {
    height: 100vh;
  }

  @media screen and (max-width: 1199px) {
  }

  @media screen and (max-width: 991px) {
    .textarea-mobile {
      height: 115px;
    }
  }
`;
