import styled from 'styled-components';
import { lighten, darken } from 'polished';

import { Modal as ModalComponent } from 'react-bootstrap';

export const Button = styled.button``;

export const Modal = styled(ModalComponent)`
  h3 {
    font-size: 24px;
    font-weight: 600;
    color: #626262;
  }
  .gray-button {
    :hover {
      background-color: ${lighten(0.1, '#4a4b4b')}!important;
      transition: 0.5s;
    }
  }

  .bg-darken {
    background-color: ${darken(0.1, '#f2f2f2')}!important;
    transition: 0.5s;
  }

  .modal-content {
    background: #ffffff;
    box-shadow: 19px 49px 112px rgba(192, 192, 192, 0.4),
      inset 1px 1px 2px rgba(255, 255, 255, 0.3),
      inset -1px -1px 2px rgba(138, 138, 138, 0.5);
    border-radius: 30px;
    border: 0 !important;
  }

  .btn-black {
    background-color: #18191a;
    border-radius: 15px;
    font-size: 18px;
    padding: 10.5px 20px;

    :hover {
      background-color: ${lighten(0.1, '#18191a')};
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
    }
  }

  .terms {
    h4,
    p {
      font-size: 10px;
      color: #ababab;
    }
  }

  .bg-payment-cards {
    background-color: #f2f2f2;
    border-radius: 15px;
  }

  @media screen and (max-width: 430px) {
    p {
      font-size: 13px;
    }

    .bg-payment-cards img {
      height: 25px;
    }

    .pad-mobile {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
`;
