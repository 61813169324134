import styled from 'styled-components';
import { lighten } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Button = styled.button`
  button {
    .btn-no-tanks {
      color: #ababab;
      font-size: 20px;
      width: 200px;
      :hover {
        text-decoration: underline;
        box-shadow: none !important;
        color: #ababab;
      }
    }
  }
`;

export const Modal = styled(ModalComponent)`
  h2 {
    font-size: 22px;
    color: #606060;
  }

  p {
    font-size: 22px;
    font-weight: 400;
    color: #ababab;
    line-height: 27px;
    span {
      color: #242526;
    }
  }

  button {
    background: #2f80ed;
    border-radius: 20px;
    padding: 13px 20px;
    color: #fff;
    border: 0;
    font-weight: bold;
    :hover {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
    }
  }

  video {
    border-radius: 21px;
    width: 100%;
    max-width: 1000px;
  }

  .btn:focus {
    box-shadow: none;
  }

  .btn-transparent {
    background: transparent;
    border-radius: 20px;
    padding: 12px 20px;
    border: 1px solid #8c8c8c;
    font-weight: 500;
    color: #606060;
    :hover {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
    }
  }

  .modal-content {
    background: #ffffff;
    box-shadow: 19px 49px 112px rgba(192, 192, 192, 0.4),
      inset 1px 1px 2px rgba(255, 255, 255, 0.3),
      inset -1px -1px 2px rgba(138, 138, 138, 0.5);
    border-radius: 30px;
    border: 0 !important;
  }

  .close-button {
    background-color: transparent;
    :hover {
      box-shadow: none !important;
    }
  }

  @media screen and (max-width: 991px) {
    p {
      font-size: 18px;
    }

    button,
    a {
      font-size: 14px;
    }
    .btn-transparent {
      font-weight: bold;
      color: rgba(96, 96, 96, 0.36);
      background-color: rgba(96, 96, 96, 0.1);
      border: unset;
    }
  }

  @media screen and (max-width: 575px) {
    p {
      font-size: 16px;
    }
  }
`;

export const ModalStep = styled(ModalComponent)`
  h2 {
    font-size: 18px;
    color: #606060;
  }
  label {
    color: #8c8c8c;
    font-size: 14px;
  }
  .height {
    height: 58px;
    width: 58px;
  }
  .step {
    div {
      background-color: #d6d6d6;
      border: solid 2px #d6d6d6;

      span {
        font-size: 24px;
        color: #fff;
      }
    }
    p {
      color: #8c8c8c;
      font-size: 18px;
    }
  }

  hr {
    background-color: #b7b7b7;
  }

  .terms {
    h4,
    p {
      font-size: 10px;
      color: #ababab;
    }
  }

  .selected-step {
    div {
      border: 1px solid #37c694;
      background-color: #fff;
      span {
        font-size: 24px;
        color: #37c694;
      }
    }
    p {
      color: #606060;
      font-weight: 600;
      font-size: 18px;
    }
  }

  .active-step {
    div {
      background-color: #37c694;
      border: solid 2px #37c694;

      span {
        font-size: 24px;
        color: #fff;
      }
    }
    p {
      color: #8c8c8c;
      font-size: 18px;
    }
  }

  .active-hr {
    background-color: #37c694;
  }

  .bg-input {
    background-color: #f7f7f7;
    border: 0;
  }

  .btn-black {
    background-color: #18191a;
    border-radius: 15px;
    font-size: 24px;
    padding: 10.5px 20px;

    :hover {
      background-color: ${lighten(0.1, '#18191a')};
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
    }
  }

  .modal-content {
    background: #ffffff;
    box-shadow: 19px 49px 112px rgba(192, 192, 192, 0.4),
      inset 1px 1px 2px rgba(255, 255, 255, 0.3),
      inset -1px -1px 2px rgba(138, 138, 138, 0.5);
    border-radius: 30px;
    border: 0 !important;
  }

  .close-button {
    background-color: transparent;
  }

  .btn-credit {
    color: #ababab !important;
    font-size: 14px;
  }

  .items {
    h2 {
      color: #081f32;
      font-size: 22px;
    }
    h3 {
      font-size: 18px;
    }
    h4 {
      font-size: 14px;
      span {
        font-size: 14px;
      }
    }
    p {
      font-size: 12px;
      color: #6e798c;
    }

    .gradient-items {
      background: linear-gradient(
        270.03deg,
        #fb5a43 0.02%,
        #c341ae 92.68%,
        #c341ae 99.97%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    span {
      font-size: 18px;
      color: #8c8c8c;
    }

    .btn-cart {
      background-color: #ababab;
      border-radius: 10px;
      padding: 7.5px 20px;
      color: #fff;
      font-size: 14px;

      :hover {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
      }
    }

    .bd-box {
      background: #ffffff;
      box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.05);
      border-radius: 16px;
    }
  }
`;
