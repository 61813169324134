import styled from 'styled-components';

export const Container = styled.div`
  background: #181818;
  padding-bottom: 117px;
  padding-top: 205px;
  p,
  a {
    color: #dadada;
    line-height: 26px !important;
  }

  button {
    color: #000;
    background-color: #f7f7f7;
    position: fixed;
    z-index: 10000;
    bottom: 50px;
    right: 16%;
    height: 55px;
  }

  @media screen and (max-width: 991px) {
    button {
      bottom: 20px;
      right: 5%;
      height: 40px;
    }
  }

  @media screen and (max-width: 767px) {
    padding-bottom: 60px;
    padding-top: 110px;
    .mg-bottom {
      margin-bottom: 70px;
    }
  }
`;
