import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Header from '~/components/Header';
import Footer from '~/components/Footer';
import HeaderLp from '~/components/HeaderLp';
import FooterLp from '~/components/FooterLp';

const LandingPage: React.FC = ({ children }) => {
  const location = useLocation();
  const salesFunnel = location.pathname.match('/sales-funnel');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {location.pathname === '/' ? <HeaderLp /> : <Header />}

      {children}
      <div>
        {salesFunnel || location.pathname !== '/' ? <Footer /> : <FooterLp />}
      </div>
    </>
  );
};

export default LandingPage;
