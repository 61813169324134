import React from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import { useLanguage } from '~/hooks/Language';

import { Container } from './styles';

const Attention: React.FC = () => {
  const { language } = useLanguage();
  return (
    <Container className="bg-danger">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2 className="h6 h5-sm h3-xl text-white mb-0 py-3 text-center">
              <FaExclamationCircle />
              <b className="ml-3">{language.attention_component.h2_b_1} </b>
              {language.attention_component.h2_1}{' '}
              <b className="font-weight-600">
                <u>{language.attention_component.h2_b_u}</u>
              </b>{' '}
              {language.attention_component.h2_2}
            </h2>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Attention;
