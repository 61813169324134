import React from 'react';
import { useLanguage } from '~/hooks/Language';

import { Container } from './styles';
import Contact from '~/components/Contact';

const PrivacyPolicy: React.FC = () => {
  const { language } = useLanguage();

  return (
    <>
      <Container>
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <h1>
                Wiserr.io
                <br />
                {language.privacyPolicy.h1}
              </h1>
              <hr className="mt-3 mb-5" />
              <div className="mb-5">
                <h2 className="h3">{language.privacyPolicy.h2_1}</h2>
                <p>{language.privacyPolicy.p_1}</p>
                <p>{language.privacyPolicy.p_2}</p>
                <p>{language.privacyPolicy.p_3}</p>
                <p>
                  {language.privacyPolicy.p_4}{' '}
                  <a href="mailto:privacy@wiserr.io">privacy@wiserr.io</a>.
                </p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.privacyPolicy.h2_2}</h2>
                <p>{language.privacyPolicy.p_5}</p>
                <ul>
                  <li>{language.privacyPolicy.li_1}</li>
                  <li>{language.privacyPolicy.li_2}</li>
                  <li>{language.privacyPolicy.li_3}</li>
                  <li>{language.privacyPolicy.li_4}</li>
                </ul>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.privacyPolicy.h2_3}</h2>
                <p>{language.privacyPolicy.p_6}</p>
                <p>{language.privacyPolicy.p_7}</p>
                <p>{language.privacyPolicy.p_8}</p>
                <p>{language.privacyPolicy.p_9}</p>
                <p>{language.privacyPolicy.p_10}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.privacyPolicy.h2_4}</h2>
                <p>{language.privacyPolicy.p_11}</p>
                <ul>
                  <li>{language.privacyPolicy.li_5}</li>
                  <li>{language.privacyPolicy.li_6}</li>
                  <li>{language.privacyPolicy.li_7}</li>
                  <li>{language.privacyPolicy.li_8}</li>
                  <li>{language.privacyPolicy.li_9}</li>
                  <li>{language.privacyPolicy.li_10}</li>
                  <li>{language.privacyPolicy.li_11}</li>
                  <li>{language.privacyPolicy.li_12}</li>
                  <li>{language.privacyPolicy.li_13}</li>
                  <li>{language.privacyPolicy.li_14}</li>
                  <li>{language.privacyPolicy.li_15}</li>
                  <li>{language.privacyPolicy.li_16}</li>
                </ul>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.privacyPolicy.h2_5}</h2>
                <p>
                  {language.privacyPolicy.p_12}{' '}
                  <a href="mailto:privacy@wiserr.io">privacy@wiserr.io</a>.
                </p>
                <p>{language.privacyPolicy.p_13}</p>
                <p>
                  {language.privacyPolicy.p_14}{' '}
                  <a href="mailto:privacy@wiserr.io">privacy@wiserr.io</a>.
                </p>
                <p>{language.privacyPolicy.p_15}</p>
                <p>
                  {language.privacyPolicy.p_16}{' '}
                  <a href="mailto:privacy@wiserr.io">privacy@wiserr.io</a>.
                </p>
                <p>{language.privacyPolicy.p_17}</p>
                <p>{language.privacyPolicy.p_18}</p>
                <p>{language.privacyPolicy.p_19}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.privacyPolicy.h2_6}</h2>
                <p>{language.privacyPolicy.p_20}</p>
                <p>{language.privacyPolicy.p_21}</p>
                <p>{language.privacyPolicy.p_22}</p>
                <p>
                  {language.privacyPolicy.p_23}{' '}
                  <a
                    href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
                    target="blank"
                    className="word-break"
                  >
                    http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html
                  </a>
                </p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.privacyPolicy.h2_7}</h2>
                <p>{language.privacyPolicy.p_24}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.privacyPolicy.h2_8}</h2>
                <p>{language.privacyPolicy.p_25}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.privacyPolicy.h2_9}</h2>
                <p>{language.privacyPolicy.p_26}</p>
                <p>
                  <u>{language.privacyPolicy.p_27_u}</u>
                  {language.privacyPolicy.p_27}
                </p>
                <p>
                  <u>{language.privacyPolicy.p_28_u}</u>
                  {language.privacyPolicy.p_28}
                </p>
                <p>
                  <u>{language.privacyPolicy.p_29_u}</u>
                  {language.privacyPolicy.p_29}
                </p>
                <p>
                  <u>{language.privacyPolicy.p_30_u}</u>
                  {language.privacyPolicy.p_30}
                </p>
                <p>
                  <u>{language.privacyPolicy.p_31_u}</u>
                  {language.privacyPolicy.p_31}
                </p>
                <p>{language.privacyPolicy.p_32}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.privacyPolicy.h2_10}</h2>
                <p>{language.privacyPolicy.p_33}</p>
                <p>{language.privacyPolicy.p_34}</p>
                <p>{language.privacyPolicy.p_35}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.privacyPolicy.h2_11}</h2>
                <p>{language.privacyPolicy.p_36}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.privacyPolicy.h2_12}</h2>
                <p>{language.privacyPolicy.p_37}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.privacyPolicy.h2_13}</h2>
                <p>{language.privacyPolicy.p_38}</p>
                <p className="my-2">
                  <a href="mailto:privacy@wiserr.io">privacy@wiserr.io</a>
                </p>
                <p className="my-2">{language.privacyPolicy.p_39}</p>
                <p className="my-2">Wiserr, Inc.</p>
                <p className="my-2">30 N Gould St Ste 21152, </p>
                <p className="my-2">Sheridan, WY, 92801</p>
                <p className="mt-2 mb-4">
                  <a href="tel:8447770500">(844) 777 0500</a>
                </p>
                <p>{language.privacyPolicy.p_40}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.privacyPolicy.h2_14}</h2>
                <p>{language.privacyPolicy.p_41}</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Contact />
    </>
  );
};

export default PrivacyPolicy;
