import React from 'react';

import { useLanguage } from '~/hooks/Language';

import { Container } from './styles';
import Contact from '~/components/Contact';

const CopyrightNotice: React.FC = () => {
  const { language } = useLanguage();

  return (
    <>
      <Container>
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <h1>
                Wiserr.io
                <br />
                {language.copyRight.h1}
              </h1>
              <hr className="mt-3 mb-5" />
              <div className="mb-5">
                <h2 className="h3">{language.copyRight.h2}</h2>
                <p>{language.copyRight.p_1}</p>
                <p>{language.copyRight.p_2}</p>
                <p>{language.copyRight.p_3}</p>
                <ul>
                  <li>{language.copyRight.li_1}</li>
                  <li>{language.copyRight.li_2}</li>
                  <li>{language.copyRight.li_3}</li>
                  <li>{language.copyRight.li_4}</li>
                  <li>{language.copyRight.li_5}</li>
                  <li>{language.copyRight.li_6}</li>
                </ul>
                <p>{language.copyRight.p_4}</p>
                <p className="my-2">Wiserr, Inc.</p>
                <p className="my-2">30 N Gould St Ste 21152, </p>
                <p className="my-2">Sheridan, WY, 92801</p>
                <p className="mt-2 mb-4">
                  <a href="tel:8447770500">(844) 777 0500</a>
                </p>
                <p className="my-2">
                  Email:{' '}
                  <a href="mailto:support@wiserr.com">support@wiserr.com</a>
                </p>
                <p>{language.copyRight.p_5}</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Contact />
    </>
  );
};

export default CopyrightNotice;
