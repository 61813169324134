import styled from 'styled-components';
import bgBookFhd from '~/assets/banners/bg-book.png';
import bgBookHd from '~/assets/banners/bg-book-laptop.png';
import bgBookTb from '~/assets/banners/bg-book-tablet.png';

export const Container = styled.div`
  background-image: url(${bgBookFhd});
  min-height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;

  h1,
  p {
    color: #131313;
    font-size: small;
  }

  strong {
    font-weight: 800;
  }

  a {
    font-size: 1.5rem;
    border: 0;
    border-radius: 12px;
    color: #fff;
    padding: 10px 70px;
    width: 100%;
    background: #fb5a43 !important;
    background: linear-gradient(
      270deg,
      #fb5a43 0.03%,
      #c341ae 30.44%,
      #9445c2 57.35%,
      #079cfe 95.73%
    ) !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;

    :hover {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5) !important;
    }
  }

  .color {
    color: #606060;
  }

  @media (max-width: 1199px) {
    background-image: url(${bgBookHd});

    button {
      font-size: 1rem;
    }
  }

  @media (max-width: 991px) {
    background-image: url(${bgBookTb});

    button {
      font-size: 1rem;
    }

    h1 {
      font-size: 3rem;
    }
  }

  @media (max-width: 767px) {
    background-image: none;
    .size-10 {
      font-size: 10px;
    }

    .mg-negative {
      margin-top: -6rem;
    }
  }
`;
