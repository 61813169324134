import { lighten } from 'polished';
import styled from 'styled-components';

import banner from '~/assets/defaults/banner.png';

export const Container = styled.div`
  font-size: 14px;
`;
export const Video = styled.div`
  h1 {
    color: #6e6e6e;
    font-size: 3rem;
  }

  video {
    border-radius: 21px;
    width: 100%;
    max-width: 1000px;
  }

  .xxs-size {
    font-size: 18px;
  }

  button {
    background-color: #2f80ed;
    color: #fff;
    transition-duration: 0.3s;
    border-radius: 15px;
    padding: 15px 0px;
    width: 270px;
    font-size: 20px;
    border: 0;
    font-weight: bold;

    :hover {
      background-color: ${lighten(0.1, '#2F80ED')};
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
      color: #fff;
    }
  }

  .order {
    color: #242526;
    font-size: 18px;
  }

  .height {
    height: 48px;
    width: 48px;
  }

  .step_active {
    div {
      background-color: #333333;
      border: solid 2px #333333;

      span {
        font-size: 24px;
        color: #fff;
      }
    }
  }

  .hr_2,
  .hr_3 {
    background-color: #333;
  }

  .btn-no-tanks {
    color: #ababab;
    font-size: 20px;
    width: 200px;
    :hover {
      text-decoration: underline;
      box-shadow: none !important;
      color: #ababab;
    }
  }

  .text-gradient-video {
    background: #fb5a43;
    background: linear-gradient(
      270.03deg,
      #fb5a43 10.39%,
      #c341ae 80.71%,
      #9941c3 99.97%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media screen and (min-width: 992px) {
    /* background-image: url(${banner});
    background-position: 85%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 65vw;
    margin-top: -100px; */
  }

  @media screen and (min-width: 1200px) {
    height: calc(80vh - 100px);
    .video-position {
      position: absolute;
      right: 0;
    }
  }

  @media screen and (max-width: 1399px) {
    button {
      font-size: 16px;
      width: 200px;
    }
  }

  @media screen and (max-width: 991px) {
    .step_active div {
      span {
        font-size: 12px;
      }
    }

    .height {
      width: 40px;
      height: 40px;
    }
  }

  @media screen and (max-width: 767px) {
    button {
      font-size: 14px;
      width: 100%;
      padding: 19.5px 0px;
    }

    .btn-no-tanks {
      width: 100%;
      margin: 20px 0;
      font-size: 14px;
    }
  }
`;
