import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

import { useLanguage } from '~/hooks/Language';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container } from './styles';
import Input from '~/components/Input';
import { useFunnelTrack } from '~/hooks/FunnelTrack';

interface ISignUpFormData {
  name: string;
  email: string;
  referrer?: string;
  indication: string;
}

interface IReset {
  reset(): void;
}

const SignUp: React.FC = () => {
  const {
    funnelsTracks,
    setFunnelTrack,
    ip,
    origin,
    country,
  } = useFunnelTrack();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [signUpFormData, setSignUpFormData] = useState({} as ISignUpFormData);

  const { language } = useLanguage();
  const date = new Date();
  const year = date.getFullYear();

  useEffect(() => {
    setLoading(true);
    const indicationData = localStorage.getItem('@Membership:indication');
    if (indicationData) {
      const [indication, referrer] = indicationData.split('|');
      api
        .get(`students/${referrer}`)
        .then(() => {
          const formData: ISignUpFormData = {
            name: '',
            email: '',
            referrer,
            indication,
          };

          setSignUpFormData(formData);
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message === 'Student not found'
          ) {
            Swal.fire(
              'Opss...',
              language.swal_message.referral_link,
              'error'
            ).then(() => {
              history.push(`${process.env.PUBLIC_URL}/`);
              setLoading(false);
            });
          } else {
            Swal.fire('Opss...', language.swal_message.try_again, 'error').then(
              () => {
                history.push(`${process.env.PUBLIC_URL}/`);
                setLoading(false);
              }
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      Swal.fire(
        'Opss...',
        language.swal_message.valid_indication,
        'error'
      ).then(() => {
        history.push(`${process.env.PUBLIC_URL}/`);
        setLoading(false);
      });
    }
  }, [
    history,
    language.swal_message.referral_link,
    language.swal_message.try_again,
    language.swal_message.valid_indication,
  ]);

  useEffect(() => {
    setTimeout(() => {
      if (origin) {
        const funnelTrackData = funnelsTracks.find(
          (funnelTrack) =>
            funnelTrack.ip === ip &&
            funnelTrack.page === 'sign-up' &&
            funnelTrack.action === 'first-contact' &&
            funnelTrack.origin === origin
        );

        if (!funnelTrackData) {
          console.log(funnelsTracks);
          console.log(ip);
          console.log(setFunnelTrack);
          console.log(origin);
          console.log(country);
          api
            .post('funnels-tracks', {
              page: 'sign-up',
              action: 'first-contact',
              origin,
              country,
            })
            .then(() => {
              setFunnelTrack({
                ip,
                page: 'sign-up',
                action: 'first-contact',
                origin,
                country,
              });
            });
        }
      }
    }, 400);
  }, [funnelsTracks, ip, setFunnelTrack, origin, country]);

  const handleSubmit = useCallback(
    async (data: ISignUpFormData, { reset }: IReset) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required(language.signUp.require_1),
          email: Yup.string().required(language.signUp.require_2),
          indication: Yup.string().required(language.signUp.require_3),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          name: data.name,
          email: data.email,
          referenced: data.indication,
        };

        const response = await api.post('students', formData);
        const credentials = JSON.stringify(response.data);
        localStorage.setItem('@Membership:credentials', credentials);
        reset();

        if (origin) {
          const funnelTrackData = funnelsTracks.find(
            (funnelTrack) =>
              funnelTrack.ip === ip &&
              funnelTrack.page === 'sign-up' &&
              funnelTrack.action === 'create-account' &&
              funnelTrack.origin === origin
          );

          if (!funnelTrackData) {
            api
              .post('funnels-tracks', {
                page: 'sign-up',
                action: 'create-account',
                origin,
                country,
              })
              .then(() => {
                setFunnelTrack({
                  ip,
                  page: 'sign-up',
                  action: 'create-account',
                  origin,
                  country,
                });
              });
          }
        }

        history.push(`${process.env.PUBLIC_URL}/sales-funnel`);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            language.swal_message.try_again,
            'error'
          ).then(() => setLoading(false));
        }
      } finally {
        setLoading(false);
      }
    },
    [
      country,
      funnelsTracks,
      history,
      ip,
      language.signUp.require_1,
      language.signUp.require_2,
      language.signUp.require_3,
      language.swal_message.try_again,
      origin,
      setFunnelTrack,
    ]
  );

  return (
    <>
      <Container className="container-fluid d-flex px-lg-0">
        <div className="w-100 w-lg-65 w-xxl-50 w-100 d-flex flex-column justify-content-between min-vh-100">
          <div />
          <div className="row justify-content-center">
            <div className="col-11 col-md-8 col-lg-10 col-xl-9">
              <div className="row">
                <div className="col-12 pt-5 pt-xxl-0">
                  <h1 className="h1 font-weight-bold">
                    {language.signUp.h1}{' '}
                    <strong className="text-gradient display-lg-4">
                      <span className="h1 font-weight-bold">
                        {language.signUp.h1_span_1}{' '}
                      </span>
                      <span className="font-weight-bold">
                        {language.signUp.h1_span_2}
                      </span>
                      <span className="h1 font-weight-bold">
                        {language.signUp.h1_span_2_1}
                      </span>
                    </strong>
                  </h1>
                  <p className="h5">
                    <span className="small">{language.signUp.p}</span>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-10">
                  <Form
                    ref={formRef}
                    initialData={signUpFormData}
                    onSubmit={handleSubmit}
                  >
                    <div className="my-3 mt-md-5 mb-md-4">
                      <div>
                        <label htmlFor="name" className="small">
                          {language.signUp.label_1}
                        </label>
                        <Input name="name" id="name" className="w-100 focus" />
                      </div>
                      <div className="my-4">
                        <label htmlFor="name" className="small">
                          {language.signUp.label_2}
                        </label>
                        <Input
                          type="email"
                          name="email"
                          id="email"
                          className="w-100 focus"
                        />
                      </div>
                      <div>
                        <label htmlFor="name" className="small">
                          {language.signUp.label_3}
                        </label>
                        <Input
                          name="referrer"
                          id="referrer"
                          className="w-100 focus"
                          disabled
                        />
                        <Input
                          name="indication"
                          id="indication"
                          className="d-none"
                          readOnly
                        />
                      </div>
                    </div>
                    <div>
                      <label htmlFor="check" className="size-check">
                        <Input
                          className="d-inline-block"
                          type="checkbox"
                          name="check"
                          id="check"
                          required
                        />{' '}
                        {language.signUp.label_4_1}&nbsp;
                        {language.signUp.label_4_2}
                      </label>
                    </div>
                    <div>
                      <button type="submit" className="btn w-100 my-4">
                        <strong>{language.signUp.button}</strong>
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="row">
                <div className="col-lg-10">
                  <p className="text-footer text-center">
                    © {year} - {language.book.p_3}
                  </p>
                  <p className="text-footer text-center">
                    {language.book.p_4_1} <br className="d-none d-md-block" />
                    {language.book.p_4_2}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-girl w-lg-35 w-xl-50 d-none d-lg-block" />
      </Container>
      {loading && (
        <>
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SignUp;
