import styled from 'styled-components';
import polygon from '~/assets/defaults/polygon-master.png';
import arrowNext from '~/assets/icons/arrow-corousel-stories.svg';
import arrowPrev from '~/assets/icons/arrow-corousel-prev-stories.svg';

export const Container = styled.div`
  background: #f5f3ed;
  overflow: hidden;

  a.btn-access {
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: #fff;
    font-family: 'Quicksand';
    font-size: 28px;
    padding: 21px 40px;
    font-weight: 700;
    line-height: 134.078%;
    letter-spacing: 2.52px;
    border-radius: 20px;
    border: none;
    background: linear-gradient(180deg, #3fde9e 0%, #29bc81 100%);
    transition-duration: 0.2s;
    :hover {
      background: linear-gradient(180deg, #29bc81 0%, #3fde9e 100%);
      //opacity: 0.9;
    }
  }

  @media screen and (max-width: 991px) {
    margin-top: 83px;
  }

  @media screen and (max-width: 430px) {
    a.btn-access {
      padding: 21px 21px;
      font-size: 6.5vw;
    }
  }

  @media screen and (min-width: 992px) {
    zoom: 0.7;
  }

  @media screen and (min-width: 1100px) {
  }

  @media screen and (min-width: 1200px) {
    zoom: 0.8;
  }

  @media screen and (min-width: 1400px) {
    zoom: 0.9;
    .container-xxl {
      max-width: 1440px !important;
    }
  }

  @media screen and (min-width: 1500px) {
    zoom: 1;
  }
`;

export const BannerMain = styled.div`
  h1 {
    color: #000;
    font-family: 'Quicksand';
    font-size: 54px;
    font-weight: 600;
    line-height: 117.078%;
    letter-spacing: 2px;
    span {
      color: #ffae52;
    }
  }

  h2 {
    color: #000;
    font-family: 'Quicksand';
    font-size: 20px;
    font-weight: 400;
    line-height: 134.078%;
    letter-spacing: 0.4px;
    span {
      color: #ffae52;
      font-weight: 500;
    }
  }

  .box {
    position: relative;
    z-index: 1;
    padding: 31px 0;

    border-radius: 45px;
    border: 1px solid #e1d8d8;
    background: #fff;
    box-shadow: 1px 12px 30px 0px rgba(0, 0, 0, 0.05);

    ::-webkit-scrollbar {
      background-color: transparent !important;
      height: 0px !important;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: transparent !important;
    }
  }

  .box div span {
    color: #7c7777;
    font-family: 'Inter', 'sans-serif';
    font-size: 22px;
    font-weight: 400;
    line-height: 32px;
  }

  .box div span.number {
    color: #303030;
    font-family: 'Red Hat Display', 'sans-serif';
    font-size: 56px;
    font-weight: 700;
    line-height: 64px;
  }

  .hash {
    width: 1px;
    height: 50px;
    background: #bdbdbd;
  }

  .min-width {
    justify-content: space-evenly;
    min-width: 700px;
  }

  @media screen and (max-width: 575px) {
    h1 {
      font-size: 46px;
    }

    h2 {
      font-size: 18px;
      padding: 0 20px;
    }
  }
`;

export const Enjoy = styled.div`
  h2 {
    color: #000;
    font-family: 'Source Sans Pro';
    font-size: 45px;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 3.6px;
  }

  h3 {
    color: #000;
    font-family: 'Quicksand';
    font-size: 22px;
    font-weight: 400;
    line-height: 134.078%;
    letter-spacing: 0.44px;
    span {
      color: #ffae52;
      font-weight: 500;
    }
  }

  .bg {
    background: #fafafa;
  }
  .circle {
    position: relative;
    left: -5%;
    width: calc(100% + 200px);
    height: 150px;
    background: #fafafa;
    border-radius: 50% 50% 0 0;
    margin-top: -90px;
    z-index: 0;
  }
  .triangle {
    width: 100%;
    height: 180px;
    background: #fafafa;
    clip-path: polygon(0% 0%, 100% 0%, 51% 100%, 49% 100%);
  }

  .enjoy-img {
    box-shadow: 1px 12px 35px 0px rgba(0, 0, 0, 0.08);
    border-radius: 30px;
    position: relative;
    z-index: 1;
    margin-bottom: -90px;
  }

  .square-enjoy {
    position: absolute;
    right: -63px;
    top: -40px;
    width: 174px;
    height: 174px;
    z-index: 0;
  }

  @media screen and (max-width: 575px) {
    h2 {
      font-size: 40px;
    }
    .square-enjoy {
      top: -12px;
    }

    .enjoy-img {
      margin-bottom: -50px;
    }

    .triangle {
      height: 120px;
      clip-path: polygon(0% 0%, 100% 0%, 54% 100%, 46% 100%);
    }

    .circle {
      left: -8%;
      width: calc(100% + 100px);
    }
  }
`;

export const Choose = styled.div`
  h2 {
    color: #000;

    font-family: 'Quicksand';
    font-size: 50px;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 4.5px;
    span {
      color: #ffae52;
      font-size: 24px;
      font-weight: 700;
      line-height: 122.578%; /* 29.419px */
      letter-spacing: 2.16px;
    }
  }

  p {
    color: #000;
    text-align: center;
    font-family: 'Quicksand';
    font-size: 22px;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: 1.98px;
  }

  .square-tri {
    position: absolute;
    left: -65px;
    top: -184px;
    width: 122px;
    height: 122px;
  }

  .choose-cube {
    position: absolute;
    right: -68px;
    top: -26px;
    width: 164px;
    height: 152px;
  }

  .card-choose {
    border-radius: 30px;
    border: 1px solid #ede8db;
    background: rgba(255, 255, 255, 0.84);
    box-shadow: 1px 12px 35px 0px rgba(0, 0, 0, 0.04);
    padding-left: 42px;
    padding-right: 25px;
    padding-top: 30px;
    padding-bottom: 30px;
    h2 {
      color: #171616;
      font-family: 'Quicksand';
      font-size: 32px;
      font-weight: 600;
      line-height: 122.578%;
      letter-spacing: 0.32px;
    }

    h3 {
      color: #585656;
      font-family: 'Quicksand';
      font-size: 18px;
      font-weight: 400;
      line-height: 122.578%;
      letter-spacing: 1.62px;
    }
  }

  .number {
    display: flex;
    width: 80px;
    height: 68px;
    border-radius: 26px;

    justify-content: center;

    color: #fff;
    align-items: center;
    font-size: 50px;
    font-weight: 600;
    padding: 3px;
  }

  .one {
    background: #8effc9;
    border: 1px solid #8effc9;
    box-shadow: -1px 3px 10px 0px rgba(142, 255, 201, 0.5);
    background-clip: content-box;
  }

  .two {
    border: 1px solid #8ef8ff;
    background: #8ef8ff;
    box-shadow: -1px 3px 10px 0px rgba(142, 248, 255, 0.5);
    background-clip: content-box;
  }

  .three {
    border: 1px solid #8eaeff;
    background: #8eaeff;
    box-shadow: -1px 3px 10px 0px rgba(142, 174, 255, 0.5);
    background-clip: content-box;
  }

  .four {
    border: 1px solid #ffb0a5;
    background: #ffb0a5;
    box-shadow: -1px 3px 10px 0px rgba(255, 176, 165, 0.5);
    background-clip: content-box;
  }

  .five {
    border: 1px solid #ebb4ff;
    background: #ebb4ff;
    box-shadow: -1px 3px 10px 0px rgba(235, 180, 255, 0.5);
    background-clip: content-box;
  }

  .six {
    border: 1px solid #ffa0ce;
    background: #ffa0ce;
    box-shadow: -1px 3px 10px 0px rgba(255, 160, 206, 0.5);
    background-clip: content-box;
  }

  @media screen and (max-width: 991px) {
    .choose-cube {
      right: -15px;
      top: 130px;
    }
  }

  @media screen and (max-width: 575px) {
    h2 {
      font-size: 46px;
      span {
        font-size: 20px;
      }
    }

    .choose-cube {
      right: -43px;
      top: 213px;
      width: 130px;
      height: 120px;
    }
  }
`;

export const Master = styled.div`
  background-image: url(${polygon});
  background-repeat: no-repeat;
  background-position: -7%;
  padding: 15% 0;
  position: relative;
  z-index: 1;
  h2,
  h3 {
    font-family: 'Quicksand';
    color: #000;
  }
  h2 {
    font-size: 50px;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 2px;
  }

  h3 {
    font-size: 22px;
    font-weight: 400;
    line-height: 134.078%;
    letter-spacing: 0.44px;
  }

  img {
    width: 100%;
    border-radius: 40px;
    border: 5px solid #000;
  }

  @media screen and (max-width: 991px) {
    background-image: none;
    .bg-polygon-mob {
      background-image: url(${polygon});
      background-repeat: no-repeat;
      background-position: 121% 0%;
    }
  }
`;

export const Skills = styled.div`
  background: #fff;
  h2 {
    color: #000;
    text-align: center;
    font-family: 'Quicksand';
    font-size: 50px;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 4.5px;
    span {
      color: #ffae52;
    }
  }

  h3 {
    color: #000;
    text-align: center;
    font-family: 'Quicksand';
    font-size: 18px;
    font-weight: 500;
    line-height: 134.078%;
    letter-spacing: 0.36px;
  }

  h4 {
    color: #000;
    font-family: 'Source Sans Pro';
    font-size: 18px;
    font-weight: 600;
    line-height: 134.078%;
    letter-spacing: 0.36px;
  }

  .bar-scroll {
    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: transparent !important;
    }
  }

  .max-w-skills {
    min-width: 1440px;
  }

  .star1 {
    position: absolute;
    right: -6%;
    top: 57%;
    width: 16px;
    height: 18px;
    filter: drop-shadow(0px 4px 4px rgba(255, 174, 82, 0.25));
  }

  .stars1 {
    position: absolute;
    right: 0%;
    top: 37%;
    width: 18px;
    height: 18px;
    filter: drop-shadow(0px 4px 4px rgba(255, 174, 82, 0.25));
  }

  .stars2 {
    position: absolute;
    left: -95%;
    top: 17%;
    width: 18px;
    height: 18px;
    filter: drop-shadow(0px 4px 4px rgba(255, 174, 82, 0.25));
  }

  .star2 {
    position: absolute;
    left: -107%;
    top: 40%;
    width: 16px;
    height: 18px;
    filter: drop-shadow(0px 4px 4px rgba(255, 174, 82, 0.25));
  }

  .star3 {
    position: absolute;
    left: -84%;
    top: 58%;
    width: 16px;
    height: 18px;
    filter: drop-shadow(0px 4px 4px rgba(255, 174, 82, 0.25));
  }
  .invert-circle {
    background: #f5f3ed;
    position: relative;
    left: -5%;
    width: calc(100% + 200px);
    height: 400px;
    border-radius: 0 0 50% 50%;
    margin-top: -350px;
    z-index: 0;
  }

  .skills {
    width: 130px;
    border-radius: 12px;
    background: rgba(227, 232, 230, 0.22);
    border: none;
    color: #343333;
    padding: 9px;
    font-size: 13px;
    pointer-events: none;
    font-weight: 600;
  }

  .card-skills {
    position: relative;
    .bg-card {
      border-radius: 30px;
      border: 3px solid rgba(0, 0, 0, 0.7);
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
      position: relative;
      z-index: 1;
    }
  }

  .btn-access {
    z-index: 1;
  }

  .square-skills {
    position: absolute;
    right: -47px;
    top: 50px;
    width: 91px;
    height: 91px;
    z-index: 0;
  }

  .retangle {
    border-radius: 30px;
    background: #f5f3ed;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    left: -13px;
    top: 13px;
  }

  .title {
    width: 236px;
    color: #000;
    font-size: 22px;
    font-weight: 600;
    border-radius: 17px;
    background: rgba(255, 255, 255, 0.28);
    backdrop-filter: blur(15px);
    padding: 15px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
  }

  .members-row img {
    width: 30px;
    height: 30px;
  }

  .members-row img:not(:first-child) {
    margin-left: -10px;
  }

  .members-row .number-members {
    color: #000;
    font-family: 'Quicksand';
    font-size: 12px;
    font-weight: 400;
    line-height: 134.078%;
    letter-spacing: 0.24px;
  }

  .members-row .join-members {
    color: #ffae52;
    font-family: 'Quicksand';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 134.078%;
    letter-spacing: 0.28px;
    text-decoration-line: underline;
  }

  @media screen and (max-width: 991px) {
    .invert-circle {
      width: calc(100% + 10vw);
      margin-top: -290px;
    }

    .stars1 {
      right: 6%;
      top: 0%;
    }

    .star1 {
      right: 2%;
      top: 17%;
    }

    .star2 {
      left: -24%;
      top: 70%;
    }

    .star3 {
      left: 0%;
      top: 90%;
    }
  }

  @media screen and (max-width: 575px) {
    h2 {
      font-size: 33px;
    }
    .invert-circle {
      width: calc(100% + 25vw);
      left: -13%;
      height: 343px;
    }

    .star1 {
      top: 11%;
    }

    .stars1 {
      right: 10%;
    }

    .star2 {
      left: -7%;
    }

    .star3 {
      left: 21%;
    }

    .skills {
      width: auto;
      margin: 10px;
    }

    .square-skills {
      right: -24px;
    }
  }
`;

export const Stories = styled.div`
  background: #fff;
  h2 {
    color: #15141e;
    text-align: center;
    font-family: 'Quicksand';
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
  }

  h3 {
    color: #ffae52;
    text-align: center;
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.9px;
  }

  h4 {
    color: #b1b1b5;
    font-family: 'Quicksand';
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
  }

  h5 {
    color: var(--colors-text, #b1b1b5);
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.9px;
  }

  h6 {
    color: #37ebaa;
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .bg {
    border-radius: 40px;
    background: #18191a;
  }

  .quote-stories {
    width: 59px;
    height: 41px;
  }

  .bg-transparent-1 {
    border-radius: 30px;
    background: rgba(24, 25, 26, 0.5);
    width: 82%;
    height: 60px;
    margin: -30px auto 0 auto;
  }

  .bg-transparent-2 {
    border-radius: 30px;
    background: rgba(24, 25, 26, 0.25);
    width: 70%;
    height: 90px;
    margin: -60px auto 0 auto;
  }

  .slick-prev,
  .slick-next {
    top: 115%;
  }

  .slick-prev {
    left: 43%;
  }

  .slick-next {
    right: 43%;
  }

  .slick-next:before {
    content: url(${arrowNext});
  }

  .slick-prev:before {
    content: url(${arrowPrev});
  }

  .person1 {
    position: absolute;
    top: -32%;
    left: -30%;
  }
  .person2 {
    position: absolute;
    right: -23%;
    top: -29%;
  }
  .person3 {
    position: absolute;
    left: -35%;
    top: 27%;
  }
  .person4 {
    position: absolute;
    right: -36%;
    top: 23%;
  }
  .person5 {
    position: absolute;
    left: -20%;
    top: 87%;
  }
  .person6 {
    position: absolute;
    right: -22%;
    top: 83%;
  }

  .circle {
    background: #f5f3ed;
    position: relative;
    left: -5%;
    width: calc(100% + 200px);
    height: 558px;
    border-radius: 50% 50% 0 0;
    margin-bottom: -420px;
    z-index: 0;
    margin-top: 60px;
  }

  @media screen and (max-width: 991px) {
    .circle {
      width: calc(100% + 10vw);
      height: 392px;
      margin-top: 227px;
      margin-bottom: -266px;
    }

    .person1 {
      top: -21%;
      left: 10%;
    }

    .person2 {
      top: 89%;
      left: 73%;
    }

    .person3 {
      top: 103%;
      left: 9%;
    }
    .person4 {
      top: 120%;
      left: 64%;
    }
    .person5 {
      left: 44%;
      top: -24%;
    }
    .person6 {
      right: 11%;
      top: -12%;
    }
  }

  @media screen and (max-width: 575px) {
    .circle {
      width: calc(100% + 54vw);
      height: 332px;
      left: -24%;
      margin-top: 227px;
      margin-bottom: -243px;
    }

    .person1 {
      top: -17%;
      left: -1%;
    }

    .person2 {
      top: 92%;
      left: 67%;
    }

    .person3 {
      top: 103%;
      left: 4%;
    }
    .person4 {
      top: 118%;
      left: 53%;
    }
    .person5 {
      left: 35%;
      top: -20%;
    }
    .person6 {
      right: 4%;
      top: -10%;
    }

    .slick-prev {
      left: 41%;
    }

    .slick-next {
      right: 41%;
    }
  }
`;

export const Stacking = styled.div`
  h2 {
    color: #000;
    font-family: 'Source Code Pro';
    text-align: center;
    font-size: 50px;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 4.5px;
    span {
      color: #ffae52;
    }
  }

  h3 {
    color: #000;
    text-align: right;
    font-family: 'Source Sans Pro';
    font-size: 40px;
    font-weight: 700;
    line-height: 125%;
    letter-spacing: 1.6px;
    b {
      font-weight: 700;
    }
  }

  h4 {
    color: #000;
    font-family: 'Quicksand';
    text-align: right;
    font-size: 22px;
    font-weight: 400;
    line-height: 134.078%;
    letter-spacing: 0.44px;
    span {
      color: #ffae52;
      font-weight: 600;
    }
  }

  .stacking-img {
    box-shadow: 1px 12px 35px 0px rgba(0, 0, 0, 0.08);
    border-radius: 30px;
    position: relative;
    z-index: 1;
  }

  .square-stacking {
    position: absolute;
    left: -63px;
    bottom: -57px;
    width: 174px;
    height: 174px;
    z-index: 0;
  }

  @media screen and (max-width: 991px) {
    h3 span {
      font-family: 'Quicksand';
      b {
        color: #ffae52;
      }
    }

    h4 span {
      font-weight: 700;
      span {
        color: #000;
      }
    }
    .square-stacking {
      left: 16px;
      bottom: 74%;
    }
  }

  @media screen and (max-width: 575px) {
    h2 {
      font-size: 48px;
    }

    .square-stacking {
      left: 10px;
      bottom: 61%;
    }
  }

  @media screen and (max-width: 400px) {
    h2 {
      font-size: 11vw;
      line-height: 15vw;

      span {
        font-size: 12vw;
      }
    }
  }
`;

export const Learn = styled.div`
  h2 {
    color: #ffae52;
    text-align: center;
    font-family: 'Quicksand';
    font-size: 85px;
    font-weight: 700;
    line-height: 159px;
    padding-bottom: 450px;
    span {
      color: #15141e;
      font-size: 199px;
    }
  }

  .square-tri {
    position: absolute;
    width: 118px;
    height: 118px;
    right: -6%;
    top: 7%;
  }

  .stars {
    position: absolute;
    bottom: 31%;
    left: 9%;
    width: 18px;
    height: 18px;
    filter: drop-shadow(0px 4px 4px rgba(255, 174, 82, 0.25));
  }
  .star1 {
    position: absolute;
    bottom: 21%;
    left: 5%;
    width: 16px;
    height: 18px;
    filter: drop-shadow(0px 4px 4px rgba(255, 174, 82, 0.25));
  }
  .star2 {
    position: absolute;
    bottom: 25%;
    left: 14%;
    width: 16px;
    height: 18px;
    filter: drop-shadow(0px 4px 4px rgba(255, 174, 82, 0.25));
  }

  .stars-large {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 38%;
    right: 4%;
    filter: drop-shadow(0px 4px 4px rgba(255, 174, 82, 0.25));
  }

  .star-large {
    position: absolute;
    width: 21px;
    height: 24px;
    bottom: 31%;
    right: 9%;
    filter: drop-shadow(0px 4px 4px rgba(255, 174, 82, 0.25));
  }

  .cube-learn {
    position: absolute;
    bottom: 0%;
    right: 25%;
    z-index: 2;
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 43px;
      line-height: 85px;
      padding-bottom: 175px;
      span {
        font-size: 116px;
      }
    }
  }

  @media screen and (max-width: 575px) {
    h2 {
      font-size: 30px;
      line-height: 71px;
      padding-bottom: 175px;
      span {
        font-size: 87px;
      }
    }

    .stars-large {
      bottom: 122%;
      right: 8%;
    }

    .star-large {
      bottom: 111%;
      right: 23%;
    }

    .stars {
      bottom: 42%;
      left: 12%;
    }

    .star1 {
      bottom: 15%;
      left: 5%;
    }

    .star2 {
      bottom: 25%;
      left: 24%;
    }
  }

  @media screen and (max-width: 400px) {
    h2 {
      font-size: 7vw;
      line-height: 15vw;

      span {
        font-size: 20vw;
      }
    }
  }
`;

export const Global = styled.div`
  margin-top: -30px;
  margin-bottom: 262px;

  .bg-discover {
    background: #18191a;
    border-radius: 65px;
    z-index: 1;
  }

  .bg-discover {
    h2,
    h3,
    p {
      font-family: 'Poppins', sans-serif;
    }
    h3 {
      font-weight: 600;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.9px;
      color: #37ebaa;
    }
    h2 {
      font-weight: 600;
      font-size: 56px;
      line-height: 60px;
      color: #f3f4f8;
    }
    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      color: #b1b1b5;
    }
  }

  .discover1 {
    top: 5%;
    left: 11%;
  }

  .discover2 {
    top: 42%;
    left: 14%;
  }

  .discover3 {
    left: 24%;
    top: 69%;
  }

  .discover4 {
    top: 5%;
    right: 10%;
  }

  .discover5 {
    top: 31%;
    right: 7%;
  }

  .discover6 {
    top: 61%;
    right: 16%;
  }

  .square-global {
    position: absolute;
    width: 174px;
    height: 174px;
    bottom: -17%;
    right: -4%;
    opacity: 0.4;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 135px;

    .discover1 {
      left: 2%;
    }

    .discover2 {
      left: 5%;
    }

    .discover3 {
      left: 12%;
    }

    .discover4 {
      right: 2%;
    }

    .discover5 {
      right: 4%;
    }

    .discover6 {
      right: 10%;
    }

    .discover-mob1 {
      width: 50px;
      height: 50px;
      left: 6%;
      top: 4%;
    }

    .discover-mob2 {
      width: 46px;
      height: 46px;
      right: 5%;
      top: 12%;
    }

    .discover-mob3 {
      width: 46px;
      height: 46px;
      bottom: 45%;
      left: 6%;
    }

    .discover-mob4 {
      width: 60px;
      height: 60px;
      bottom: 22%;
      right: 5%;
    }
  }

  @media screen and (max-width: 575px) {
    .bg-discover h2 {
      font-size: 44px;
    }

    .discover-mob3 {
      bottom: 40%;
    }
  }
  @media screen and (max-width: 475px) {
    a.btn-access {
      font-size: 24px;
      padding: 21px 21px;
    }
  }
`;
