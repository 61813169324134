import React, { createContext, useState, useContext, useCallback } from 'react';

interface IUser {
  id: string;
}

interface FunnelContextData {
  user: IUser;
  setUser(user: IUser): void;
}

export const FunnelContext = createContext<FunnelContextData>(
  {} as FunnelContextData
);

export const FunnelProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<IUser>(() => {
    const userFunnel = localStorage.getItem('@Wiserr:userFunnel');
    if (userFunnel) {
      return JSON.parse(userFunnel);
    }

    return {} as IUser;
  });

  const handleSetUser = useCallback((userFunnel: IUser) => {
    localStorage.setItem('@Wiserr:userFunnel', JSON.stringify(userFunnel));
    setUser(userFunnel);
  }, []);

  return (
    <FunnelContext.Provider value={{ user, setUser: handleSetUser }}>
      {children}
    </FunnelContext.Provider>
  );
};

export function useFunnel(): FunnelContextData {
  const context = useContext(FunnelContext);

  if (!context) {
    throw new Error('useFunnel must be used within an FunnelProvider');
  }

  return context;
}
