import { lighten } from 'polished';
import styled from 'styled-components';

import banner from '~/assets/defaults/banner.png';
import tablets from '~/assets/defaults/tablets.png';

export const Attention = styled.div`
  background: #ff1a50;
  box-shadow: 0px 4px 4px rgba(255, 26, 80, 0.15);
  h2 {
    padding: 21px 0;
  }
  svg {
    color: #fff;
    font-size: 28px;
  }

  @media screen and (max-width: 1399px) {
    h2 {
      font-size: 13px;
      padding: 15px 0;
    }
    svg {
      font-size: 20px;
      min-width: 20px;
    }
  }

  @media screen and (max-width: 991px) {
    margin-top: 64px;
  }
`;

export const Video = styled.div`
  h1 {
    color: #6e6e6e;
    font-size: 3rem;
    font-weight: 300 !important;
  }

  video {
    border-radius: 21px;
    width: 100%;
    max-width: 1000px;
  }

  .xxs-size {
    font-size: 18px;
  }

  button {
    background-color: #2f80ed;
    color: #fff;
    transition-duration: 0.3s;
    border-radius: 15px;
    padding: 15px 0px;
    width: 270px;
    font-size: 20px;

    :hover {
      background-color: ${lighten(0.1, '#2F80ED')};
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
      color: #fff;
    }
  }

  .order {
    color: #242526;
    font-size: 18px;
  }

  .height {
    height: 48px;
    width: 48px;
  }

  .text-gradient-video {
    background: #fb5a43;
    background: linear-gradient(
      270.03deg,
      #fb5a43 0.02%,
      #c341ae 92.68%,
      #c341ae 99.97%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media screen and (min-width: 992px) {
    .video-position {
      position: absolute;
      right: 0;
    }
    /* background-image: url(${banner});
    background-position: 85%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 65vw;
    margin-top: -100px; */
  }

  @media screen and (min-width: 1200px) {
    height: calc(80vh - 100px);
    .video-position {
      position: absolute;
      right: 0;
    }
  }

  @media screen and (max-width: 991px) {
    h1 {
      font-weight: 400 !important;
    }
  }

  @media screen and (max-width: 575px) {
    h1 {
      font-size: 30px;
    }
  }
`;

export const Text = styled.div`
  h2,
  p {
    font-size: 18px;
    line-height: 24px;
  }

  h2 {
    color: #ababab;
  }

  p {
    color: #ff1a50;
  }

  .border-text {
    border: 1px solid #e4e4e4;
    border-radius: 20px;
  }

  @media screen and (max-width: 575px) {
    p {
      font-size: 14px;
    }
  }
`;

export const Access = styled.div`
  h2 {
    color: #242526;
    font-size: 3rem;
    font-weight: 600;
  }

  b {
    color: #6e6e6e;
  }
  p {
    line-height: 150% !important;
    font-size: 22px;
  }
  .color {
    color: #ababab;
  }

  a {
    background-color: #000;
    color: #fff;
    border-radius: 15px;
    padding: 15px 60px;

    :hover {
      background-color: ${lighten(0.1, '#000000')};
      color: ${lighten(0.1, '#fff')};
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
    }
  }

  .top {
    top: -70px;
    right: 0px;
  }

  .bd-left {
    border: 3px solid #606060;
    border-radius: 3px;
    background-color: #606060;
  }

  .text-gradient-access {
    background: #fb5a43;
    background: linear-gradient(
      270.03deg,
      #fb5a43 0.02%,
      #c341ae 92.68%,
      #c341ae 99.97%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media screen and (min-width: 992px) {
    background-image: url(${tablets});
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    height: 45vw;
    margin: 8rem 0;
  }

  @media screen and (min-width: 2000px) {
    height: 80vh;
  }

  @media screen and (max-width: 1399px) {
  }

  @media screen and (max-width: 1199px) {
    h2 {
      font-size: 2rem;
    }
    p {
      font-size: 19px;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 30px;
      font-weight: 400 !important;
      color: #6e6e6e;
      span {
        font-size: 40px;
      }
    }

    p {
      font-size: 18px;
    }

    .top {
      height: 40px;
    }
  }
`;

export const Steps = styled.div`
  h2 {
    color: #242526;
    font-size: 3rem;
  }

  .border-step {
    border: 1px solid #f3f3f3;
    border-radius: 10px;
  }

  .top {
    top: -70px;
    right: 0px;
  }

  .text-gradient-steps {
    background: #fb5a43;
    background: linear-gradient(
      270.03deg,
      #fb5a43 0.02%,
      #c341ae 92.68%,
      #c341ae 99.97%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .steps {
    transition: all 0.3s;
    h3 {
      color: #242526;
      font-size: 48px;
      font-weight: 600;
    }

    hr {
      background-color: #606060;
      height: 2px;
      border: none;
    }
    p {
      color: #969696;
      font-size: 24px;
      font-weight: 300;
      line-height: 150%;
    }

    a {
      color: #242526;
    }

    cursor: pointer;

    :hover {
      background-color: #2f80ed;
      box-shadow: 10px 9px 37px rgba(41, 41, 41, 0.3);
      transform: scale(1.05);

      h3 {
        color: #e4e4e4;
      }

      hr {
        background-color: #fb5a43;
      }
      p {
        color: #fff;
      }

      a {
        color: #000;
      }
    }
  }

  .p-signature {
    color: #969696;
    font-size: 24px;
    font-weight: 300;
    line-height: 150%;
    margin-top: 140px;
  }

  .mg {
    margin: 140px 0;
  }

  @media screen and (max-width: 1399px) {
    h2 {
      font-size: 2rem;
    }
    .steps {
      p {
        font-size: 19px;
      }
      h3 {
        font-size: 2rem;
      }
    }

    .p-signature {
      font-size: 19px;
      margin-top: 80px;
    }

    .mg {
      margin: 80px 0;
      img {
        width: 200px;
      }
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      color: #6e6e6e;
      font-size: 30px;
      font-weight: 400 !important;
      span {
        font-weight: 600;
        font-size: 40px !important;
      }
    }

    .p-signature {
      font-size: 14px;
    }

    .top {
      height: 40px;
    }
    .steps {
      p {
        font-size: 16px;
        color: #6e6e6e;
      }
      h3 {
        font-size: 1.75rem;
      }

      :hover {
        transform: unset;
        p {
          color: #fff;
        }
        hr {
          background-color: #fff;
        }
        h3 {
          color: #fff;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .steps {
      padding: 3rem;
    }
  }

  @media screen and (max-width: 575px) {
    .mg {
      margin: 50px 0 25px 0;
      img {
        width: 200px;
      }
    }
  }
  @media screen and (max-width: 400px) {
    .steps {
      padding: 3rem 1rem;
    }
  }
`;

export const Chat = styled.a`
  position: fixed;
  bottom: 5%;
  right: 3%;

  :hover {
    filter: drop-shadow(2px 4px 6px #ababab);
    img {
      transform: scale(1.05);
    }
  }

  @media screen and (max-width: 1399px) {
    img {
      height: 50px;
    }
  }
`;
