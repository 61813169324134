import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { Container, Menu } from './styles';

import en from '~/assets/icons/eua-flag.png';
import es from '~/assets/icons/spain-flag.png';
import logo from '~/assets/logo/logo.svg';
import { useLanguage } from '~/hooks/Language';

const Header: React.FC = () => {
  const location = useLocation();
  const [active, setActive] = useState(false);
  const { language, handleSelectIdiom, idiom } = useLanguage();
  const pathNameSalesFunnel = location.pathname.match('/sales-funnel');

  useEffect(() => {
    setActive(false);
  }, [location]);

  return (
    <>
      <Container className="menu-header py-3 py-lg-0">
        <div className="container-fluid container-lg h-lg-100">
          <div className="row h-lg-100">
            <div className="col-12 d-flex justify-content-between align-items-center text-center">
              <div className="">
                <Link
                  to={
                    location.pathname.match('/sales-funnel')
                      ? location.pathname
                      : '/'
                  }
                >
                  <img src={logo} alt="logo" className="w-75" />
                </Link>
              </div>

              {/* <div className="d-flex justify-content-center">
                <button
                  type="button"
                  onClick={() => handleSelectIdiom('en')}
                  className="pr-3 border-0 bg-transparent menu-item w-lg-100 d-flex flex-lg-column justify-content-center align-items-center btn-idiom mx-2 mx-lg-0"
                >
                  <img
                    src={en}
                    alt="EN"
                    className={`${
                      idiom === 'en' ? 'active-idiom' : ''
                    } mr-1 mr-lg-0`}
                  />
                </button>
                <button
                  type="button"
                  onClick={() => handleSelectIdiom('es')}
                  className="border-0 bg-transparent menu-item w-lg-100 d-flex flex-lg-column justify-content-center align-items-center btn-idiom mx-2 mx-lg-0"
                >
                  <img
                    src={es}
                    alt="ES"
                    className={`${
                      idiom === 'es' ? 'active-idiom' : ''
                    } mr-1 mr-lg-0`}
                  />
                </button>
              </div> */}

              <Menu active={active}>
                <button
                  type="button"
                  className={`${
                    pathNameSalesFunnel && 'd-none'
                  } bg-transparent d-lg-none border-0 mx-3`}
                  onClick={() => setActive(!active)}
                >
                  <span />
                  <span />
                  <span />
                </button>
                <div
                  className={`${
                    pathNameSalesFunnel && 'd-none'
                  } p-absolute d-lg-none overflow overflow-auto menu-group active`}
                >
                  <div className="menu">
                    <AnchorLink
                      href="#aboutUs"
                      offset={80}
                      onClick={() => setActive(!active)}
                      className="menu-item w-100 pl-4 pl-sm-0 py-2 font-weight-300 d-lg-none justify-content-sm-center d-flex align-items-center"
                    >
                      {language.menu_mobile.a_1}
                    </AnchorLink>
                    <AnchorLink
                      href="#whoWiserr"
                      offset={100}
                      onClick={() => setActive(!active)}
                      className="menu-item w-100 pl-4 pl-sm-0 py-2 font-weight-300 d-lg-none justify-content-sm-center d-flex align-items-center"
                    >
                      {language.menu_mobile.a_2}
                    </AnchorLink>
                    <AnchorLink
                      href="#started"
                      offset={70}
                      onClick={() => setActive(!active)}
                      className="menu-item w-100 pl-4 pl-sm-0 py-2 font-weight-300 d-lg-none justify-content-sm-center d-flex align-items-center"
                    >
                      {language.menu_mobile.a_3}
                    </AnchorLink>
                    <AnchorLink
                      href="#contact"
                      offset={100}
                      onClick={() => setActive(!active)}
                      className="menu-item w-100 pl-4 pl-sm-0 py-2 font-weight-300 d-lg-none justify-content-sm-center d-flex align-items-center"
                    >
                      {language.menu_mobile.a_4}
                    </AnchorLink>
                    <AnchorLink
                      href="#faq"
                      offset={70}
                      onClick={() => setActive(!active)}
                      className="menu-item w-100 pl-4 pl-sm-0 py-2 font-weight-300 d-lg-none justify-content-sm-center d-flex align-items-center"
                    >
                      {language.menu_mobile.a_5}
                    </AnchorLink>
                    <a
                      href="https://app.wiserr.io"
                      target="blank"
                      onClick={() => setActive(!active)}
                      className="menu-item w-100 pl-4 pl-sm-0 py-2 font-weight-300 d-lg-none justify-content-sm-center d-flex align-items-center"
                    >
                      {language.menu_mobile.a_6}
                    </a>
                    <div className="d-lg-none text-center btn-menu py-5 px-4">
                      <a
                        href="https://app.wiserr.io/sign-up"
                        target="blank"
                        className="btn h6 my-xxl-5 justify-content-center justify-content-md-start align-items-center font-weight-bold"
                      >
                        {language.menu_mobile.a_7}
                      </a>
                    </div>
                    <div className="d-flex d-lg-none justify-content-center mb-5 pb-5">
                      <button
                        type="button"
                        onClick={() => handleSelectIdiom('en')}
                        className="pr-3 border-0 bg-transparent menu-item w-lg-100 d-flex flex-lg-column justify-content-center align-items-center btn-idiom mx-2 mx-lg-0"
                      >
                        <img
                          src={en}
                          alt="EN"
                          className={`${
                            idiom === 'en' ? 'active-idiom' : ''
                          } mr-1 mr-lg-0`}
                        />
                      </button>
                      <button
                        type="button"
                        onClick={() => handleSelectIdiom('es')}
                        className="border-0 bg-transparent menu-item w-lg-100 d-flex flex-lg-column justify-content-center align-items-center btn-idiom mx-2 mx-lg-0"
                      >
                        <img
                          src={es}
                          alt="ES"
                          className={`${
                            idiom === 'es' ? 'active-idiom' : ''
                          } mr-1 mr-lg-0`}
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    pathNameSalesFunnel ? 'd-flex' : 'd-none'
                  } d-lg-flex justify-content-center`}
                >
                  <button
                    type="button"
                    onClick={() => handleSelectIdiom('en')}
                    className="pr-3 border-0 bg-transparent menu-item w-lg-100 d-flex flex-lg-column justify-content-center align-items-center btn-idiom mx-2 mx-lg-0"
                  >
                    <img
                      src={en}
                      alt="EN"
                      className={`${
                        idiom === 'en' ? 'active-idiom' : ''
                      } mr-1 mr-lg-0`}
                    />
                  </button>
                  <button
                    type="button"
                    onClick={() => handleSelectIdiom('es')}
                    className="border-0 bg-transparent menu-item w-lg-100 d-flex flex-lg-column justify-content-center align-items-center btn-idiom mx-2 mx-lg-0"
                  >
                    <img
                      src={es}
                      alt="ES"
                      className={`${
                        idiom === 'es' ? 'active-idiom' : ''
                      } mr-1 mr-lg-0`}
                    />
                  </button>
                </div>
              </Menu>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Header;
