import React from 'react';

import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from 'react-router-dom';

import { Container } from './styles';

import logo from '~/assets/logo/logo-lp-footer.svg';
import twitter from '~/assets/icons/twitter-white.svg';
import facebook from '~/assets/icons/facebook-white.svg';
import telegram from '~/assets/icons/telegram-white.svg';
import instagram from '~/assets/icons/instagram-white.svg';
import { useLanguage } from '~/hooks/Language';

const FooterLp: React.FC = () => {
  const { language } = useLanguage();

  return (
    <Container>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-10 col-xxl-9 px-xxl-5 py-5 my-sm-5">
            <div className="row">
              <div className="col-6 col-md-3 pl-lg-5 pt-5 pt-sm-4 pt-md-0">
                <img src={logo} alt="Logo" className="logo-footer" />
              </div>

              <div className="col-6 col-md-3 ps-lg-5 pb-5 pb-md-0">
                <h2 className="mb-3">Home</h2>

                <AnchorLink href="#why" offset={20} className="d-block">
                  Why Wiserr
                </AnchorLink>
                <AnchorLink href="#courses" offset={-320} className="d-block">
                  Our Courses
                </AnchorLink>
                <AnchorLink href="#stories" offset={-100} className="d-block">
                  Success Stories
                </AnchorLink>
                <a
                  href="https://app.wiserr.io/sign-up"
                  className="d-block"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get Early Access
                </a>
                <a
                  href="https://app.wiserr.io/"
                  className="d-block"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sign In
                </a>
              </div>

              <div className="col-6 col-md-3 ps-lg-5">
                <h2 className="mb-3">Legal</h2>
                <Link
                  to={`${process.env.PUBLIC_URL}/privacy-policy`}
                  className="d-block"
                >
                  Privacy Policy
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/terms-service`}
                  className="d-block"
                >
                  Terms of Service
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/refund-policy`}
                  className="d-block"
                >
                  Refund Policy
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/copyright-policy`}
                  className="d-block"
                >
                  Security
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/cookie-policy`}
                  className="d-block"
                >
                  Cookies
                </Link>
              </div>

              <div className="col-6 col-md-3 ps-lg-5">
                <h2 className="mb-3">Social</h2>
                <Link to="/" className="d-block">
                  Articles
                </Link>
                <Link to="/" className="d-block">
                  Social Channels
                </Link>
                <Link to="/" className="d-block">
                  Media Kit
                </Link>
              </div>

              <div className="col-12">
                <hr />
              </div>

              <div className="col-md-6 pl-4 text-center text-md-left">
                <p className="h6 mb-0">
                  <span className="d-block">{language.footer.p}</span>
                  <br className="d-md-none" />
                  <span className="">
                    <Link
                      to={`${process.env.PUBLIC_URL}/privacy-policy`}
                      className="h6 mb-0"
                    >
                      {language.footer.a_1}
                    </Link>{' '}
                    |{' '}
                    <Link
                      to={`${process.env.PUBLIC_URL}/terms-of-use`}
                      className="h6 mb-0"
                    >
                      {language.footer.a_2}
                    </Link>{' '}
                    |{' '}
                    <Link
                      to={`${process.env.PUBLIC_URL}/dmca-notice`}
                      className="h6 mb-0"
                    >
                      {language.footer.a_3}
                    </Link>{' '}
                    |{' '}
                    <Link
                      to={`${process.env.PUBLIC_URL}/refund-policy`}
                      className="h6 mb-0"
                    >
                      {language.footer.a_4}
                    </Link>{' '}
                    |{' '}
                    <Link
                      to={`${process.env.PUBLIC_URL}/anti-spam-policy`}
                      className="h6 mb-0"
                    >
                      {language.footer.a_5}
                    </Link>{' '}
                    |{' '}
                    <Link
                      to={`${process.env.PUBLIC_URL}/sms-privacy-policy`}
                      className="h6 mb-0"
                    >
                      {language.footer.a_6}
                    </Link>{' '}
                    |{' '}
                    <Link
                      to={`${process.env.PUBLIC_URL}/copyright-notice`}
                      className="h6 mb-0"
                    >
                      {language.footer.a_7}
                    </Link>
                  </span>
                </p>
              </div>

              <div className="col-md-6">
                <div className="d-flex d-md-block justify-content-around text-center text-md-right mt-5 mt-md-0 pr-md-4">
                  <a
                    href="https://twitter.com/WiserrOfficial"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mx-md-2"
                  >
                    <img src={twitter} alt="Twitter" />
                  </a>

                  <a
                    href="https://facebook.com/wiserrofficial/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mx-md-2"
                  >
                    <img src={facebook} alt="Facebook" />
                  </a>

                  <a
                    href="https://t.me/wiserrofficial"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mx-md-2"
                  >
                    <img src={telegram} alt="Telegram" />
                  </a>

                  <a
                    href="https://instagram.com/millonario.digital/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mx-md-2"
                  >
                    <img src={instagram} alt="Instagram" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default FooterLp;
