import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {} from 'react-anchor-link-smooth-scroll';

import { Container, Video } from './styles';

import { useLanguage } from '~/hooks/Language';

import ButtonNoThanks from '~/components/ModalNoThanks';
import Footer from '~/components/FooterViewOffer';
import Attention from '~/components/Attention';
import CreditCardPay from '~/components/CreditCardPay';

import thumb from '~/assets/defaults/thumb-funnel.png';
import api from '~/services/api';
import { useFunnel } from '~/hooks/Funnel';
import { useFunnelTrack } from '~/hooks/FunnelTrack';

interface IOffer {
  id: string;
  title: string;
  price: number;
  recurrence_price: number;
}

const ViewOffer3: React.FC = () => {
  const { language } = useLanguage();
  const {
    funnelsTracks,
    setFunnelTrack,
    ip,
    origin,
    country,
  } = useFunnelTrack();
  const { user } = useFunnel();
  const history = useHistory();
  const [showPage, setshowPage] = useState(() => {
    if (localStorage.getItem('@Wiserr:Progress3end')) {
      return true;
    }
    return false;
  });
  const [offer, setOffer] = useState<IOffer>({} as IOffer);
  const [videoUrl, setVideoUrl] = useState('');
  const [secondaryVideoUrl, setSecondaryVideoUrl] = useState('');

  useEffect(() => {
    setTimeout(() => {
      if (origin) {
        const funnelTrackData = funnelsTracks.find(
          (funnelTrack) =>
            funnelTrack.ip === ip &&
            funnelTrack.page === 'sales-funnel/view-offer-3' &&
            funnelTrack.action === 'first-contact' &&
            funnelTrack.origin === origin
        );
        if (!funnelTrackData) {
          api
            .post('funnels-tracks', {
              page: 'sales-funnel/view-offer-3',
              action: 'first-contact',
              origin,
              country,
            })
            .then(() => {
              setFunnelTrack({
                ip,
                page: 'sales-funnel/view-offer-3',
                action: 'first-contact',
                origin,
                country,
              });
            });
        }
      }
    }, 400);
  }, [funnelsTracks, ip, setFunnelTrack, origin, country]);

  useEffect(() => {
    api.get('offers/funnel/3').then(async (response) => {
      const responseVideo = await api.get(`videos/${response.data.video_id}`, {
        responseType: 'blob',
      });

      const urlData = window.URL.createObjectURL(
        new Blob([responseVideo.data], {
          type: responseVideo.headers['content-type'],
        })
      );

      try {
        const responseSecondaryVideo = await api.get(
          `videos/${response.data.secondary_video_id}`,
          {
            responseType: 'blob',
          }
        );

        const secondaryUrlData = window.URL.createObjectURL(
          new Blob([responseSecondaryVideo.data], {
            type: responseSecondaryVideo.headers['content-type'],
          })
        );

        setSecondaryVideoUrl(secondaryUrlData);
      } finally {
        const data: IOffer = {
          id: response.data.id,
          title: response.data.id,
          price: response.data.price,
          recurrence_price: response.data.recurrence_price,
        };

        setOffer(data);
        setVideoUrl(urlData);
      }
    });
  }, []);

  const handleVideoPlay = useCallback(() => {
    if (origin) {
      const funnelTrackData = funnelsTracks.find(
        (funnelTrack) =>
          funnelTrack.ip === ip &&
          funnelTrack.page === 'sales-funnel/view-offer-3' &&
          funnelTrack.action === 'play-video' &&
          funnelTrack.origin === origin
      );
      if (!funnelTrackData) {
        api
          .post('funnels-tracks', {
            page: 'sales-funnel/view-offer-3',
            action: 'play-video',
            origin,
            country,
          })
          .then(() => {
            setFunnelTrack({
              ip,
              page: 'sales-funnel/view-offer-3',
              action: 'play-video',
              origin,
              country,
            });
          });
      }
    }
  }, [funnelsTracks, ip, setFunnelTrack, origin, country]);

  const page = useCallback(() => {
    localStorage.setItem('@Wiserr:Progress3end', 'true');
    setshowPage(true);

    if (origin) {
      const funnelTrackData = funnelsTracks.find(
        (funnelTrack) =>
          funnelTrack.ip === ip &&
          funnelTrack.page === 'sales-funnel/view-offer-3' &&
          funnelTrack.action === 'ended-video' &&
          funnelTrack.origin === origin
      );
      if (!funnelTrackData) {
        api
          .post('funnels-tracks', {
            page: 'sales-funnel/view-offer-3',
            action: 'ended-video',
            origin,
            country,
          })
          .then(() => {
            setFunnelTrack({
              ip,
              page: 'sales-funnel/view-offer-3',
              action: 'ended-video',
              origin,
              country,
            });
          });
      }
    }
  }, [funnelsTracks, ip, setFunnelTrack, origin, country]);

  const handleSucceededPaid = useCallback(async () => {
    const response = await api.get(`students/admin/${user.id}`);
    if (response.data.affiliate) {
      const affiliate = {
        student_id: user.id,
        active_affiliate: true,
        affiliate_commission: response.data.affiliate.affiliate_commission,
        franchise_owner: response.data.affiliate.franchise_owner,
        crowdfunding_participation:
          response.data.affiliate.crowdfunding_participation,
        crowdfunding_share: response.data.affiliate.crowdfunding_share,
        franchise_commission: response.data.affiliate.franchise_commission,
        is_elite: true,
      };
      await api.put(
        `affiliates/funnel/${response.data.affiliate.id}`,
        affiliate
      );
    }
    history.push(`${process.env.PUBLIC_URL}/sales-funnel/congratulations`);
  }, [history, user.id]);

  return (
    <>
      <Attention />
      <Container>
        <Video className="container">
          <div className="row h-100 align-items-center">
            <div className="col-lg-6 col-xxl-5">
              <div className="row mb-4 mb-xl-5 mt-4 mt-lg-0 pt-5 pt-lg-2 justify-content-center justify-content-lg-between">
                <div className="col-12 text-center font-weight-400 order mb-5">
                  {language.view_offer.div_1} 3 {language.view_offer.div_2} 3:
                </div>
                <div className="col-3 col-lg-2 text-center step_active">
                  <div className="height mx-auto rounded-circle d-flex align-items-center justify-content-center mb-4">
                    <span className="font-weight-bold">1</span>
                  </div>
                </div>
                <div className="col-1 col-lg-2 px-0 px-lg-3 pt-1 pt-sm-2">
                  <hr className="hr_2" />
                </div>
                <div className="col-3 col-lg-2 text-center step_active">
                  <div className="height mx-auto rounded-circle d-flex align-items-center justify-content-center mb-4">
                    <span className="font-weight-bold">2</span>
                  </div>
                </div>
                <div className="col-1 col-lg-2 px-0 px-lg-3 pt-1 pt-sm-2">
                  <hr className="hr_3" />
                </div>
                <div className="col-3 col-lg-2 text-center step_active">
                  <div className="height mx-auto rounded-circle d-flex align-items-center justify-content-center mb-4">
                    <span className="font-weight-bold">3</span>
                  </div>
                </div>
              </div>
              <h1 className="h2 h1-xl mb-5 font-weight-300 mx-auto mx-md-0">
                {language.view_offer.h1}{' '}
                <span className="font-weight-500 text-gradient-video">
                  {language.view_offer.h1_span}
                </span>
              </h1>
              {showPage && (
                <div className="d-lg-flex mb-md-5 mb-lg-0">
                  <CreditCardPay
                    product_id={offer.id}
                    text_button={language.view_offer.button}
                    price={offer.price}
                    onSucceededPaid={handleSucceededPaid}
                    page="sales-funnel/view-offer-3"
                    action="upgrade-order"
                  />
                  <ButtonNoThanks
                    product_id={offer.id}
                    price={offer.price}
                    video_url={secondaryVideoUrl}
                    onSucceededPaid={handleSucceededPaid}
                    page="sales-funnel/view-offer-3"
                    action="dont-want-order"
                  />
                </div>
              )}
            </div>
            <div className="col-lg-6 d-flex pr-xl-0 justify-content-end video-position">
              <video
                src={videoUrl}
                controls
                id="video"
                poster={thumb}
                onPlay={handleVideoPlay}
                onEnded={() => {
                  page();
                }}
              >
                <track
                  default
                  kind="captions"
                  srcLang="pt-BR"
                  src="https://www.w3schools.com/html/mov_bbb.mp4"
                />
              </video>
            </div>
          </div>
        </Video>
      </Container>

      <Footer />
    </>
  );
};

export default ViewOffer3;
