import React from 'react';
import { useLanguage } from '~/hooks/Language';

import { Container } from './styles';
import Contact from '~/components/Contact';

const SmsPrivacyPolicy: React.FC = () => {
  const { language } = useLanguage();

  return (
    <>
      <Container>
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <h1>
                Wiserr.io
                <br />
                {language.smsPolicy.h1}
              </h1>
              <hr className="mt-3 mb-5" />
              <div className="mb-5">
                <h2 className="h3">{language.smsPolicy.h2_1}</h2>
                <p>{language.smsPolicy.p_1}</p>
                <p>{language.smsPolicy.p_2}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.smsPolicy.h2_2}</h2>
                <p>{language.smsPolicy.p_3}</p>
                <p>{language.smsPolicy.p_4}</p>
                <p>{language.smsPolicy.p_5}</p>
                <p>{language.smsPolicy.p_6}</p>
                <p>
                  {language.smsPolicy.p_7}{' '}
                  <a href="mailto:support@wiserr.io">support@wiserr.io</a>
                </p>
                <p>{language.smsPolicy.p_8}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.smsPolicy.h2_3}</h2>
                <p>{language.smsPolicy.p_9}</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Contact />
    </>
  );
};

export default SmsPrivacyPolicy;
