import React, { useCallback, useEffect, useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Helmet } from 'react-helmet';
import { IoIosArrowUp } from 'react-icons/io';
import { useHistory } from 'react-router-dom';

import { useLanguage } from '~/hooks/Language';
import { useFunnelTrack } from '~/hooks/FunnelTrack';

import {
  Container,
  VideoSide,
  Message,
  RealFast,
  Member,
  Bonus,
  Next,
  ForAll,
  ButtonToUp,
} from './styles';

import Button from '~/components/ModalSalesFunnel';
import Attention from '~/components/Attention';
import FooterBlack from './FooterBlack';
import Video from './Video';

import quotes from '~/assets/icons/quotes.svg';
import heart from '~/assets/icons/heart.svg';
import eye from '~/assets/icons/eye.svg';
import plane from '~/assets/icons/plane.svg';
import forAllMobile from '~/assets/defaults/img-for-all.png';

import api from '~/services/api';
import { formatPrice } from '~/utils/format';

interface IOffer {
  id: string;
  title: string;
  price: string;
  recurrence_price: string;
}

const SalesFunnel: React.FC = () => {
  const history = useHistory();
  const { language } = useLanguage();
  const {
    funnelsTracks,
    setFunnelTrack,
    ip,
    origin,
    country,
  } = useFunnelTrack();
  const [scroll, setScroll] = useState(0);
  const [offer, setOffer] = useState<IOffer>({} as IOffer);
  const [videoUrl, setVideoUrl] = useState('');
  const [showPage, setshowPage] = useState(() => {
    if (localStorage.getItem('@Wiserr:videoEnded')) {
      return true;
    }
    return false;
  });

  useEffect(() => {
    console.log(history);
  }, [history]);

  useEffect(() => {
    setTimeout(() => {
      if (origin) {
        const funnelTrackData = funnelsTracks.find(
          (funnelTrack) =>
            funnelTrack.ip === ip &&
            funnelTrack.page === 'sales-funnel' &&
            funnelTrack.action === 'first-contact' &&
            funnelTrack.origin === origin
        );

        if (!funnelTrackData) {
          api
            .post('funnels-tracks', {
              page: 'sales-funnel',
              action: 'first-contact',
              origin,
              country,
            })
            .then(() => {
              setFunnelTrack({
                ip,
                page: 'sales-funnel',
                action: 'first-contact',
                origin,
                country,
              });
            });
        }
      }
    }, 400);
  }, [funnelsTracks, ip, setFunnelTrack, origin, country]);

  const upScroll = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const page = useCallback(() => {
    localStorage.setItem('@Wiserr:videoEnded', 'true');
    setshowPage(true);

    if (origin) {
      const funnelTrackData = funnelsTracks.find(
        (funnelTrack) =>
          funnelTrack.ip === ip &&
          funnelTrack.page === 'sales-funnel' &&
          funnelTrack.action === 'ended-video' &&
          funnelTrack.origin === origin
      );
      if (!funnelTrackData) {
        api
          .post('funnels-tracks', {
            page: 'sales-funnel',
            action: 'ended-video',
            origin,
            country,
          })
          .then(() => {
            setFunnelTrack({
              ip,
              page: 'sales-funnel',
              action: 'ended-video',
              origin,
              country,
            });
          });
      }
    }
  }, [funnelsTracks, ip, setFunnelTrack, origin, country]);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY);
    });
  }, []);

  useEffect(() => {
    api.get('offers/funnel/1').then(async (response) => {
      const responseVideo = await api.get(`videos/${response.data.video_id}`, {
        responseType: 'blob',
      });

      const urlData = window.URL.createObjectURL(
        new Blob([responseVideo.data], {
          type: responseVideo.headers['content-type'],
        })
      );

      const priceParts = formatPrice(response.data.price).split('.');
      const recurrencePriceParts = formatPrice(
        response.data.recurrence_price
      ).split('.');

      const data: IOffer = {
        id: response.data.id,
        title: response.data.id,
        price: priceParts[0],
        recurrence_price: recurrencePriceParts[0],
      };

      setOffer(data);
      setVideoUrl(urlData);
    });
  }, []);

  const handleVideoPlay = useCallback(() => {
    if (origin) {
      const funnelTrackData = funnelsTracks.find(
        (funnelTrack) =>
          funnelTrack.ip === ip &&
          funnelTrack.page === 'sales-funnel' &&
          funnelTrack.action === 'play-video' &&
          funnelTrack.origin === origin
      );

      if (!funnelTrackData) {
        api
          .post('funnels-tracks', {
            page: 'sales-funnel',
            action: 'play-video',
            origin,
            country,
          })
          .then(() => {
            setFunnelTrack({
              ip,
              page: 'sales-funnel',
              action: 'play-video',
              origin,
              country,
            });
          });
      }
    }
  }, [funnelsTracks, ip, setFunnelTrack, origin, country]);

  return (
    <>
      <Helmet>
        <meta property="og:title" content={language.head.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://wiserr.io" />
        <meta
          property="og:image:url"
          itemProp="image"
          content="http://cdn.wiserr.io/promotional-stuff/image-social-media.png"
        />
        <meta
          property="og:image"
          itemProp="image"
          content="http://cdn.wiserr.io/promotional-stuff/image-social-media.png"
        />
        <meta property="og:description" content={language.head.description} />
      </Helmet>
      {!showPage && <Attention />}
      <Container>
        <VideoSide className="container py-lg-4 py-xl-0 my-lg-5">
          <div
            className={`${
              showPage && 'pt-5'
            } row h-100 my-4 my-xxl-0 align-items-center`}
          >
            <div className="col-lg-5 mb-5 mb-lg-0">
              <h1 className="h2 h1-xl mb-5 mb-lg-4 mb-xl-5 font-weight-300 mx-auto mx-md-0">
                {language.sales_funnel.h1_1}{' '}
                <span className="font-weight-600">
                  {language.sales_funnel.h1_2}
                </span>{' '}
                {language.sales_funnel.h1_3}
                <br />
                <span className="font-weight-bold text-gradient-video">
                  {language.sales_funnel.h1_span}
                </span>
              </h1>

              <div className="d-lg-none">
                <Video
                  src={videoUrl}
                  videoPlay={handleVideoPlay}
                  videoEnded={page}
                />
              </div>

              <p className="xxs-size h4-xxl mb-4 mt-5 mt-lg-0 font-weight-600">
                {language.sales_funnel.p_1}
              </p>
              <p className="xxs-size h4-xxl mb-0 font-weight-300">
                {language.sales_funnel.p_2_1}{' '}
                <span className="font-weight-600">
                  {language.sales_funnel.p_2_2}
                </span>{' '}
                {language.sales_funnel.p_2_3}
              </p>
              {showPage && <Button {...offer} />}
            </div>
            <div className="col-lg-7 col-xl-6 pr-lg-0 justify-content-end video-position d-none d-lg-flex">
              <Video
                src={videoUrl}
                videoPlay={handleVideoPlay}
                videoEnded={page}
              />
            </div>
          </div>
        </VideoSide>
        {!showPage && (
          <div className="container">
            <div className="row">
              <div className="col-12 text-center text-lg-left text-bottom font-weight-300 mb-4">
                {language.sales_funnel.div}
              </div>
            </div>
          </div>
        )}
        {showPage && (
          <>
            <Message className="d-flex flex-column justify-content-center p-relative py-lg-5 mt-5">
              <div className="container mb-5 px-3">
                <div className="row justify-content-center">
                  <div className="col-lg-11">
                    <div className="bg-white-message">
                      <h2 className="mb-3 mb-xxl-5 font-weight-600">
                        {language.sales_funnel.h2_1}{' '}
                        <span className="text-gradient-message">
                          {language.sales_funnel.h2_1_span}
                        </span>
                      </h2>
                      <h3>
                        {language.sales_funnel.h3_1}{' '}
                        <span className="gradient-from">David Motta</span>
                      </h3>
                      <h3>
                        {language.sales_funnel.h3_2}{' '}
                        <span className="gradient-to">
                          {language.sales_funnel.h3_2_span}
                        </span>
                      </h3>

                      <p className="font-weight-300 mt-5 mb-4">
                        {language.sales_funnel.p_3}
                      </p>
                      <p className="font-weight-300 mb-4">
                        {language.sales_funnel.p_4_1}{' '}
                        <b className="font-weight-600">
                          {language.sales_funnel.b_1}
                        </b>
                        <br />
                        {language.sales_funnel.p_4_2}{' '}
                        <b className="font-weight-600">
                          {language.sales_funnel.b_2}
                        </b>{' '}
                        {language.sales_funnel.p_4_3}
                        <br />
                        {language.sales_funnel.p_4_4}{' '}
                        <b className="font-weight-600">
                          {' '}
                          {language.sales_funnel.b_3}
                        </b>
                        <br />
                        {language.sales_funnel.p_4_5}
                      </p>
                      <p className="font-weight-300 mb-4">
                        {language.sales_funnel.p_5_1}
                        <br />
                        {language.sales_funnel.p_5_2}
                        <br />
                        {language.sales_funnel.p_5_3}
                      </p>
                      <p className="font-weight-300 mb-4">
                        {language.sales_funnel.p_6_1}
                        <br />
                        {language.sales_funnel.p_6_2}
                        <br />
                        {language.sales_funnel.p_6_3}
                      </p>
                      <div className="d-flex justify-content-end">
                        <div className="size">
                          <img src={heart} alt="heart-icon" className="mr-2" />
                          120
                        </div>
                        <div className="size mx-5">
                          <img src={eye} alt="eye-icon" className="mr-2" />
                          540
                        </div>
                        <div className="size">
                          <img src={plane} alt="plane-icon" className="mr-2" />
                          50
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Message>

            <RealFast className="d-flex flex-column justify-content-center p-relative my-5 my-xxl-0 py-lg-4">
              <div className="bg-yellow">
                <div className="container px-4 px-lg-3">
                  <div className="row">
                    <div className="col-xl-8">
                      <div className="row justify-content-between">
                        <div className="col-lg-9 col-xl-12">
                          <h2 className="mb-3 mb-md-5 font-weight-600">
                            {language.sales_funnel.h2_2_1}
                            <br />
                            {language.sales_funnel.h2_2_2}{' '}
                            <span className="text-gradient-about-us">
                              {language.sales_funnel.h2_2_span}
                            </span>
                          </h2>
                        </div>

                        <img
                          src={quotes}
                          alt="quotes"
                          className="p-absolute top"
                        />
                        <div className="col-lg-9 col-xl-12 mb-5">
                          <p className="h5-sm h4-xxl mt-xxl-3 mb-xxl-5 color pr-3 pr-sm-5 font-weight-300">
                            <b className="font-weight-600">
                              {language.sales_funnel.p_7_b}
                            </b>
                            <br />
                            {language.sales_funnel.p_7}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-blue-pink">
                <div className="bg-green">
                  <div className="container px-4 px-lg-3">
                    <div className="row justify-content-center justify-content-lg-start">
                      <div className="col-10 col-lg-8 col-xl-7">
                        <div className="row mb-3">
                          <div className="col-lg-6 mb-5 mb-lg-0 d-flex">
                            <div className="bd-left h-100" />
                            <p className="h6-sm font-weight-300 py-2 pl-3 pr-0 mb-0">
                              {language.sales_funnel.p_8}
                            </p>
                          </div>

                          <div className="col-lg-6 d-flex mt-5 mt-md-0 pr-xxl-5">
                            <div className="bd-left h-100" />
                            <p className="h6-sm font-weight-300 py-2 pl-3 px-sm-4 pr-md-5 pr-lg-4 mb-0">
                              {language.sales_funnel.p_9}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </RealFast>

            <Member className="py-lg-5 mt-5">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12">
                    <h2 className="font-weight-600 mb-0 px-lg-3">
                      <span className="">{language.sales_funnel.h2_span} </span>
                      <br />
                      {language.sales_funnel.h2_3}
                    </h2>
                  </div>

                  <div className="col-xl-12 mt-5">
                    <div className="row">
                      <div className="col-md-6 col-xl-3 px-4 mb-5 mb-xl-0">
                        <div className="border-box px-3 hover pb-4">
                          <h3 className="font-weight-600 py-4 py-lg-5 mb-0">
                            {language.sales_funnel.h2_4}
                          </h3>
                          <p className="font-weight-300">
                            {language.sales_funnel.p_10}
                          </p>
                          <div className="bg-arrow mt-4">
                            <div className="d-flex h-100 align-items-center justify-content-center">
                              <MdKeyboardArrowRight size={25} color="#fff" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-3 px-4 mb-5 mb-xl-0">
                        <div className="border-box px-3 hover pb-4">
                          <h3 className="font-weight-600 py-4 py-lg-5 mb-0">
                            {language.sales_funnel.h3_3}
                          </h3>
                          <p className="font-weight-300">
                            {language.sales_funnel.p_11}
                          </p>
                          <div className="bg-arrow mt-4">
                            <div className="d-flex h-100 align-items-center justify-content-center">
                              <MdKeyboardArrowRight size={25} color="#fff" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 px-4 mb-5 mb-xl-0">
                        <div className="border-box px-3 hover pb-4">
                          <h3 className="font-weight-600 py-4 py-lg-5 mb-0">
                            {language.sales_funnel.h3_4}
                          </h3>
                          <p className="font-weight-300">
                            {language.sales_funnel.p_12}
                          </p>
                          <div className="bg-arrow mt-4">
                            <div className="d-flex h-100 align-items-center justify-content-center">
                              <MdKeyboardArrowRight size={25} color="#fff" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-xl-3 px-4 mb-5 mb-xl-0">
                        <div className="border-box px-3 hover pb-4">
                          <h3 className="font-weight-600 py-4 py-lg-5 mb-0">
                            {language.sales_funnel.h3_5}
                          </h3>
                          <p className="font-weight-300">
                            {language.sales_funnel.p_13}
                          </p>
                          <div className="bg-arrow mt-4">
                            <div className="d-flex h-100 align-items-center justify-content-center">
                              <MdKeyboardArrowRight size={25} color="#fff" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-xl-3 px-4 mb-5 mb-xl-0">
                        <div className="border-box px-3 hover pb-4">
                          <h3 className="font-weight-600 py-4 py-lg-5 mb-0">
                            {language.sales_funnel.h3_6}
                          </h3>
                          <p className="font-weight-300">
                            {language.sales_funnel.p_14}
                          </p>
                          <div className="bg-arrow mt-4">
                            <div className="d-flex h-100 align-items-center justify-content-center">
                              <MdKeyboardArrowRight size={25} color="#fff" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 px-4">
                        <div className="border-box px-3 hover pb-4">
                          <h3 className="font-weight-600 py-4 py-lg-5 mb-0">
                            {language.sales_funnel.h3_7}
                          </h3>
                          <p className="font-weight-300">
                            {language.sales_funnel.p_15}
                          </p>
                          <div className="bg-arrow mt-4">
                            <div className="d-flex h-100 align-items-center justify-content-center">
                              <MdKeyboardArrowRight size={25} color="#fff" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Member>

            <Bonus className="py-5">
              <div className="container px-4 px-lg-3">
                <div className="row mt-lg-5 align-items-center justify-content-center">
                  <div className="col-lg-8 my-lg-5">
                    <h2 className="mb-0 font-weight-600">
                      {language.sales_funnel.h2_5}{' '}
                      <br className="d-none d-lg-block" />
                      <span>{language.sales_funnel.h2_5_span}</span>
                    </h2>
                  </div>
                  <div className="col-lg-4 my-5 text-center">
                    <Button {...offer} />
                  </div>
                  <div className="col-11 col-md-10 col-lg-12 col-xl-11 d-lg-flex border-card my-xl-5 px-0">
                    <div className="col-12 col-lg-4 py-4 py-lg-5 px-4 px-lg-3 p-sm-5 p-md-4 p-lg-5 hover">
                      <div className="d-flex bg-white p-absolute d-lg-none">
                        <span className="h1 number-gradient mb-0 px-2 font-weight-600">
                          01
                        </span>
                      </div>
                      <div className="">
                        <h3 className="d-flex mt-4 mt-lg-0 pt-5 pt-lg-0 pb-5 pb-lg-3 font-weight-600">
                          {language.sales_funnel.h3_8}
                          <span className="d-none d-lg-block">&nbsp;01</span>
                        </h3>
                        <p className="pt-4 pt-lg-5 mb-5 mb-lg-0 font-weight-300">
                          {language.sales_funnel.p_16}
                        </p>
                      </div>
                    </div>

                    <div className="col-12 col-lg-4 py-4 py-lg-5 px-4 px-lg-3 p-sm-5 p-md-4 p-lg-5 hover">
                      <div className="d-flex bg-white p-absolute d-lg-none">
                        <span className="h1 number-gradient mb-0 px-2 font-weight-600">
                          02
                        </span>
                      </div>
                      <div className="">
                        <h3 className="d-flex mt-4 mt-lg-0 pt-5 pt-lg-0 pb-5 pb-lg-3 font-weight-600">
                          {language.sales_funnel.h3_9}
                          <span className="d-none d-lg-block">&nbsp;02</span>
                        </h3>
                        <p className="pt-4 pt-lg-5 mb-5 mb-lg-0 font-weight-300">
                          {language.sales_funnel.p_17}
                        </p>
                      </div>
                    </div>

                    <div className="col-12 col-lg-4 py-4 py-lg-5 px-4 px-lg-3 p-sm-5 p-md-4 p-lg-5 hover">
                      <div className="d-flex bg-white p-absolute d-lg-none">
                        <span className="h1 number-gradient mb-0 px-2 font-weight-600">
                          03
                        </span>
                      </div>
                      <div className="">
                        <h3 className="d-flex mt-4 mt-lg-0 pt-5 pt-lg-0 pb-5 pb-lg-3 font-weight-600">
                          {language.sales_funnel.h3_10}
                          <span className="d-none d-lg-block">&nbsp;03</span>
                        </h3>
                        <p className="pt-4 pt-lg-5 mb-5 mb-lg-0 font-weight-300">
                          {language.sales_funnel.p_18}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Bonus>

            <ForAll className="d-flex flex-column justify-content-center p-relative">
              <div className="container px-lg-3">
                <div className="row justify-content-end">
                  <div className="col-lg-6 px-4 pr-lg-3 pl-lg-0">
                    <div className="d-flex align-items-center justify-content-between mb-5 mb-lg-0">
                      <h2 className="text-gradient-for-all mb-0 mb-xxl-5 ml-5 ml-lg-0 font-weight-600 px-4 px-lg-0 text-right text-lg-left order-1 order-lg-0">
                        {language.sales_funnel.h2_6}
                      </h2>
                      <img
                        src={quotes}
                        alt="quotes"
                        className="p-lg-absolute top order-0 order-lg-1"
                      />
                    </div>

                    <p className="my-4 my-xl-5 mt-lg-3 font-weight-400">
                      {language.sales_funnel.p_19}
                    </p>
                    <p className="my-4 my-xl-5 mt-lg-3 font-weight-400">
                      {language.sales_funnel.p_20}
                    </p>
                    <p className="my-4 my-xl-5 mt-lg-3 font-weight-400">
                      {language.sales_funnel.p_21_1}
                      <br />
                      {language.sales_funnel.p_21_2}
                      <br />
                      {language.sales_funnel.p_21_3}
                    </p>
                    <p className="my-4 my-xl-5 font-weight-400">
                      {language.sales_funnel.p_22_1}
                      <br />
                      {language.sales_funnel.p_22_2}
                    </p>
                  </div>
                </div>
              </div>
            </ForAll>

            <div className="d-lg-none">
              <img src={forAllMobile} alt="" className="w-95" />
            </div>

            <Next className="py-lg-5">
              <div className="container px-4 px-lg-3">
                <div className="row mt-lg-5 align-items-center justify-content-center">
                  <div className="col-lg-6 mt-5 my-lg-5">
                    <div className="d-flex">
                      <h2 className="mb-0 font-weight-600">
                        {language.sales_funnel.h2_7}{' '}
                        <span>{language.sales_funnel.h2_7_span}</span>
                      </h2>
                      <img
                        src={quotes}
                        alt="quotes"
                        className="p-lg-absolute top"
                      />
                    </div>
                    <p className="font-weight-300 mt-4 mt-xl-5">
                      {language.sales_funnel.p_23}
                    </p>
                  </div>

                  <div className="col-lg-6 my-5 text-center text-lg-right pad-right">
                    <Button {...offer} />
                  </div>
                  <div className="col-11 col-md-10 col-lg-12 col-xl-11 d-lg-flex border-card mb-5 my-xl-5 px-0">
                    <div className="col-12 col-lg-4 py-5 px-4 px-lg-3 p-sm-5 p-md-4 p-lg-5 hover">
                      <div className="">
                        <h3 className="pb-4 pb-lg-3 mt-5 mt-lg-0  font-weight-600">
                          {language.sales_funnel.h3_11}
                        </h3>
                        <p className="pt-4 mb-5 mb-lg-2 pt-lg-5 font-weight-300">
                          {language.sales_funnel.p_24_1}{' '}
                          <span className="font-weight-600">
                            {language.sales_funnel.p_24_span}
                          </span>{' '}
                          {language.sales_funnel.p_24_2}
                        </p>
                      </div>
                    </div>

                    <div className="col-12 col-lg-4 py-5 px-4 px-lg-3 p-sm-5 p-md-4 p-lg-5 hover">
                      <div className="">
                        <h3 className="pb-4 pb-lg-3 mt-5 mt-lg-0  font-weight-600">
                          {language.sales_funnel.h3_12}
                        </h3>
                        <p className="pt-4 mb-5 mb-lg-2 pt-lg-5 font-weight-300">
                          {language.sales_funnel.p_25}
                        </p>
                      </div>
                    </div>

                    <div className="col-12 col-lg-4 py-5 px-4 px-lg-3 p-sm-5 p-md-4 p-lg-5 hover">
                      <div className="">
                        <h3 className="pb-4 pb-lg-3 mt-5 mt-lg-0  font-weight-600">
                          {language.sales_funnel.h3_13}
                        </h3>
                        <p className="pt-4 mb-5 mb-lg-2 pt-lg-5 font-weight-300">
                          {language.sales_funnel.p_26}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Next>
          </>
        )}
      </Container>
      {showPage && (
        <>
          <FooterBlack />
          <ButtonToUp
            type="button"
            onClick={upScroll}
            className={`border-0 rounded-circle p-2 p-lg-3 ${
              scroll < 1000 ? 'd-none' : ''
            }`}
          >
            <IoIosArrowUp size={23} className="mb-1" />
          </ButtonToUp>
        </>
      )}
    </>
  );
};

export default SalesFunnel;
