import React from 'react';

import { useLanguage } from '~/hooks/Language';

import { Container } from './styles';
import Contact from '~/components/Contact';

const TermsOfUse: React.FC = () => {
  const { language } = useLanguage();

  return (
    <>
      <Container>
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <h1>
                Wiserr.io
                <br />
                {language.termsUse.h1}
              </h1>
              <hr className="mt-3 mb-5" />
              <div className="mb-5">
                <h2 className="h3">{language.termsUse.h2_1}</h2>
                <p>{language.termsUse.p_1}</p>
                <p>{language.termsUse.p_2}</p>
                <p>{language.termsUse.p_3}</p>
                <p>{language.termsUse.p_4}</p>
                <p>{language.termsUse.p_5}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.termsUse.h2_2}</h2>
                <p>{language.termsUse.p_6}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.termsUse.h2_3}</h2>
                <p>{language.termsUse.p_7}</p>
                <p>{language.termsUse.p_8}</p>
                <p>{language.termsUse.p_9}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.termsUse.h2_4}</h2>
                <p>{language.termsUse.p_10}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.termsUse.h2_5}</h2>
                <p>{language.termsUse.p_11}</p>
                <p>{language.termsUse.p_12}</p>
                <p>{language.termsUse.p_13}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.termsUse.h2_6}</h2>
                <p>{language.termsUse.p_14}</p>
                <p>{language.termsUse.p_15}</p>
                <p>{language.termsUse.p_16}</p>
                <p>{language.termsUse.p_17}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.termsUse.h2_7}</h2>
                <p>{language.termsUse.p_18}</p>
                <ol>
                  <li>{language.termsUse.li_1}</li>
                  <li>{language.termsUse.li_2}</li>
                  <li>{language.termsUse.li_3}</li>
                  <li>{language.termsUse.li_4}</li>
                  <li>{language.termsUse.li_5}</li>
                  <li>{language.termsUse.li_6}</li>
                  <li>{language.termsUse.li_7}</li>
                  <li>{language.termsUse.li_8}</li>
                  <li>{language.termsUse.li_9}</li>
                  <li>{language.termsUse.li_10}</li>
                  <li>{language.termsUse.li_11}</li>
                  <li>{language.termsUse.li_12}</li>
                </ol>
                <p>{language.termsUse.p_19}</p>
                <p>{language.termsUse.p_20}</p>
                <p>{language.termsUse.p_21}</p>
                <p>{language.termsUse.p_22}</p>
                <p>{language.termsUse.p_23}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.termsUse.h2_8}</h2>
                <p>{language.termsUse.p_24}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.termsUse.h2_9}</h2>
                <p>{language.termsUse.p_25}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.termsUse.h2_10}</h2>
                <p>{language.termsUse.p_26}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.termsUse.h2_11}</h2>
                <p>{language.termsUse.p_27}</p>
                <p>{language.termsUse.p_28}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.termsUse.h2_12}</h2>
                <p>{language.termsUse.p_29}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.termsUse.h2_13}</h2>
                <p>{language.termsUse.p_30}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.termsUse.h2_14}</h2>
                <p>{language.termsUse.p_31}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.termsUse.h2_15}</h2>
                <p>{language.termsUse.p_32}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.termsUse.h2_16}</h2>
                <p>{language.termsUse.p_33}</p>
                <p>{language.termsUse.p_34}</p>
                <p>{language.termsUse.p_35}</p>
                <p>{language.termsUse.p_36}</p>
                <p>{language.termsUse.p_37}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.termsUse.h2_17}</h2>
                <p>
                  <b>{language.termsUse.p_38_b}</b>
                  {language.termsUse.p_38}
                </p>
                <p>
                  <b>{language.termsUse.p_39_b}</b>
                  {language.termsUse.p_39}
                </p>
                <p>
                  <b>{language.termsUse.p_40_b}</b>
                  {language.termsUse.p_40}
                </p>
                <p>
                  <b>{language.termsUse.p_41_b}</b>
                  {language.termsUse.p_41}
                </p>
                <p>
                  <b>{language.termsUse.p_42_b}</b>
                  {language.termsUse.p_42}
                </p>
                <p>
                  <b>{language.termsUse.p_43_b}</b>
                  {language.termsUse.p_43}
                </p>
                <p>Wiserr, Inc.</p>
                <p>30 N Gould St Ste 21152, </p>
                <p>Sheridan, WY, 92801</p>
                <p>
                  <a href="tel:8447770500">(844) 777 0500</a>
                </p>
                <p>
                  <a href="mailto:support@wiserr.io">support@wiserr.io</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Contact />
    </>
  );
};

export default TermsOfUse;
