import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  p {
    span {
      font-weight: 300;
      color: #8b8b8b;
    }
    strong {
      color: #292929;
    }
  }
  h1,
  h2,
  h4 {
    color: #202020;
  }
  video {
    border-radius: 20px;
    width: 100%;
  }

  button {
    border-radius: 20px;
    font-size: 2rem;
    border: 0;
    color: #fff;
    height: 70px;
    width: 100%;
    background: #fb5a43 !important;
    background: linear-gradient(
      270deg,
      #fb5a43 0.03%,
      #c341ae 30.44%,
      #9445c2 57.35%,
      #079cfe 95.73%
    ) !important;
    :hover {
      color: ${darken(0.1, '#fff')};
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5) !important;
    }
  }

  button {
    img {
      width: 45px;
      padding-bottom: 6px;
      margin-right: 30px;
    }
  }

  .rad-20 {
    border-radius: 20px;
    border: 1px solid #dadada;
  }

  @media (max-width: 991px) {
    button {
      font-size: 1.5rem;
      height: 50px;
      border-radius: 15px;
    }
  }

  @media (max-width: 575px) {
    h1 {
      line-height: 2.5rem;
    }

    button {
      font-size: 1.1rem;
      img {
        width: 33px;
        padding-bottom: 2px;
        margin-right: 15px;
      }
    }

    .size {
      font-size: 12px;
    }

    .id-size {
      font-size: 14px;
    }
  }
`;
