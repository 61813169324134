/* eslint-disable react/jsx-indent */
import React, { FC, ComponentType, useMemo } from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouterProps,
} from 'react-router-dom';

import LandingPage from '~/pages/_Layouts/LandingPage';
import Default from '~/pages/_Layouts/Default';

interface RouteProps extends ReactDOMRouterProps {
  showMenu?: boolean;
  component: ComponentType;
}

const Route: FC<RouteProps> = ({ component: Component, showMenu, ...rest }) => {
  const Layout = useMemo(() => {
    return showMenu ? LandingPage : Default;
  }, [showMenu]);

  return (
    <ReactDOMRoute
      {...rest}
      render={() => (
        <Layout>
          <Component />
        </Layout>
      )}
    />
  );
};

export default Route;
