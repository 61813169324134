import React, { useState, useCallback } from 'react';
import { ReactCreditCard } from '@repay/react-credit-card';
import '@repay/react-credit-card/dist/react-credit-card.css';

import { useLanguage } from '~/hooks/Language';

import { Container } from './styles';
import InputMask from '~/components/InputMask';
import Input from '~/components/Input';

interface ICreditCard {
  id?: string;
  name: string;
  number: string;
  expiration: string;
  cvc: string;
  flag: string;
}

interface creditCardProps {
  initialValue?: ICreditCard;
  className?: string;
}

const CreditCard: React.FC<creditCardProps> = ({ initialValue, className }) => {
  const { language } = useLanguage();
  const [values, setValues] = useState({
    name: initialValue ? initialValue.name : '',
    number: initialValue ? initialValue.number : '',
    expiration: initialValue ? initialValue.expiration : '',
    cvc: initialValue ? initialValue.cvc : '',
    flag: initialValue ? initialValue.flag : '',
  });

  const handleChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      const noSpaceValue = value.replace(/ /g, '');
      setValues((v) => ({
        ...v,
        [name]: name === 'number' ? noSpaceValue : value,
      }));
      if (name === 'number') {
        const ReactCreditCardElement = document.getElementsByClassName(
          'ReactCreditCard'
        )[0];
        let flag = ReactCreditCardElement.classList[1].replace(
          'ReactCreditCard--',
          ''
        );

        flag = flag.charAt(0).toUpperCase() + flag.slice(1);
        setValues((v) => ({ ...v, flag: flag === 'Unknown' ? '' : flag }));
      }
    },
    [setValues]
  );

  const [focused, setFocus] = useState<
    'number' | 'cvc' | 'expiration' | 'name' | undefined
  >(undefined);
  const handleFocus = useCallback(
    (event) => {
      setFocus(event.target.name || event.target.placeholder.toLowerCase());
    },
    [setFocus]
  );
  const handleBlur = React.useCallback(() => setFocus(undefined), [setFocus]);

  return (
    <Container className={className}>
      <div className="credit-card-preview w-100 w-lg-50 text-center">
        <ReactCreditCard {...values} focused={focused} />
      </div>
      <div className="credit-card-data mt-5 mt-lg-0 w-100 w-lg-50">
        <InputMask
          kind="credit-card"
          name="number"
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={values.number}
          placeholder={language.credit_card_component.input_1}
          className="mt-2 py-3 py-lg-2"
        />
        <Input
          name="name"
          onChange={(e) => setValues({ ...values, name: e.target.value })}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={values.name}
          placeholder={language.credit_card_component.input_2}
          className="mt-2 py-3 py-lg-2"
        />
        <InputMask
          kind="datetime"
          options={{
            format: 'MM/YY',
          }}
          name="expiration"
          placeholder={language.credit_card_component.input_3}
          className="form-control mt-2 py-3 py-lg-2"
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={values.expiration}
        />
        <Input
          name="cvc"
          onChange={(e) =>
            setValues({ ...values, cvc: e.target.value.slice(0, 3) })
          }
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={values.cvc.slice(0, 3)}
          placeholder={language.credit_card_component.input_4}
          className="form-control mt-2 py-3 py-lg-2"
        />
        <Input
          name="flag"
          onChange={(e) => setValues({ ...values, flag: e.target.value })}
          value={values.flag}
          placeholder={language.credit_card_component.input_5}
          className="form-control mt-2 py-3 py-lg-2"
        />
      </div>
    </Container>
  );
};

export default CreditCard;
