import styled from 'styled-components';

export const Footer = styled.footer`
  p {
    color: #6e6e6e;
    font-size: 14px;
    font-weight: 300;
    span {
      color: #a0a0a0;
    }
  }

  a {
    color: #a0a0a0;
  }

  button {
    color: #000;
    background-color: #f7f7f7;
    position: fixed;
    z-index: 1000;
    bottom: 50px;
    right: 16%;
    height: 55px;
  }

  @media screen and (max-width: 575px) {
    p {
      font-size: 12px;
    }
  }
`;
