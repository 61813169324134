import React, { useEffect } from 'react';

import {} from 'react-anchor-link-smooth-scroll';

import { FaExclamationCircle } from 'react-icons/fa';
import { Video, Attention, Text, Access, Steps, Chat } from './styles';

import { useLanguage } from '~/hooks/Language';

import Footer from '~/components/FooterViewOffer';
import FooterBlack from '../FooterBlack';
import thumb from '~/assets/defaults/thumb-funnel.png';
import quotes from '~/assets/icons/quotes.svg';
import signature from '~/assets/logo/signature.svg';
import company from '~/assets/logo/company.svg';
import chat from '~/assets/logo/chat.svg';
import tabletMob from '~/assets/defaults/tablet-mob.png';
import tabletRight from '~/assets/defaults/tablet-right.png';
import tabletLeft from '~/assets/defaults/tablet-left.png';
import { useFunnelTrack } from '~/hooks/FunnelTrack';
import api from '~/services/api';

const Congratulations: React.FC = () => {
  const { language } = useLanguage();
  const {
    funnelsTracks,
    setFunnelTrack,
    ip,
    origin,
    country,
  } = useFunnelTrack();

  useEffect(() => {
    setTimeout(() => {
      if (origin) {
        const funnelTrackData = funnelsTracks.find(
          (funnelTrack) =>
            funnelTrack.ip === ip &&
            funnelTrack.page === 'sales-funnel/congratulations' &&
            funnelTrack.action === 'first-contact' &&
            funnelTrack.origin === origin
        );
        if (!funnelTrackData) {
          api
            .post('funnels-tracks', {
              page: 'sales-funnel/congratulations',
              action: 'first-contact',
              origin,
              country,
            })
            .then(() => {
              setFunnelTrack({
                ip,
                page: 'sales-funnel/congratulations',
                action: 'first-contact',
                origin,
                country,
              });
            });
        }
      }
    }, 400);
  }, [funnelsTracks, ip, setFunnelTrack, origin, country]);

  return (
    <>
      <Attention className="bg-danger">
        <div className="container">
          <div className="row">
            <div className="col d-flex align-items-center justify-content-center">
              <FaExclamationCircle />
              <h2 className="h6-xxl text-white font-weight-400 mb-0 pl-3 text-center">
                <b className="ml-3">{language.congratulations.h2_b_1}</b>{' '}
                {language.congratulations.h2_1_1}{' '}
                <b className="font-weight-600">
                  {language.congratulations.h2_b_2}
                </b>{' '}
                {language.congratulations.h2_1_2}
              </h2>
            </div>
          </div>
        </div>
      </Attention>

      <Video className="container">
        <div className="row h-100 my-5 align-items-center">
          <div className="col-lg-6 col-xxl-5">
            <h1 className="h2-sm h1-xl mb-5 my-lg-5 mx-auto mx-md-0">
              <span className="font-weight-600 text-gradient-video">
                {language.congratulations.h1_span}
              </span>
              <br />
              {language.congratulations.h1}
            </h1>
          </div>
          <div className="col-lg-6 col-xxl-7 d-flex pr-lg-0 justify-content-end video-position">
            <video
              src="https://cdn.wiserr.io/promotional-stuff/bienvenidos.mp4"
              controls
              id="video"
              poster={thumb}
            >
              <track
                default
                kind="captions"
                srcLang="pt-BR"
                src="https://www.w3schools.com/html/mov_bbb.mp4"
              />
            </video>
          </div>
        </div>
      </Video>
      <Text className="container">
        <div className="row justify-content-center">
          <div className="col-11 col-sm-10 border-text">
            <div className="row justify-content-center">
              <div className="col-lg-8 col-xl-7  my-1 my-lg-4 py-3 text-center">
                <h2 className="mb-sm-0 font-weight-500">
                  {language.congratulations.h2_2}
                </h2>
                <p className="mb-0 font-weight-500">
                  {language.congratulations.p_1}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Text>
      <Access className="d-flex flex-column justify-content-center p-relative py-5 py-lg-4">
        <div className="container px-4 px-lg-3">
          <div className="row">
            <div className="col-lg-6">
              <div className="row justify-content-between">
                <div className="col-12 d-flex align-items-center justify-content-between">
                  <h2 className="h2-sm h1-xl mb-0 pr-3 pr-lg-0">
                    {language.congratulations.h2_3}{' '}
                    <span className="font-weight-600 text-gradient-access">
                      {language.congratulations.h2_3_span}
                    </span>
                  </h2>
                  <img
                    src={quotes}
                    alt="quotes"
                    className="p-lg-absolute top"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container px-4 px-lg-3">
          <div className="row">
            <div className="col-lg-6">
              <p className="mb-0 mb-lg-2 mb-xxl-5 color font-weight-400 mt-5">
                <b className="font-weight-600">
                  {language.congratulations.p_2_b}
                </b>
                <br />
                {language.congratulations.p_2_1}
                <br />
                {language.congratulations.p_2_2}
              </p>
            </div>
          </div>
        </div>
        <div className="text-right d-lg-none my-n2">
          <img src={tabletMob} alt="tablet" />
        </div>
        <div className="container px-4 px-lg-3">
          <div className="row">
            <div className="col-lg-6">
              <p className="mt-xxl-3 mb-0 mb-lg-2 mb-xxl-5 color font-weight-400">
                <b className="font-weight-600 mb-4">
                  {language.congratulations.p_3_b}
                </b>
                <br />
                <br />
                {language.congratulations.p_3_1}
              </p>
            </div>
          </div>
        </div>

        <div className="d-flex d-lg-none justify-content-end my-n4">
          <img src={tabletRight} alt="tablet" />
        </div>
        <div className="d-flex d-lg-none mt-n5">
          <img src={tabletLeft} alt="tablet" />
        </div>
      </Access>

      <Steps className="d-flex flex-column justify-content-center p-relative mt-5 pt-lg-5">
        <div className="container px-4 px-lg-3">
          <div className="row">
            <div className="col-md-8 col-lg-6">
              <div className="row justify-content-between">
                <div className="col-11">
                  <h2 className="h2-sm h1-xl mb-5">
                    {language.congratulations.h2_4} <br className="d-sm-none" />
                    <span className="text-gradient-steps">
                      {language.congratulations.h2_4_span}
                    </span>
                  </h2>
                </div>

                <img src={quotes} alt="quotes" className="p-absolute top" />
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-11 col-sm-12 mx-xl-3 border-step">
              <div className="row">
                <div className="col-md-6 py-5 steps">
                  <div className="row justify-content-center">
                    <div className="col-sm-8 test">
                      <h3 className="h1">{language.congratulations.h3_1}</h3>
                      <hr className="w-lg-75 bg-black" />
                      <p className="mt-4 mt-sm-5">
                        {language.congratulations.p_4}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 py-5 steps">
                  <div className="row justify-content-center">
                    <div className="col-sm-8">
                      <h3 className="h1">{language.congratulations.h3_2}</h3>
                      <hr className="w-lg-75 bg-black" />
                      <p className="mt-4 mt-sm-5">
                        {language.congratulations.p_5}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 py-5 steps">
                  <div className="row justify-content-center">
                    <div className="col-sm-8">
                      <h3 className="h1">{language.congratulations.h3_3}</h3>
                      <hr className="w-lg-75 bg-black" />
                      <p className="mt-4 mt-sm-5">
                        {language.congratulations.p_6}{' '}
                        <a href="https://app.wiserr.io">
                          https://app.wiserr.io
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 py-5 steps">
                  <div className="row justify-content-center">
                    <div className="col-sm-8 col-md-9">
                      <h3 className="h1">{language.congratulations.h3_4}</h3>
                      <hr className="w-lg-75 w-lg-60 bg-black" />
                      <p className="mt-4 mt-sm-5">
                        {language.congratulations.p_7_1}{' '}
                        <a href="mailto:wiserr@companyeducation.com">
                          wiserr@companyeducation.com
                        </a>{' '}
                        {language.congratulations.p_7_2}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-11 mb-5 mb-lg-0">
              <p className="p-signature">{language.congratulations.p_8}</p>
            </div>
            <div className="col-sm-6 text-center mg d-none d-lg-block">
              <img src={signature} alt="Signature" />
            </div>
            <div className="col-sm-6 text-center mg d-none d-lg-block">
              <img src={company} alt="Logo Company" />
            </div>
          </div>
        </div>
      </Steps>
      <div className="d-none d-lg-block">
        <Footer />
      </div>
      <div className="d-lg-none">
        <FooterBlack />
      </div>
      <Chat href={`${process.env.PUBLIC_URL}/sales-funnel/congratulations`}>
        <img src={chat} alt="chat logo" />
      </Chat>
    </>
  );
};

export default Congratulations;
