import styled from 'styled-components';
import { lighten } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Button = styled.button`
  background-color: #000;
  color: #fff;
  border-radius: 15px;
  padding: 15px 0px;
  width: 270px;
  font-size: 20px;

  :hover {
    background-color: ${lighten(0.1, '#000000')};
    color: ${lighten(0.1, '#fff')};
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
  }

  @media screen and (max-width: 1399px) {
    width: 200px !important;
    font-size: 16px !important;
  }

  @media screen and (max-width: 574px) {
    width: 100% !important;
  }
`;

export const Modal = styled(ModalComponent)`
  h2 {
    font-size: 48px;
    color: #242526;
  }

  h4 {
    font-size: 16px;
    color: #000;
  }
  u {
    font-weight: 800;
    font-size: 14px;
  }

  .btn-blue {
    font-size: 14px;
    color: #fff;
    background-color: #2f80ed;
    border-radius: 26px;
    padding: 15.5px 22px;
    :hover {
      background-color: ${lighten(0.1, '#2f80ed')};
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
    }
  }

  .btn-continue {
    font-size: 14px;
    color: #292929;
    :hover {
      background-color: ${lighten(0.1, '#292929')};
      text-decoration: underline;
    }
  }

  .bg-gray {
    background: #f4f4f4;
    border-radius: 26px;
  }
  .padding {
    padding: 27px 20px;
  }

  .pad-button {
    padding: 15.5px 15px;
    font-size: 13px;
    border-radius: 26px;
    :hover {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
    }
  }
  .bd-lg-black {
    background: #ffffff;
    border: 1.5px solid #242526;
    border-radius: 26px;

    p {
      color: #000;
      font-size: 15px;
    }
  }
  .text-black {
    color: #000;
  }
  .bg-plan {
    background: #242526;
    color: #fff;
  }
  .bg-blue {
    background-color: #2f80ed;
    box-shadow: 0px 22px 24px rgba(78, 78, 78, 0.25);
    border-radius: 20px;

    p {
      font-size: 15px;
    }
  }
  .h2-description {
    color: #6e6e6e;
    font-size: 24px;
  }
  .modal-content {
    background: #ffffff;
    box-shadow: 19px 49px 112px rgba(192, 192, 192, 0.4),
      inset 1px 1px 2px rgba(255, 255, 255, 0.3),
      inset -1px -1px 2px rgba(138, 138, 138, 0.5);
    border-radius: 30px;
    border: 0 !important;
  }

  .close-button {
    background-color: transparent;
  }

  @media screen and (min-width: 992px) {
    .text-gradient-lg-table {
      background: linear-gradient(
        270.04deg,
        #fb5a43 0.03%,
        #c341ae 30.44%,
        #9445c2 57.35%,
        #079cfe 95.73%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  @media screen and (max-width: 1399px) {
    h2 {
      font-size: 40px;
    }

    .h2-description {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 1199px) {
    h2 {
      font-size: 34px;
    }

    .h2-description {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 991px) {
    .text-mob-gray {
      color: #818181 !important;
    }
    .padding {
      padding: 15px 32px;
    }
    .bg-gray {
      background-color: transparent;
    }
    .pad-button {
      border-radius: 6px;
    }

    .bd-lg-black,
    .bd-mobile {
      border: 1.5px solid #cbccce;
      box-shadow: 0px 22px 24px rgba(213, 207, 177, 0.331184);
    }

    .bd-mobile {
      border-radius: 26px;
    }

    .bg-plan {
      background: linear-gradient(
        270.03deg,
        #fb5a43 0.02%,
        #c341ae 92.68%,
        #c341ae 99.97%
      ) !important;
    }

    .btn-blue {
      border-radius: 12px;
      width: 100%;
      padding: 19.5px 22px;
    }

    .btn-continue {
      background-color: rgba(96, 96, 96, 0.1) !important;
      color: rgba(96, 96, 96, 0.36);
      border-radius: 12px;
      width: 100%;
      padding: 19.5px 22px;
    }
  }

  @media screen and (max-width: 574px) {
    .h2-description {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 400px) {
    h2 {
      font-size: 28px;
    }
  }
`;

export const ModalStep = styled(ModalComponent)`
  h2 {
    font-size: 18px;
    color: #606060;
  }
  label {
    color: #8c8c8c;
    font-size: 15px;
  }
  .height {
    height: 58px;
    width: 58px;
  }
  .step {
    div {
      background-color: #d6d6d6;
      border: solid 2px #d6d6d6;

      span {
        font-size: 24px;
        color: #fff;
      }
    }
    p {
      color: #8c8c8c;
      font-size: 18px;
    }
  }

  hr {
    background-color: #b7b7b7;
  }

  .terms {
    h4,
    p {
      font-size: 10px;
      color: #ababab;
    }
  }

  .selected-step {
    div {
      border: 1px solid #37c694;
      background-color: #fff;
      span {
        font-size: 24px;
        color: #37c694;
      }
    }
    p {
      color: #606060;
      font-weight: 600;
      font-size: 18px;
    }
  }

  .active-step {
    div {
      background-color: #37c694;
      border: solid 2px #37c694;

      span {
        font-size: 24px;
        color: #fff;
      }
    }
    p {
      color: #8c8c8c;
      font-size: 18px;
    }
  }

  .active-hr {
    background-color: #37c694;
  }

  .bg-input {
    background-color: #f7f7f7;
    border: 0;
  }

  .btn-black {
    background-color: #18191a;
    border-radius: 15px;
    font-size: 24px;
    padding: 16.5px 20px;

    :hover {
      background-color: ${lighten(0.1, '#18191a')};
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
    }
  }

  .modal-content {
    background: #ffffff;
    box-shadow: 19px 49px 112px rgba(192, 192, 192, 0.4),
      inset 1px 1px 2px rgba(255, 255, 255, 0.3),
      inset -1px -1px 2px rgba(138, 138, 138, 0.5);
    border-radius: 30px;
    border: 0 !important;
  }

  .close-button {
    background-color: transparent;
  }

  .items {
    h2 {
      color: #081f32;
      font-size: 22px;
    }
    h3 {
      font-size: 18px;
    }
    h4 {
      font-size: 14px;
      span {
        font-size: 14px;
      }
    }
    p {
      font-size: 12px;
      color: #6e798c;
    }

    .gradient-items {
      background: linear-gradient(
        270.03deg,
        #fb5a43 0.02%,
        #c341ae 92.68%,
        #c341ae 99.97%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    span {
      font-size: 18px;
      color: #8c8c8c;
    }

    .btn-cart {
      background-color: #ababab;
      border-radius: 10px;
      padding: 7.5px 20px;
      color: #fff;
      font-size: 14px;

      :hover {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
      }
    }

    .bd-box {
      background: #ffffff;
      box-shadow: 0px 3px 10px 2px rgba(0, 0, 0, 0.05);
      border-radius: 16px;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 23px;
    }

    .items .btn-cart {
      border-radius: 12px;
      padding: 19.5px 20px;
      background-color: #2f80ed;
    }
    .selected-step {
      div {
        border: 1px solid #2f80ed;
        background-color: #2f80ed;
        span {
          color: #fff;
        }
      }
      p {
        color: #2f80ed;
      }
    }

    .bd-box {
      img {
        width: 100%;
        max-width: 280px;
      }
    }

    .step div {
      border: solid 1px #8c8c8c;
      background-color: transparent;
      span {
        color: #8c8c8c;
      }
    }

    .height {
      height: 40px;
      width: 40px;
    }

    .selected-step,
    .active-step,
    .step {
      div span {
        font-size: 12px;
      }
      p {
        font-size: 12px;
      }
    }

    .row-padding {
      padding: 0 3rem;
    }

    .items h3 {
      font-size: 23px;
    }

    .items span {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 574px) {
    .btn-black {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 400px) {
    .row-padding {
      padding: 0 1rem;
    }

    .bd-box {
      h2 {
        font-size: 16px;
      }
      h4 {
        font-size: 13px;
        span {
          font-size: 13px;
        }
      }
    }
  }
`;
