import React from 'react';

import { Link } from 'react-router-dom';
import { Footer } from './styles';

import { useLanguage } from '~/hooks/Language';

import logoFooter from '~/assets/logo/logo.svg';

const FooterViewOffer: React.FC = () => {
  const { language } = useLanguage();

  return (
    <Footer>
      <div className="container py-5">
        <div className="row">
          <div className="col-12 text-center mb-5">
            <img src={logoFooter} alt="Logo Wiserr Footer" />
          </div>

          <div className="col-12 mb-5">
            <p className="mb-0">{language.sales_funnel.div}</p>
          </div>
          <div className="col-12">
            <p className="h6 mb-0 text-center">
              <span className="d-block mb-2">{language.footer.p}</span>

              <span className="">
                <Link
                  to={`${process.env.PUBLIC_URL}/privacy-policy`}
                  className="h6 mb-0"
                >
                  {language.footer.a_1}
                </Link>{' '}
                |{' '}
                <Link
                  to={`${process.env.PUBLIC_URL}/terms-of-use`}
                  className="h6 mb-0"
                >
                  {language.footer.a_2}
                </Link>{' '}
                |{' '}
                <Link
                  to={`${process.env.PUBLIC_URL}/dmca-notice`}
                  className="h6 mb-0"
                >
                  {language.footer.a_3}
                </Link>{' '}
                |{' '}
                <Link
                  to={`${process.env.PUBLIC_URL}/refund-policy`}
                  className="h6 mb-0"
                >
                  {language.footer.a_4}
                </Link>{' '}
                |{' '}
                <Link
                  to={`${process.env.PUBLIC_URL}/anti-spam-policy`}
                  className="h6 mb-0"
                >
                  {language.footer.a_5}
                </Link>{' '}
                |{' '}
                <Link
                  to={`${process.env.PUBLIC_URL}/sms-privacy-policy`}
                  className="h6 mb-0"
                >
                  {language.footer.a_6}
                </Link>{' '}
                |{' '}
                <Link
                  to={`${process.env.PUBLIC_URL}/copyright-notice`}
                  className="h6 mb-0"
                >
                  {language.footer.a_7}
                </Link>
              </span>
            </p>
          </div>
        </div>
      </div>
    </Footer>
  );
};

export default FooterViewOffer;
