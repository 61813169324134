import React, { useCallback, useState } from 'react';

import { Link, useLocation } from 'react-router-dom';

import CreditCardPay from '~/components/CreditCardPay';

import { Button, Modal } from './styles';
import closeIcon from '~/assets/icons/close.svg';

import thumb from '~/assets/defaults/thumb-funnel.png';
import { useLanguage } from '~/hooks/Language';
import { useFunnelTrack } from '~/hooks/FunnelTrack';
import api from '~/services/api';

interface IModalNoThanksProps {
  product_id: string;
  price: number;
  video_url?: string;
  onSucceededPaid?(data?: any): void;
  installments?: number;
  page: string;
  action: string;
}

const ModalNoThanks: React.FC<IModalNoThanksProps> = ({
  product_id,
  price,
  video_url,
  onSucceededPaid,
  installments,
  page,
  action,
}) => {
  const {
    funnelsTracks,
    setFunnelTrack,
    ip,
    origin,
    country,
  } = useFunnelTrack();
  const { language } = useLanguage();
  const location = useLocation();
  const [showNoThanks, setShowNoThanks] = useState(false);

  const [url, setUrl] = useState(location.pathname);

  const handleShowNoThanks = useCallback(() => {
    if (origin) {
      const funnelTrackData = funnelsTracks.find(
        (funnelTrack) =>
          funnelTrack.ip === ip &&
          funnelTrack.page === page &&
          funnelTrack.action === action &&
          funnelTrack.origin === origin
      );
      if (!funnelTrackData) {
        api
          .post('funnels-tracks', {
            page,
            action,
            origin,
            country,
          })
          .then(() => {
            setFunnelTrack({
              ip,
              page,
              action,
              origin,
              country,
            });
          });
      }
    }

    setShowNoThanks(true);
  }, [action, funnelsTracks, ip, page, setFunnelTrack, origin, country]);

  const handleCloseNoThanks = useCallback(() => {
    if (origin) {
      const funnelTrackData = funnelsTracks.find(
        (funnelTrack) =>
          funnelTrack.ip === ip &&
          funnelTrack.page === page &&
          funnelTrack.action === `close-${action}` &&
          funnelTrack.origin === origin
      );
      if (!funnelTrackData) {
        api
          .post('funnels-tracks', {
            page,
            action: `close-${action}`,
            origin,
            country,
          })
          .then(() => {
            setFunnelTrack({
              ip,
              page,
              action: `close-${action}`,
              origin,
              country,
            });
          });
      }
    }

    setShowNoThanks(false);
  }, [action, country, funnelsTracks, ip, origin, page, setFunnelTrack]);

  useState(() => {
    switch (url) {
      case '/sales-funnel/view-offer-1':
        setUrl(`/sales-funnel/view-offer-2`);
        break;

      case '/sales-funnel/view-offer-2':
        setUrl(`/sales-funnel/view-offer-3`);
        break;

      case '/sales-funnel/view-offer-3':
        setUrl(`/sales-funnel/congratulations`);
        break;

      default:
        break;
    }
  });

  const handleVideoPlay = useCallback(() => {
    if (origin) {
      const funnelTrackData = funnelsTracks.find(
        (funnelTrack) =>
          funnelTrack.ip === ip &&
          funnelTrack.page === page &&
          funnelTrack.action === `${action}-play-video` &&
          funnelTrack.origin === origin
      );
      if (!funnelTrackData) {
        api
          .post('funnels-tracks', {
            page,
            action: `${action}-play-video`,
            origin,
            country,
          })
          .then(() => {
            setFunnelTrack({
              ip,
              page,
              action: `${action}-play-video`,
              origin,
              country,
            });
          });
      }
    }
  }, [action, country, funnelsTracks, ip, origin, page, setFunnelTrack]);

  const handleVideoEnded = useCallback(() => {
    if (origin) {
      const funnelTrackData = funnelsTracks.find(
        (funnelTrack) =>
          funnelTrack.ip === ip &&
          funnelTrack.page === page &&
          funnelTrack.action === `${action}-ended-video`
      );
      if (!funnelTrackData) {
        api
          .post('funnels-tracks', {
            page,
            action: `${action}-ended-video`,
            origin,
            country,
          })
          .then(() => {
            setFunnelTrack({
              ip,
              page,
              action: `${action}-ended-video`,
              origin,
              country,
            });
          });
      }
    }
  }, [action, country, funnelsTracks, ip, origin, page, setFunnelTrack]);

  const handleClickRefused = useCallback(() => {
    if (origin) {
      const funnelTrackData = funnelsTracks.find(
        (funnelTrack) =>
          funnelTrack.ip === ip &&
          funnelTrack.page === page &&
          funnelTrack.action === `${action}-refused` &&
          funnelTrack.origin === origin
      );
      if (!funnelTrackData) {
        api
          .post('funnels-tracks', {
            page,
            action: `${action}-refused`,
            origin,
            country,
          })
          .then(() => {
            setFunnelTrack({
              ip,
              page,
              action: `${action}-refused`,
              origin,
              country,
            });
          });
      }
    }
  }, [action, country, funnelsTracks, ip, origin, page, setFunnelTrack]);

  return (
    <>
      <Button
        type="button"
        className="btn-no-tanks border-0 bg-transparent font-weight-bold"
        onClick={handleShowNoThanks}
      >
        {language.modal_no_thanks.button_1}
      </Button>

      <Modal
        className="modal-sales-funnel px-0"
        centered
        size="xl"
        show={showNoThanks}
        onHide={handleCloseNoThanks}
      >
        <Modal.Header className="d-block border-0 pb-0 px-4 pt-4 pt-lg-5 px-lg-5">
          <div className="w-100 text-right">
            <button
              type="button"
              className="close-button border-0"
              onClick={handleCloseNoThanks}
            >
              <img src={closeIcon} alt="Close" />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="container py-0 px-4 px-lg-5">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8">
              <h2 className="font-weight-600 my-5 my-lg-0 pb-4 text-center">
                {language.modal_no_thanks.h2_1}
              </h2>
              {video_url && (
                <video
                  src={video_url}
                  controls
                  id="video"
                  poster={thumb}
                  onPlay={handleVideoPlay}
                  onEnded={handleVideoEnded}
                >
                  <track
                    default
                    kind="captions"
                    srcLang="pt-BR"
                    src="https://www.w3schools.com/html/mov_bbb.mp4"
                  />
                </video>
              )}

              <div className="row my-5">
                <div className="col-12">
                  <p className="mb-4">{language.modal_no_thanks.p_1}</p>
                  <p className="mb-4">
                    {language.modal_no_thanks.p_2_1}{' '}
                    <span className="font-weight-600">
                      {language.modal_no_thanks.p_2_span}
                    </span>{' '}
                    {language.modal_no_thanks.p_2_2}
                  </p>
                  <p className="mb-0">{language.modal_no_thanks.p_3}</p>
                  <p className="mb-0">{language.modal_no_thanks.p_4}</p>
                  <p className="mb-4 font-weight-bold">
                    {language.modal_no_thanks.p_5}
                  </p>
                  <p>{language.modal_no_thanks.p_6}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-10 col-xl-8 mb-5">
              <CreditCardPay
                product_id={product_id}
                text_button={language.modal_no_thanks.text_button_1}
                price={price}
                onSucceededPaid={onSucceededPaid}
                installments={installments}
                page={page}
                action={`${action}-payment`}
              />
              <Link
                to={`${process.env.PUBLIC_URL}${url}`}
                className="btn btn-transparent mt-4"
                onClick={handleClickRefused}
              >
                {language.modal_no_thanks.a_1}
              </Link>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalNoThanks;
