import styled, { css } from 'styled-components';
import { lighten } from 'polished';

interface IMenuProps {
  active: boolean;
}

export const Container = styled.header`
  top: 0;
  background: transparent;
  z-index: 100;
  width: 100%;

  @media screen and (max-width: 991px) {
    position: fixed;
    height: 64px;
    background: rgba(255, 255, 255, 1);
  }
  @media screen and (min-width: 992px) {
    height: 100px;
  }
`;
export const Menu = styled.div<IMenuProps>`
  .btn-idiom {
    color: #000 !important;
    font-size: 16px !important;
    img {
      width: 22px;
      height: 22px;
      transition-duration: 0.3s;
      filter: grayscale(1);
      border-radius: 50%;
      opacity: 70%;
    }

    :hover img,
    .active-idiom {
      filter: grayscale(0);
    }
    :hover,
    :focus {
      color: #606060 !important;
    }
  }
  .min-height {
    min-height: 50px;
  }
  > button {
    width: 30px;
    height: 30px;

    span {
      display: block;
      width: 100%;
      height: 4px;
      background-color: #131313;
      border-radius: 30px;
      transition-duration: 0.5s;

      :nth-child(2) {
        width: 100%;
        margin: 6px 0 6px auto;
      }

      ${(props) =>
        props.active &&
        css`
          :nth-child(1) {
            transform: rotate(225deg);
          }

          :nth-child(2) {
            opacity: 0;
            transform: rotate(225deg);
          }

          :nth-child(3) {
            transform: rotate(315deg);
            margin-top: -20px;
          }
        `}
    }
  }

  /* .menu-group {
    height: calc(100vh - 100px);
  } */

  .menu,
  .logoff {
    a,
    button {
      background: transparent;
      color: #6e6e6e;
      position: relative;

      div {
        width: 22px;
        height: 22px;
        margin: 0 auto;
        transition-duration: 0.3s;
      }

      small {
        position: absolute;
        transition-duration: 0.3s;
        z-index: -1;
        left: 0;
        background: rgba(255, 255, 255, 0.95);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 5px 5px 0;
      }
    }
    .become-affiliate-link {
      line-height: 1;
    }

    a:hover,
    button:hover,
    a.active {
      text-decoration: none;
    }

    /* a:hover,
    button:hover {
      small {
        padding: 0 20px;
        left: 100%;
        width: 160%;
        min-height: 50px;
      }
    } */
  }

  @media screen and (max-width: 991px) {
    .overflow {
      ::-webkit-scrollbar {
        background-color: #fff !important;
        width: 5px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #fff !important;
      }
    }
    .btn-menu {
      a {
        background-color: #000;
        color: #fff;
        border-radius: 15px;
        padding: 17.5px 0px;
        width: 212px;

        :hover {
          background-color: ${lighten(0.1, '#000000')};
          color: ${lighten(0.1, '#fff')};
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
        }
      }
    }
    .menu-item {
      font-size: 32px;
    }
    .menu-group {
      width: 100%;
      height: 100vh;
      background: rgba(255, 255, 255, 1);
      transition-duration: 0.4s;
      top: 47px;
      left: ${(props) => (props.active ? '0' : '1000%')};
    }

    .menu {
      padding-top: 4vh;
    }

    .menu,
    .logoff {
      a,
      button {
        div {
          margin: 0 10px;
        }

        small {
          position: relative;
          z-index: 0;
          text-align: left;
          opacity: 1;
          width: 60px;
          justify-content: start;
          left: 0 !important;
          width: 100px !important;
          padding: 0 !important;
        }
      }
    }
  }

  @media screen and (min-height: 800px) {
    .menu {
      padding-top: 10vh;
    }
  }

  @media screen and (max-width: 500px) {
    .btn-menu {
      a {
        width: 100%;
      }
    }
  }
`;
