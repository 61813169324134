import React from 'react';

import { useLanguage } from '~/hooks/Language';

import { Container } from './styles';
import Contact from '~/components/Contact';

const AntiSpamPolicy: React.FC = () => {
  const { language } = useLanguage();

  return (
    <>
      <Container>
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <h1>
                Wiserr.io
                <br />
                {language.antiSpan.h1}
              </h1>
              <hr className="mt-3 mb-5" />
              <div className="mb-5">
                <h2 className="h3">{language.antiSpan.h2}</h2>
                <p>{language.antiSpan.p_1}</p>
                <p>{language.antiSpan.p_2}</p>
                <p>{language.antiSpan.p_3}</p>
                <p>{language.antiSpan.p_4}</p>
                <p>{language.antiSpan.p_5}</p>
                <p>
                  {language.antiSpan.p_6_1}{' '}
                  <a href="mailto:support@wiserr.io">support@wiserr.io</a>
                  {language.antiSpan.p_6_2}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Contact />
    </>
  );
};

export default AntiSpamPolicy;
