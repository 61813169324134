import styled from 'styled-components';

export const Container = styled.div`
  input {
    width: 100%;
  }
  .ReactCreditCard__container {
    margin: 0 auto;
  }

  .form-control {
    height: unset !important;
  }

  @media screen and (max-width: 991px) {
  }

  @media screen and (max-width: 375px) {
    .ReactCreditCard__container {
      width: 100%;
      max-width: 315px;
    }
  }

  @media screen and (max-width: 350px) {
    .ReactCreditCard__container {
      left: -25px;
    }
  }
`;
