import { lighten } from 'polished';
import styled from 'styled-components';

import banner from '~/assets/defaults/banner.png';
import bannerForAll from '~/assets/defaults/img-for-all.png';
import bannerMessage from '~/assets/banners/message.svg';
import peoples from '~/assets/defaults/bg-real-fast.png';
import bgYellow from '~/assets/defaults/banner-yellow.png';
import bgBluePink from '~/assets/defaults/banner-blue-pink.png';
import bgGreen from '~/assets/defaults/banner-green.png';

export const Container = styled.div`
  .text-bottom {
    font-size: 14px;
  }

  .w-95 {
    width: 95%;
  }

  @media screen and (max-width: 1199px) {
    .text-bottom {
      font-size: 12px;
    }
  }
`;
export const VideoSide = styled.div`
  h1 {
    color: #6e6e6e;
    font-size: 3rem;
  }

  video {
    border-radius: 21px;
    width: 100%;
    max-width: 1000px;
  }

  p {
    font-size: 20px;
  }

  button {
    background-color: #2f80ed;
    color: #fff;
    transition-duration: 0.3s;
    border-radius: 15px;
    padding: 17px 0px;
    width: 270px;
    font-size: 20px;
    margin-top: 3rem;

    :hover {
      background-color: ${lighten(0.1, '#2F80ED')};
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
      color: #fff;
    }
  }

  .text-gradient-video {
    background: #fb5a43;
    background: linear-gradient(
      270.03deg,
      #fb5a43 10.39%,
      #c341ae 80.71%,
      #9941c3 99.97%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media screen and (min-width: 992px) {
    p {
      font-size: 18px;
    }
    .video-position {
      position: absolute;
      right: 0;
    }
    /* background-image: url(${banner});
    background-position: 85%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 65vw;
    margin-top: -100px; */
  }

  @media screen and (min-width: 1200px) {
    height: calc(80vh - 100px);
  }
`;

export const Message = styled.div`
  background-image: url(${bannerMessage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  h2 {
    color: #242526;
    font-size: 3rem;
    span {
      font-size: 3rem;
    }
  }

  h3 {
    font-size: 20px;
  }

  p,
  b {
    font-size: 18px;
  }

  b {
    color: #242526;
  }

  .bg-white-message {
    background: #ffffff;
    border: 1px solid #f3f3f3;
    box-sizing: border-box;
    box-shadow: 3px 4px 23px rgba(95, 95, 95, 0.1),
      inset 1px 1px 2px rgba(255, 255, 255, 0.3),
      inset -1px -1px 2px rgba(230, 230, 230, 0.5);
    border-radius: 20px;
    padding: 67px 84px;
  }

  .size {
    font-size: 20px;
    font-weight: 500;
  }

  .text-gradient-message,
  .gradient-to {
    background: #fb5a43;
    background: linear-gradient(
      270.03deg,
      #fb5a43 0.02%,
      #c341ae 92.68%,
      #c341ae 99.97%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .gradient-from {
    background: #fb5a43;
    background: linear-gradient(
      270.03deg,
      #fb5a43 0.02%,
      #c341ae 2.68%,
      #c341ae 65.97%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media screen and (min-width: 992px) {
  }

  @media screen and (min-width: 2000px) {
  }

  @media screen and (max-width: 1399px) {
    h2 {
      font-size: 2.5rem;

      span {
        font-size: 2.5rem;
      }
    }
  }

  @media screen and (max-width: 767px) {
    h2 {
      font-size: 30px;

      span {
        font-size: 40px;
      }
    }

    h3 {
      font-size: 18px;
    }

    .size {
      font-size: 17px;
    }
    .bg-white-message {
      padding: 40px 30px;
    }
  }

  @media screen and (max-width: 500px) {
    a {
      width: 100% !important;
    }
  }
  @media screen and (max-width: 400px) {
    h2 {
      font-size: 1.7rem;
      span {
        font-size: 1.7rem;
      }
    }
    h3 {
      font-size: 16px;
    }
    p,
    b {
      font-size: 1rem;
    }
    .bg-white-message {
      padding: 40px 20px;
    }
    .width-75 {
      width: 75%;
    }
  }
`;

export const RealFast = styled.div`
  h2 {
    color: #18191a;
    font-size: 3rem;
  }

  b {
    color: #6e6e6e;
  }
  p {
    line-height: 150% !important;
    font-size: 18px;
  }
  .color {
    color: #ababab;
  }

  a {
    background-color: #000;
    color: #fff;
    border-radius: 15px;
    padding: 15px 60px;

    :hover {
      background-color: ${lighten(0.1, '#000000')};
      color: ${lighten(0.1, '#fff')};
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
    }
  }

  .top {
    top: -70px;
    right: 90px;
  }

  .bd-left {
    border: 3px solid #606060;
    border-radius: 3px;
    background-color: #606060;
  }

  .text-gradient-about-us {
    background: #fb5a43;
    background: linear-gradient(to left, #fb5a43 0%, #c341ae 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media screen and (min-width: 992px) {
    background-image: url(${peoples});
    background-position: 94%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 45vw;
  }

  @media screen and (min-width: 2000px) {
    height: 80vh;
  }

  @media screen and (max-width: 1399px) {
    h2 {
      font-size: 2.5rem;
      span {
        font-size: 2.5rem;
      }
    }
  }

  @media screen and (max-width: 1199px) {
    .top {
      top: -50px;
      right: 260px;
    }
  }

  @media screen and (max-width: 991px) {
    .bg-yellow {
      background-image: url(${bgYellow});
      background-position: right;
      background-repeat: no-repeat;
    }

    .bg-blue-pink {
      background-image: url(${bgBluePink});
      background-position: left;
      background-repeat: no-repeat;
      padding: 16% 0;
    }

    .bg-green {
      background-image: url(${bgGreen});
      background-position: right;
      background-repeat: no-repeat;
    }

    .top {
      top: -10px;
      right: 20px;
    }
  }

  @media screen and (max-width: 767px) {
    h2 {
      font-size: 28px;
      span {
        font-size: 28px;
      }
    }

    p {
      font-size: 18px;
    }

    img {
      width: 36px;
      height: 36px;
    }
  }

  @media screen and (max-width: 400px) {
    h2 {
      font-size: 1.7rem;
      span {
        font-size: 1.7rem;
      }
    }
  }
`;

export const Member = styled.div`
  h2 {
    color: #242526;
    font-size: 3rem;

    span {
      background: #fb5a43;
      background: linear-gradient(
        270.03deg,
        #fb5a43 0.02%,
        #c341ae 92.68%,
        #c341ae 99.97%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  h3 {
    font-size: 24px;
  }

  p {
    font-size: 1rem;
  }

  .border-box {
    border-top: 6px solid #606060;
  }

  .bg-arrow {
    opacity: 0;
    height: 43px;
    width: 43px;
    border-radius: 50%;
    background: linear-gradient(
      270.03deg,
      #fb5a43 0.02%,
      #c341ae 92.68%,
      #c341ae 99.97%
    );
  }

  .hover:hover {
    background-color: #3d3d3d;
    border-radius: 0 0 15px 15px;
    border-top: 6px solid #3d3d3d;
    cursor: pointer;
    h3,
    p {
      color: #e4e4e4;
    }

    p {
      font-weight: 400 !important;
    }
    .bg-arrow {
      opacity: 1;
    }
  }

  @media screen and (max-width: 1399px) {
    h2 {
      font-size: 2.5rem;
      span {
        font-size: 2.5rem;
      }
    }
  }

  @media screen and (max-width: 767px) {
    h2 {
      font-size: 30px;
      span {
        font-size: 30px;
      }
    }
  }

  @media screen and (max-width: 400px) {
    h2 {
      font-size: 1.7rem;
      span {
        font-size: 1.7rem;
      }
    }
  }
`;

export const Bonus = styled.div`
  button {
    background-color: #000;
    color: #fff;
    border-radius: 15px;
    padding: 17px 0px;
    width: 270px;
    font-size: 20px;

    :hover {
      background-color: ${lighten(0.1, '#000000')};
      color: ${lighten(0.1, '#fff')};
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
    }
  }
  p {
    span {
      background: #079cfe;
      background: linear-gradient(
        to left,
        #c341ae 0%,
        #9445c2 40%,
        #079cfe 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  h2 {
    color: #242526;
    font-size: 3rem;

    span {
      background: #fb5a43;
      background: linear-gradient(
        270.03deg,
        #fb5a43 -29.02%,
        #c341ae 92.68%,
        #c341ae 99.97%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  h3 {
    color: #242526;
    border-bottom: solid 1px #606060;
    font-size: 3rem;
  }

  .border-card {
    border: solid 1px #f3f3f3;
    border-radius: 10px;
  }

  .text-black {
    color: #202020;
  }
  .hover:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    cursor: pointer;
  }

  .hover p {
    font-size: 24px;
  }

  @media screen and (max-width: 1399px) {
    h2,
    h3 {
      font-size: 2.5rem;
      span {
        font-size: 2.5rem;
      }
    }

    .hover p {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 991px) {
    button {
      background-color: #2f80ed;
      :hover {
        background-color: ${lighten(0.1, '#2f80ed')};
      }
    }
    h2,
    h3 {
      font-size: 35px;
      span {
        font-size: 35px;
      }
    }

    .hover p {
      font-size: 18px;
    }

    .hover:hover {
      background-color: #2f80ed;
      box-shadow: unset !important;
      h3,
      p {
        color: #fff;
      }
      h3 {
        border-bottom: solid 1px #fff;
      }
    }

    .hover :nth-child(1) {
      right: -22px;
      top: 0px;
    }

    .number-gradient {
      font-size: 60px;

      background: linear-gradient(
        270.03deg,
        #fb5a43 0.02%,
        #c341ae 92.68%,
        #c341ae 99.97%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  @media screen and (max-width: 767px) {
    h2 {
      font-size: 30px;
      span {
        font-size: 30px;
      }
    }
  }

  @media screen and (max-width: 500px) {
    a {
      width: 100% !important;
    }
  }

  @media screen and (max-width: 400px) {
    h2,
    h3 {
      font-size: 1.7rem;
      span {
        font-size: 1.7rem;
      }
    }

    .hover p {
      font-size: 18px;
    }
  }
`;

export const ForAll = styled.div`
  h2 {
    font-size: 3rem;
  }

  p {
    font-size: 24px;
    color: #6e6e6e;
  }

  a {
    background-color: #000;
    color: #fff;
    border-radius: 15px;
    padding: 14.5px 0px;
    width: 212px;

    :hover {
      background-color: ${lighten(0.1, '#000000')};
      color: ${lighten(0.1, '#fff')};
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
    }
  }

  .top {
    top: -40px;
    right: 0px;
  }

  .bd-left {
    border: 3px solid #606060;
    border-radius: 3px;
    background-color: #606060;
  }

  .text-gradient-for-all {
    background: #fb5a43;
    background: linear-gradient(
      270.03deg,
      #fb5a43 36.02%,
      #c341ae 92.68%,
      #c341ae 99.97%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media screen and (min-width: 992px) {
    background-image: url(${bannerForAll});
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
    height: 52vw;
    margin: 8rem 0;
  }

  @media screen and (min-width: 2000px) {
    height: 80vh;
  }

  @media screen and (max-width: 1399px) {
    h2 {
      font-size: 2.5rem;
    }

    p {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 991px) {
    img {
      transform: rotate3d(0, 1, 0, 180deg);
    }
  }

  @media screen and (max-width: 767px) {
    h2 {
      font-size: 35px;
    }

    img {
      width: 36px;
      height: 36px;
    }
  }

  @media screen and (max-width: 500px) {
    a {
      width: 100% !important;
    }
    p {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 400px) {
    h2 {
      font-size: 1.7rem;
    }

    .width-75 {
      width: 75%;
    }
  }
`;

export const Next = styled.div`
  button {
    background-color: #000;
    color: #fff;
    border-radius: 15px;
    padding: 17px 0px;
    width: 270px;
    font-size: 20px;

    :hover {
      background-color: ${lighten(0.1, '#000000')};
      color: ${lighten(0.1, '#fff')};
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
    }
  }

  h2 {
    color: #242526;
    font-size: 3rem;

    span {
      background: #fb5a43;
      background: linear-gradient(
        270.03deg,
        #fb5a43 20.02%,
        #c341ae 99.68%,
        #c341ae 99.97%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  h3 {
    color: #242526;
    border-bottom: solid 1px #606060;
    font-size: 3rem;
  }

  .top {
    top: -70px;
    right: 20px;
  }

  .pad-right {
    padding-right: 83px;
  }

  .border-card {
    border: solid 1px #f3f3f3;
    border-radius: 10px;
  }

  .text-black {
    color: #202020;
  }
  .hover:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    cursor: pointer;
  }

  p {
    font-size: 24px;
    color: #6e6e6e;
  }

  @media screen and (max-width: 1399px) {
    h2,
    h3 {
      font-size: 2.5rem;
      span {
        font-size: 2.5rem !important;
      }
    }

    p {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 991px) {
    .pad-right {
      padding-right: 15px;
    }

    .hover:hover {
      box-shadow: unset !important;
      background-color: #2f80ed;
      h3 {
        border-bottom: solid 1px #fff;
      }
      h3,
      p {
        color: #fff;
      }
    }
  }

  @media screen and (max-width: 767px) {
    h2,
    h3 {
      font-size: 35px;
      span {
        font-size: 35px !important;
      }
    }

    p {
      font-size: 18px;
    }

    img {
      width: 36px;
      height: 36px;
    }
  }

  @media screen and (max-width: 500px) {
    a {
      width: 100% !important;
    }
  }

  @media screen and (max-width: 400px) {
    h2,
    h3 {
      font-size: 1.7rem;
      span {
        font-size: 1.7rem !important;
      }
    }
  }
`;

export const ButtonToUp = styled.button`
  color: #000;
  background-color: #f7f7f7;
  position: fixed;
  z-index: 1000;
  bottom: 50px;
  right: 16%;
  height: 55px;
  width: 55px;

  @media screen and (max-width: 574px) {
    height: 40px;
    width: 40px;
  }
`;
