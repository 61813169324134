import { darken } from 'polished';
import styled from 'styled-components';
import bg from '~/assets/banners/bg-girl.png';
import bgHd from '~/assets/banners/girl-book-hd.png';

export const Container = styled.div`
  h1 {
    color: #202020;
  }
  p,
  label {
    color: #606060;
  }

  .focus {
    border: 1.5px solid #cbccce !important;
    height: 50px;
    background: #fdfdfd !important;
    border-radius: 15px !important;
  }

  button {
    background-color: #18191a;
    color: #fff;
    border: 0;
    height: 50px;
    border-radius: 15px;
    :hover {
      color: ${darken(0.1, '#fff')};
      background-color: ${darken(0.1, '#18191a')};
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5) !important;
    }
  }

  .size {
    font-size: 14px;
  }

  .size-check {
    font-size: 12px;
  }

  .bg-girl {
    background-image: url(${bg});
    background-position: center;
    background-size: cover;
  }

  label {
    div {
      width: 35px;
      border: 0;
      padding: 0;
    }
  }

  @media (max-width: 1199px) {
    .bg-girl {
      background-image: url(${bgHd});
    }

    .w-lg-35 {
      width: 35% !important;
    }

    .w-lg-65 {
      width: 65% !important;
    }
  }

  @media (max-width: 991px) {
    label {
      font-size: 12px;
    }
    .w-100 {
      width: 100% !important;
    }
  }

  @media (max-width: 767px) {
    label {
      font-size: 12px !important;
    }
  }

  @media (max-width: 575px) {
    .focus {
      height: 46px;
    }
  }
`;
