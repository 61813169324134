import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '~/hooks/Language';
import { Container } from './styles';
import logoFooter from '~/assets/logo/logo-sales-funnel.svg';
import twitter from '~/assets/icons/twitter-sales-funnel.svg';
import facebook from '~/assets/icons/facebook-sales-funnel.svg';
import telegram from '~/assets/icons/telegram-sales-funnel.svg';
import instagram from '~/assets/icons/instagram-sales-funnel.svg';
import signatureMobile from '~/assets/logo/signature-mobi-footer.svg';

const FooterBlack: React.FC = () => {
  const { language } = useLanguage();
  return (
    <Container>
      <div className="container px-4 py-5">
        <div className="row">
          <div className="col-12 text-center mb-4 mb-lg-5 d-none d-lg-block">
            <img
              src={logoFooter}
              alt="Logo Wiserr Footer"
              className="logo-mobile"
            />
          </div>
          <div className="col-12 text-center mb-4 mb-lg-5">
            <p className="mb-0">{language.sales_funnel.p_27}</p>
            <p className="mb-0">{language.sales_funnel.p_28}</p>
          </div>
          <div className="col-12 text-center mb-4 mb-lg-5">
            <p className="mb-0">{language.sales_funnel.p_29}</p>
          </div>
          <div className="col-12 mb-5">
            <p className="h6 mb-0 text-center">
              <span className="d-block">{language.footer.p}</span>

              <span className="">
                <Link
                  to={`${process.env.PUBLIC_URL}/privacy-policy`}
                  className="h6 mb-0"
                >
                  {language.footer.a_1}
                </Link>{' '}
                |{' '}
                <Link
                  to={`${process.env.PUBLIC_URL}/terms-of-use`}
                  className="h6 mb-0"
                >
                  {language.footer.a_2}
                </Link>{' '}
                |{' '}
                <Link
                  to={`${process.env.PUBLIC_URL}/dmca-notice`}
                  className="h6 mb-0"
                >
                  {language.footer.a_3}
                </Link>{' '}
                |{' '}
                <Link
                  to={`${process.env.PUBLIC_URL}/refund-policy`}
                  className="h6 mb-0"
                >
                  {language.footer.a_4}
                </Link>{' '}
                |{' '}
                <Link
                  to={`${process.env.PUBLIC_URL}/anti-spam-policy`}
                  className="h6 mb-0"
                >
                  {language.footer.a_5}
                </Link>{' '}
                |{' '}
                <Link
                  to={`${process.env.PUBLIC_URL}/sms-privacy-policy`}
                  className="h6 mb-0"
                >
                  {language.footer.a_6}
                </Link>{' '}
                |{' '}
                <Link
                  to={`${process.env.PUBLIC_URL}/copyright-notice`}
                  className="h6 mb-0"
                >
                  {language.footer.a_7}
                </Link>
              </span>
            </p>
          </div>
          <div className="col-12 text-center">
            <a
              href="https://twitter.com/WiserrOfficial"
              target="blank"
              className="mr-4"
            >
              <img src={twitter} alt="Twitter" />
            </a>
            <a
              href="https://facebook.com/wiserrofficial/"
              target="blank"
              className="mr-4"
            >
              <img src={facebook} alt="Facebook" />
            </a>
            <a
              href="https://t.me/wiserrofficial"
              target="blank"
              className="mr-4"
            >
              <img src={telegram} alt="Telegram" />
            </a>
            <a
              href="https://instagram.com/millonario.digital/"
              target="blank"
              className=""
            >
              <img src={instagram} alt="Instagram" />
            </a>
          </div>
        </div>
      </div>
      <div className="col-12 d-lg-none text-center pb-5">
        <img src={signatureMobile} alt="Logo" />
      </div>
    </Container>
  );
};

export default FooterBlack;
