import React, { useState, useEffect, useCallback } from 'react';

import { useLanguage } from '~/hooks/Language';

import logoBtn from '~/assets/logo/logo-white.svg';
import { Container } from './styles';
import thumb from '~/assets/defaults/thumb.png';

interface ICredentials {
  email: string;
  password: string;
}

const Video: React.FC = () => {
  const { language } = useLanguage();

  const [credentials, setCredentials] = useState<ICredentials>(
    {} as ICredentials
  );

  useEffect(() => {
    const credentialsData = localStorage.getItem('@Membership:credentials');
    if (credentialsData) {
      setCredentials(JSON.parse(credentialsData));
    }
  }, []);

  const handleClick = useCallback(() => {
    localStorage.removeItem('@Membership:credentials');
    window.location.href = `${process.env.REACT_APP_STUDENTS_URL}/auto-login/${credentials.password}/${credentials.email}`;
  }, [credentials]);

  return (
    <Container>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h1 className="display-xxl-4 font-weight-900 mt-4">
              <span className="big normal-sm d-block d-sm-inline-block">
                {language.video.h1_span}
              </span>{' '}
              {language.video.h1}
            </h1>
            <h2 className="h6 my-3">
              <strong>{language.video.h2_strong_1}</strong>
              {language.video.h2}
              <strong>{language.video.h2_strong_2}</strong>
            </h2>
            <video
              src="https://cdn.wiserr.io/promotional-stuff/bienvenidos.mp4"
              controls
              id="video"
              poster={thumb}
            >
              <track
                default
                kind="captions"
                srcLang="pt-BR"
                src="https://www.w3schools.com/html/mov_bbb.mp4"
              />
              {language.video.video}
            </video>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 my-4 my-md-5 my-lg-4">
            <div className="rad-20 py-2 px-3 p-md-0">
              <div className="text-center">
                <h4 className="h5 h4-md pt-3 font-weight-bold">
                  {language.video.h4_1}&nbsp;{language.video.h4_2} :
                </h4>
                <p className="small mt-3 size">
                  {language.video.p_1}{' '}
                  <br className="d-none d-lg-block d-xl-none" />
                  {language.video.p_2}&nbsp;{language.video.p_3}
                </p>
              </div>

              <div className="col-md-6 px-2 px-sm-3 pb-3 d-inline-flex justify-content-center justify-content-lg-end">
                <p className="h6 h5-lg id-size mr-lg-3">
                  <span>{language.video.p_4_span_1}:</span>
                  <strong className="font-weight-bold">
                    {credentials.email}
                  </strong>
                </p>
              </div>
              <div className="col-md-6 px-2 px-sm-3 pb-3 d-inline-flex justify-content-center justify-content-lg-start">
                <p className="h6 h5-lg id-size ml-lg-3">
                  <span>{language.video.p_4_span_2}:</span>
                  <strong className="font-weight-bold">
                    {credentials.password}
                  </strong>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-12 text-center mb-4">
            <button
              type="button"
              onClick={handleClick}
              className="btn shadow font-weight-bold"
            >
              <img src={logoBtn} alt="Logo Wiserr" />
              {language.video.button}
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Video;
