import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { useLanguage } from '~/hooks/Language';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container } from './styles';
import Input from '~/components/Input';
import Textarea from '~/components/Textarea';

interface contactFormData {
  name: string;
  email: string;
  message: string;
}

interface IReset {
  reset(): void;
}

const Contact: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const { language } = useLanguage();

  const handleSubmit = useCallback(
    async (data: contactFormData, { reset }: IReset) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required(language.signUp.require_1),
          email: Yup.string().required(language.signUp.require_2),
          message: Yup.string().required(language.signUp.require_3),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          content: JSON.stringify(data),
        };

        const response = await api.post('leads', formData);

        if (response.data) {
          history.push(`${process.env.PUBLIC_URL}/request-sent`);
          Swal.fire(
            language.swal_message.good_job,
            language.swal_message.successfully,
            'success'
          ).then(() => {
            reset();
            setLoading(false);
            history.push(`${process.env.PUBLIC_URL}/`);
          });
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
          setLoading(false);
        } else {
          Swal.fire(
            'Opss...',
            language.swal_message.try_again,
            'error'
          ).then(() => setLoading(false));
        }
      }
    },
    [
      history,
      language.signUp.require_1,
      language.signUp.require_2,
      language.signUp.require_3,
      language.swal_message.good_job,
      language.swal_message.successfully,
      language.swal_message.try_again,
    ]
  );

  return (
    <>
      <Container id="contact" className="mt-5 mb-5 mb-lg-0">
        <div className="container px-4 px-lg-3 h-100">
          <div className="row h-100 align-items-center justify-content-center">
            <div className="col-12">
              <div className="col-lg-5 px-0 px-lg-3">
                <h2 className="h2 h1-xxl font-weight-600">
                  {language.contact_component.h2} <br className="d-sm-none" />
                  <span>{language.contact_component.h2_span}</span>
                </h2>
                <p className="h5 h4-xl my-4 my-xxl-5 font-weight-300 font-weight-lg-400">
                  {language.contact_component.p}
                </p>
              </div>
              <div className="col-lg-10 px-0 px-lg-3">
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="">
                        <label htmlFor="name">
                          {language.contact_component.label_1}
                        </label>
                        <Input name="name" id="name" />
                      </div>
                      <div className="my-4 my-xxl-5">
                        <label htmlFor="email">
                          {language.contact_component.label_2}
                        </label>
                        <Input type="email" name="email" id="email" />
                      </div>

                      <label htmlFor="message">
                        {language.contact_component.label_3}
                      </label>
                      <Textarea
                        name="message"
                        id="message"
                        placeholder={language.contact_component.placeholder}
                        className="textarea-mobile"
                      />

                      <button
                        type="submit"
                        className="h6 btn my-4 mt-xxl-5 py-3 w-100 font-weight-bold"
                      >
                        {language.contact_component.button}
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
      {loading && (
        <>
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Contact;
