import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Home from '~/pages/Home';
import PrivacyPolicy from '~/pages/PrivacyPolicy';
import TermsOfUse from '~/pages/TermsOfUse';
import DMCANotice from '~/pages/DMCANotice';
import RefundPolicy from '~/pages/RefundPolicy';
import AntiSpamPolicy from '~/pages/AntiSpamPolicy';
import SmsPrivacyPolicy from '~/pages/SmsPrivacyPolicy';
import CopyrightNotice from '~/pages/CopyrightNotice';
import SalesFunnel from '~/pages/SalesFunnel';
import Congratulations from '~/pages/SalesFunnel/Congratulations';
import ViewOffer2 from '~/pages/SalesFunnel/ViewOffer2';
import ViewOffer3 from '~/pages/SalesFunnel/ViewOffer3';

import Book from '~/pages/Book';
import SignUp from '~/pages/SignUp';
import Video from '~/pages/Video';

const routes: React.FC = () => {
  return (
    <Switch>
      <Route
        path={`${process.env.PUBLIC_URL}/`}
        exact
        component={Home}
        showMenu
      />
      <Route
        path={`${process.env.PUBLIC_URL}/i/:referrer`}
        exact
        component={Home}
        showMenu
      />
      <Route
        path={`${process.env.PUBLIC_URL}/sales-funnel`}
        exact
        component={SalesFunnel}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/sales-funnel/congratulations`}
        exact
        component={Congratulations}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/sales-funnel/view-offer-2`}
        exact
        component={ViewOffer2}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/sales-funnel/view-offer-3`}
        exact
        component={ViewOffer3}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/privacy-policy`}
        exact
        component={PrivacyPolicy}
        showMenu
      />
      <Route
        path={`${process.env.PUBLIC_URL}/terms-of-use`}
        exact
        component={TermsOfUse}
        showMenu
      />
      <Route
        path={`${process.env.PUBLIC_URL}/dmca-notice`}
        exact
        component={DMCANotice}
        showMenu
      />
      <Route
        path={`${process.env.PUBLIC_URL}/refund-policy`}
        exact
        component={RefundPolicy}
        showMenu
      />
      <Route
        path={`${process.env.PUBLIC_URL}/anti-spam-policy`}
        exact
        component={AntiSpamPolicy}
        showMenu
      />
      <Route
        path={`${process.env.PUBLIC_URL}/sms-privacy-policy`}
        exact
        component={SmsPrivacyPolicy}
        showMenu
      />
      <Route
        path={`${process.env.PUBLIC_URL}/copyright-notice`}
        exact
        component={CopyrightNotice}
        showMenu
      />

      <Route
        path={`${process.env.PUBLIC_URL}/book/i/:referrer`}
        component={Book}
      />
      <Route path={`${process.env.PUBLIC_URL}/sign-up`} component={SignUp} />
      <Route path={`${process.env.PUBLIC_URL}/video`} component={Video} />
      <Route
        path={`${process.env.PUBLIC_URL}/auto-login/:email/:password`}
        component={Video}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/request-sent`}
        exact
        component={Home}
      />
    </Switch>
  );
};

export default routes;
