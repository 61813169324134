import React, { useCallback, useEffect, useState } from 'react';
import {} from 'react-anchor-link-smooth-scroll';

import { Link } from 'react-router-dom';
import { IoIosArrowUp } from 'react-icons/io';
import { useLanguage } from '~/hooks/Language';
import { Container } from './styles';

import logo from '~/assets/logo/logo-footer.svg';
import twitter from '~/assets/icons/twitter-white.svg';
import facebook from '~/assets/icons/facebook-white.svg';
import telegram from '~/assets/icons/telegram-white.svg';
import instagram from '~/assets/icons/instagram-white.svg';

const Footer: React.FC = () => {
  const { language } = useLanguage();
  const [scroll, setScroll] = useState(0);
  const upScroll = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY);
    });
  }, []);

  return (
    <Container className="padding">
      <div className="container">
        <div className="row align-items-center justify-content-md-between">
          <div className="col-10 col-sm-6 col-md-4 col-lg-3 col-xxl-2 text-md-center mg-bottom px-4">
            <img src={logo} alt="Wiserr" className="" />
            <div className="d-flex justify-content-md-around mt-4">
              <a
                href="https://twitter.com/WiserrOfficial"
                target="blank"
                className="mr-3 mr-md-0"
              >
                <img src={twitter} alt="Twitter" />
              </a>
              <a
                href="https://facebook.com/wiserrofficial/"
                target="blank"
                className="mr-3 mr-md-0"
              >
                <img src={facebook} alt="Facebook" />
              </a>
              <a
                href="https://t.me/wiserrofficial"
                target="blank"
                className="mr-3 mr-md-0"
              >
                <img src={telegram} alt="Telegram" />
              </a>
              <a
                href="https://instagram.com/millonario.digital/"
                target="blank"
                className=""
              >
                <img src={instagram} alt="Instagram" />
              </a>
            </div>
          </div>
          <div className="col-md-8 col-lg-6 px-4">
            <p className="h6 mb-0 text-left text-md-right">
              <span className="d-block">{language.footer.p}</span>
              <br className="d-md-none" />
              <span className="">
                <Link
                  to={`${process.env.PUBLIC_URL}/privacy-policy`}
                  className="h6 mb-0"
                >
                  {language.footer.a_1}
                </Link>{' '}
                |{' '}
                <Link
                  to={`${process.env.PUBLIC_URL}/terms-of-use`}
                  className="h6 mb-0"
                >
                  {language.footer.a_2}
                </Link>{' '}
                |{' '}
                <Link
                  to={`${process.env.PUBLIC_URL}/dmca-notice`}
                  className="h6 mb-0"
                >
                  {language.footer.a_3}
                </Link>{' '}
                |{' '}
                <Link
                  to={`${process.env.PUBLIC_URL}/refund-policy`}
                  className="h6 mb-0"
                >
                  {language.footer.a_4}
                </Link>{' '}
                |{' '}
                <Link
                  to={`${process.env.PUBLIC_URL}/anti-spam-policy`}
                  className="h6 mb-0"
                >
                  {language.footer.a_5}
                </Link>{' '}
                |{' '}
                <Link
                  to={`${process.env.PUBLIC_URL}/sms-privacy-policy`}
                  className="h6 mb-0"
                >
                  {language.footer.a_6}
                </Link>{' '}
                |{' '}
                <Link
                  to={`${process.env.PUBLIC_URL}/copyright-notice`}
                  className="h6 mb-0"
                >
                  {language.footer.a_7}
                </Link>
              </span>
            </p>
          </div>
          <button
            type="button"
            onClick={upScroll}
            className={`border-0 rounded-circle p-2 p-lg-3 ${
              scroll < 1000 ? 'd-none' : ''
            }`}
          >
            <IoIosArrowUp size={23} className="mb-1" />
          </button>
        </div>
      </div>
    </Container>
  );
};

export default Footer;
