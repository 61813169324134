import styled from 'styled-components';
import { lighten } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Button = styled.button`
  span {
    color: #858585;
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    background: #ffffff;
    box-shadow: 19px 49px 112px rgba(192, 192, 192, 0.4),
      inset 1px 1px 2px rgba(255, 255, 255, 0.3),
      inset -1px -1px 2px rgba(138, 138, 138, 0.5);
    border-radius: 30px;
    border: 0 !important;
  }

  .btn-black {
    background-color: #18191a;
    border-radius: 15px;
    font-size: 18px;
    padding: 16.5px 20px;

    :hover {
      background-color: ${lighten(0.1, '#18191a')};
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
    }
  }

  @media screen and (max-width: 407px) {
  }

  @media screen and (max-width: 407px) {
    .ReactCreditCard {
      min-width: 267px !important;
    }
    .ReactCreditCard__container {
      left: 0px !important;
      max-width: 300px !important;
    }
    .ReactCreditCard__number {
      font-size: 20px !important;
    }
    .ReactCreditCard__name {
      font-size: 14px !important;
    }
  }
`;
