import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';

import { Container } from './styles';

import book from '~/assets/banners/bg-book-mobile.png';
import { useFunnelTrack } from '~/hooks/FunnelTrack';

interface IParams {
  referrer: string;
}

const Book: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const { language } = useLanguage();
  const {
    funnelsTracks,
    setFunnelTrack,
    ip,
    origin,
    country,
  } = useFunnelTrack();
  const [loading, setLoading] = useState(false);

  const date = new Date();
  const year = date.getFullYear();

  useEffect(() => {
    setLoading(true);
    api
      .get(`students/@${params.referrer}`)
      .then((response) => {
        localStorage.setItem(
          '@Membership:indication',
          `${response.data.affiliate.id}|@${params.referrer}`
        );
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message === 'Student not found'
        ) {
          Swal.fire(
            'Opss...',
            language.swal_message.referral_link,
            'error'
          ).then(() => {
            history.push(`${process.env.PUBLIC_URL}/`);
            setLoading(false);
          });
        } else {
          Swal.fire(
            'Opss...',
            language.swal_message.try_again,
            'error'
          ).then(() => setLoading(false));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    history,
    language.swal_message.referral_link,
    language.swal_message.try_again,
    params,
  ]);

  useEffect(() => {
    setTimeout(() => {
      if (origin) {
        const funnelTrackData = funnelsTracks.find(
          (funnelTrack) =>
            funnelTrack.ip === ip &&
            funnelTrack.page === 'book' &&
            funnelTrack.action === 'first-contact' &&
            funnelTrack.origin === origin
        );

        if (!funnelTrackData) {
          api
            .post('funnels-tracks', {
              page: 'book',
              action: 'first-contact',
              origin,
              country,
            })
            .then(() => {
              setFunnelTrack({
                ip,
                page: 'book',
                action: 'first-contact',
                origin,
                country,
              });
            });
        }
      }
    }, 400);
  }, [funnelsTracks, ip, setFunnelTrack, origin, country]);

  const handleClickDownload = useCallback(() => {
    if (origin) {
      const funnelTrackData = funnelsTracks.find(
        (funnelTrack) =>
          funnelTrack.ip === ip &&
          funnelTrack.page === 'book' &&
          funnelTrack.action === 'click-download-book' &&
          funnelTrack.origin === origin
      );

      if (!funnelTrackData) {
        api
          .post('funnels-tracks', {
            page: 'book',
            action: 'click-download-book',
            origin,
            country,
          })
          .then(() => {
            setFunnelTrack({
              ip,
              page: 'book',
              action: 'click-download-book',
              origin,
              country,
            });
          });
      }
    }
  }, [country, funnelsTracks, ip, origin, setFunnelTrack]);

  return (
    <>
      <Helmet>
        <meta property="og:title" content={`${language.head.title} - book`} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="http://wiserr.io" />
        <meta
          property="og:image:url"
          itemProp="image"
          content="http://cdn.wiserr.io/promotional-stuff/image-social-media.png"
        />
        <meta
          property="og:image"
          itemProp="image"
          content="http://cdn.wiserr.io/promotional-stuff/image-social-media.png"
        />
        <meta property="og:description" content={language.head.description} />
      </Helmet>
      <Container>
        <div className="container d-flex flex-column justify-content-md-between vh-100">
          <div />
          <div className="row justify-content-center justify-content-md-start">
            <div className="col-11 col-md-8 col-lg-6 col-xl-7 pt-5 pt-md-0">
              <h1 className="h1 display-lg-3 display-xxl-1">
                {language.book.h1_1}
                <strong>{language.book.h1_strong_1}</strong>
                {language.book.h1_2}
                <br />
                <strong>{language.book.h1_strong_2}</strong>
              </h1>
              <p className="h6 h4-xl pt-xl-4 pr-xl-5 pr-xxl-0 my-4 mb-md-4 mb-lg-5">
                {language.book.p_1_1}
                <strong>{language.book.p_strong_1}</strong>
                {language.book.p_1_2}&nbsp;{language.book.p_1_3}{' '}
                <strong>{language.book.p_strong_2}</strong>{' '}
                {language.book.p_1_4}&nbsp;{language.book.p_1_5}
              </p>
              <div className="col-xl-10 px-0">
                <Link
                  to={`${process.env.PUBLIC_URL}/sign-up`}
                  onClick={handleClickDownload}
                  className="btn px-2 my-3"
                >
                  <strong>{language.book.a}</strong>
                </Link>
              </div>
              <div className="row">
                <div className="col-lg-12 col-xl-10 text-center text-md-left text-lg-center">
                  <p className="color size-10 mb-0 mb-md-2">
                    {language.book.p_2}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 px-0">
              <img
                src={book}
                alt="Banner Book"
                className="d-block d-md-none w-100"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-8 col-lg-6 mg-negative">
              <p className="text-footer text-center">
                © {year} - {language.book.p_3}
              </p>
              <p className="text-footer text-center">
                {language.book.p_4_1} <br className="d-none d-md-block" />
                {language.book.p_4_2}
              </p>
            </div>
          </div>
        </div>
      </Container>
      {loading && (
        <>
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Book;
