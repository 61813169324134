import React from 'react';
import { useLanguage } from '~/hooks/Language';

import { Container } from './styles';
import Contact from '~/components/Contact';

const DMCANotice: React.FC = () => {
  const { language } = useLanguage();

  return (
    <>
      <Container>
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <h1>
                Wiserr.io
                <br />
                {language.dmca.h1}
              </h1>
              <hr className="mt-3 mb-5" />
              <div className="mb-5">
                <h2 className="h3">{language.dmca.h2_1}</h2>
                <p>{language.dmca.p_1}</p>
                <p>{language.dmca.p_2}</p>
                <p>{language.dmca.p_3}</p>
                <ul>
                  <li>{language.dmca.li_1}</li>
                  <li>{language.dmca.li_2}</li>
                </ul>
                <p>{language.dmca.p_4}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.dmca.h2_2}</h2>
                <p>{language.dmca.p_5}</p>
                <p>{language.dmca.p_6}</p>
                <p>{language.dmca.p_7}</p>
                <p>{language.dmca.p_8}</p>
                <p>{language.dmca.p_9}</p>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.dmca.h2_3}</h2>
                <p>{language.dmca.p_10}</p>
                <p>{language.dmca.p_11}</p>
                <p>
                  {language.dmca.p_12}{' '}
                  <a href="mailto:support@wiserr.io">support@wiserr.io</a>.
                </p>
                <p>{language.dmca.p_13}</p>
                <ol>
                  <li>{language.dmca.li_3}</li>
                  <li>{language.dmca.li_4}</li>
                  <li>{language.dmca.li_5}</li>
                  <li>
                    {language.dmca.li_6_1}"{language.dmca.li_6_2}"
                  </li>
                  <li>{language.dmca.li_7}</li>
                </ol>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.dmca.h2_4}</h2>
                <p>{language.dmca.p_14}</p>
                <p>{language.dmca.p_15}</p>
                <p>{language.dmca.p_16}</p>
                <p>{language.dmca.p_17}</p>
                <p>{language.dmca.p_18}</p>
                <p>{language.dmca.p_19}</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Contact />
    </>
  );
};

export default DMCANotice;
