import React from 'react';
import { useLanguage } from '~/hooks/Language';

import { Container } from './styles';
import Contact from '~/components/Contact';

const RefundPolicy: React.FC = () => {
  const { language } = useLanguage();
  return (
    <>
      <Container>
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <h1>
                Wiserr.io
                <br />
                {language.refundPolicy.h1}
              </h1>
              <hr className="mt-3 mb-5" />
              <div className="mb-5">
                <h2 className="h3">{language.refundPolicy.h2_1}</h2>
                <p>
                  {language.refundPolicy.p_1_1}
                  <u>{language.refundPolicy.p_1_u}</u>
                  {language.refundPolicy.p_1_2}
                </p>
                <p>
                  {language.refundPolicy.p_2_1}
                  <u>{language.refundPolicy.p_2_u}</u>
                  {language.refundPolicy.p_2_2}
                </p>
                <p>
                  {language.refundPolicy.p_3_1}{' '}
                  <u>{language.refundPolicy.p_3_u}</u>
                  {language.refundPolicy.p_3_2}{' '}
                  <a href="mailto:support@wiserr.io">support@wiserr.io</a>.
                </p>
                <p>
                  {language.refundPolicy.p_4_1}{' '}
                  <a href="mailto:support@wiserr.io">support@wiserr.io</a>{' '}
                  {language.refundPolicy.p_4_2}
                </p>
                <p>{language.refundPolicy.p_5}</p>
                <p>
                  <b>{language.refundPolicy.p_6}</b>
                </p>
                <ul>
                  <li>{language.refundPolicy.li_1}</li>
                </ul>
              </div>
              <div className="mb-5">
                <h2 className="h3">{language.refundPolicy.h2_2}</h2>
                <p>
                  <b>{language.refundPolicy.p_7}</b>
                </p>
                <p>
                  {language.refundPolicy.p_8_1}
                  <u>{language.refundPolicy.p_8_u}</u>
                  {language.refundPolicy.p_8_2}
                </p>
                <p>{language.refundPolicy.p_9}</p>
                <ul>
                  <li>
                    {language.refundPolicy.li_2}{' '}
                    <a href="mailto:support@wiserr.io">support@wiserr.io</a>.
                  </li>
                  <li>{language.refundPolicy.li_3}</li>
                  <li>{language.refundPolicy.li_4}</li>
                  <li>{language.refundPolicy.li_5}</li>
                  <li>{language.refundPolicy.li_6}</li>
                  <li>{language.refundPolicy.li_7}</li>
                  <li>{language.refundPolicy.li_8}</li>
                  <li>{language.refundPolicy.li_9}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Contact />
    </>
  );
};

export default RefundPolicy;
