import styled from 'styled-components';

export const Container = styled.footer`
  background-color: #1a1a1a;

  p {
    color: #a0a0a0;
    font-size: 14px;
    font-weight: 500;
  }

  a {
    color: #a0a0a0;
  }

  button {
    color: #000;
    background-color: #f7f7f7;
    position: fixed;
    z-index: 1000;
    bottom: 50px;
    right: 16%;
    height: 55px;
    width: 55px;
  }

  @media screen and (max-width: 574px) {
    button {
      height: 40px;
      width: 40px;
    }

    .logo-mobile {
      width: 70px;
    }
  }
`;
