/* eslint-disable no-console */
import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Form } from '@unform/web';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

import { useLanguage } from '~/hooks/Language';
import api from '~/services/api';
import getValidationsErrors from '~/utils/getValidationsErrors';
import { useFunnel } from '~/hooks/Funnel';

import { Button, Modal, ModalStep } from './styles';
import Input from '~/components/Input';
import InputAddress from '~/components/InputAddress';
import InputMask from '~/components/InputMask';
import closeIcon from '~/assets/icons/close.svg';
import checkWhite from '~/assets/icons/check-white.svg';
import checkBlack from '~/assets/icons/check-black.svg';
import checkGradient from '~/assets/icons/check-gradient.svg';
import circleCheck from '~/assets/icons/circle-check-black.svg';
import circle from '~/assets/icons/circle-blue.svg';
import safe from '~/assets/defaults/safe_secure.svg';
import money from '~/assets/defaults/money_back.svg';
import privacy from '~/assets/defaults/privacy_proteced.svg';
import oneBook from '~/assets/defaults/one-book.png';
import twoBooks from '~/assets/defaults/two-books.png';
import { formatPrice } from '~/utils/format';
import { useFunnelTrack } from '~/hooks/FunnelTrack';

interface IModalSalesFunnel {
  id: string;
  title: string;
  price: string;
  recurrence_price: string;
}

interface IAddress {
  city: string;
  complement: string;
  country: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
  zipCode: string;
}

interface IPersonalDetailsData {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  address: string;
}

interface IPaymentMethod {
  name: string;
  number: string;
  expiration: string;
  cvc: number;
}

interface IProduct {
  id: string;
  title: string;
  content: string;
  description: string;
  price: number;
  in_cart: boolean;
  image: string;
  shipping_price?: number;
}

interface ICartProduct {
  id: string;
  title: string;
  price: number;
  is_recurrent: boolean;
  shipping_price?: number;
}

interface ICreditCardResponse {
  card: {
    id: string;
  };
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN as string);

const ModalSalesFunnel: React.FC<IModalSalesFunnel> = ({
  price,
  recurrence_price,
}) => {
  const history = useHistory();
  const { language } = useLanguage();
  const { user, setUser } = useFunnel();
  const formPersonalDetailsRef = useRef<FormHandles>(null);
  const formPaymentMethod = useRef<FormHandles>(null);
  const {
    funnelsTracks,
    setFunnelTrack,
    ip,
    origin,
    country,
  } = useFunnelTrack();

  const [showChoice, setShowChoice] = useState(false);
  const [showStep, setShowStep] = useState(false);
  const [step, setStep] = useState(1);
  const [zipCodeData, setZipCodeData] = useState('');
  const [countryData, setCountryData] = useState('');
  const [streetData, setStreetData] = useState('');
  const [numberData, setNumberData] = useState('');
  const [neighborhoodData, setNeighborhoodData] = useState('');
  const [stateData, setStateData] = useState('');
  const [cityData, setCityData] = useState('');
  const [complementData, setComplementData] = useState('');
  const [studentId, setStudentId] = useState('');
  const [products] = useState<IProduct[]>([
    {
      id: 'a3ac9f7d-386b-4c7d-80bd-a1b97869638b',
      title: 'El Atajo del Millonario',
      content: 'Audiolibro + 4 productos exclusivos más',
      description:
        'Por fin una guía completa sobre el uso de Sketch para el diseño de aplicaciones móviles. Aprende a diseñar una app de la A a la Z',
      price: 20.9,
      in_cart: false,
      image: oneBook,
      shipping_price: 9.95,
    },
    {
      id: 'cdd9e2fa-8e22-4db4-8107-3475b8ac1aa9',
      title: 'El Atajo del Millonario (Audiolibro)',
      content: 'Audiolibro + 4 productos exclusivos más',
      description:
        'Por fin una guía completa sobre el uso de Sketch para el diseño de aplicaciones móviles. Aprende a diseñar una app de la A a la Z',
      price: 10.9,
      in_cart: false,
      image: twoBooks,
      shipping_price: 9.95,
    },
  ]);
  const [productSelected, setProductSelected] = useState({} as ICartProduct);
  const [cartProducts, setCartProducts] = useState<ICartProduct[]>([]);
  const [shippingPrice, setShippingPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalCart, setTotalCart] = useState('$0.00');
  const [creditCard, setCreditCard] = useState({} as ICreditCardResponse);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setProcessing] = useState(false);

  useEffect(() => {
    const totalShipping =
      cartProducts
        .filter((cartProduct) => !cartProduct.is_recurrent)
        .reduce(
          (previous, current) => previous + (current.shipping_price || 0),
          0
        ) + 9.95;
    const totalData =
      cartProducts
        .filter((cartProduct) => !cartProduct.is_recurrent)
        .reduce((previous, current) => previous + current.price, 0) +
      totalShipping;
    const totalRecurrent = cartProducts
      .filter((cartProduct) => cartProduct.is_recurrent)
      .reduce((previous, current) => previous + current.price, 0);

    if (totalRecurrent > 0) {
      setTotalCart(
        `${formatPrice(totalData)} + ${formatPrice(totalRecurrent)} por mes`
      );
    } else {
      setTotalCart(formatPrice(totalData));
    }
    setShippingPrice(totalShipping);
    setTotal(totalData);
  }, [cartProducts]);

  const handleShowChoice = useCallback(() => {
    if (origin) {
      const funnelTrackData = funnelsTracks.find(
        (funnelTrack) =>
          funnelTrack.ip === ip &&
          funnelTrack.page === 'sales-funnel' &&
          funnelTrack.action === 'click-show-offer' &&
          funnelTrack.origin === origin
      );
      if (!funnelTrackData) {
        api
          .post('funnels-tracks', {
            page: 'sales-funnel',
            action: 'click-show-offer',
            origin,
            country,
          })
          .then(() => {
            setFunnelTrack({
              ip,
              page: 'sales-funnel',
              action: 'click-show-offer',
              origin,
              country,
            });
          });
      }
    }

    setShowChoice(true);
  }, [funnelsTracks, ip, setFunnelTrack, origin, country]);

  const handleCloseChoice = useCallback(() => {
    if (origin) {
      const funnelTrackData = funnelsTracks.find(
        (funnelTrack) =>
          funnelTrack.ip === ip &&
          funnelTrack.page === 'sales-funnel' &&
          funnelTrack.action === 'click-close-offer' &&
          funnelTrack.origin === origin
      );
      if (!funnelTrackData) {
        api
          .post('funnels-tracks', {
            page: 'sales-funnel',
            action: 'click-close-offer',
            origin,
            country,
          })
          .then(() => {
            setFunnelTrack({
              ip,
              page: 'sales-funnel',
              action: 'click-close-offer',
              origin,
              country,
            });
          });
      }
    }

    setShowChoice(false);
  }, [country, funnelsTracks, ip, origin, setFunnelTrack]);

  const handleShowStep = useCallback(() => {
    if (origin) {
      const funnelTrackData = funnelsTracks.find(
        (funnelTrack) =>
          funnelTrack.ip === ip &&
          funnelTrack.page === 'sales-funnel' &&
          funnelTrack.action === 'init-personal-data' &&
          funnelTrack.origin === origin
      );
      if (!funnelTrackData) {
        api
          .post('funnels-tracks', {
            page: 'sales-funnel',
            action: 'init-personal-data',
            origin,
            country,
          })
          .then(() => {
            setFunnelTrack({
              ip,
              page: 'sales-funnel',
              action: 'init-personal-data',
              origin,
              country,
            });
          });
      }
    }

    setShowStep(true);
  }, [funnelsTracks, ip, setFunnelTrack, origin, country]);

  const handleCloseStep = useCallback(() => {
    if (origin) {
      if (!productSelected.is_recurrent) {
        const funnelTrackData = funnelsTracks.find(
          (funnelTrack) =>
            funnelTrack.ip === ip &&
            funnelTrack.page === 'sales-funnel' &&
            funnelTrack.action === 'click-close-recommended-offer' &&
            funnelTrack.origin === origin
        );
        if (!funnelTrackData) {
          api
            .post('funnels-tracks', {
              page: 'sales-funnel',
              action: 'click-close-recommended-offer',
              origin,
              country,
            })
            .then(() => {
              setFunnelTrack({
                ip,
                page: 'sales-funnel',
                action: 'click-close-recommended-offer',
                origin,
                country,
              });
            });
        }
      } else {
        const funnelTrackData = funnelsTracks.find(
          (funnelTrack) =>
            funnelTrack.ip === ip &&
            funnelTrack.page === 'sales-funnel' &&
            funnelTrack.action === 'click-close-recurrent-offer' &&
            funnelTrack.origin === origin
        );
        if (!funnelTrackData) {
          api
            .post('funnels-tracks', {
              page: 'sales-funnel',
              action: 'click-close-recurrent-offer',
              origin,
              country,
            })
            .then(() => {
              setFunnelTrack({
                ip,
                page: 'sales-funnel',
                action: 'click-close-recurrent-offer',
                origin,
                country,
              });
            });
        }
      }
    }

    setProductSelected({} as ICartProduct);
    setShowStep(false);
    setStep(1);
  }, [
    funnelsTracks,
    ip,
    productSelected.is_recurrent,
    setFunnelTrack,
    origin,
    country,
  ]);

  const handleSelectProduct = useCallback(
    (product: ICartProduct) => {
      if (origin) {
        if (!product.is_recurrent) {
          const funnelTrackData = funnelsTracks.find(
            (funnelTrack) =>
              funnelTrack.ip === ip &&
              funnelTrack.page === 'sales-funnel' &&
              funnelTrack.action === 'recommended-offer' &&
              funnelTrack.origin === origin
          );
          if (!funnelTrackData) {
            api
              .post('funnels-tracks', {
                page: 'sales-funnel',
                action: 'recommended-offer',
                origin,
                country,
              })
              .then(() => {
                setFunnelTrack({
                  ip,
                  page: 'sales-funnel',
                  action: 'recommended-offer',
                  origin,
                  country,
                });
              });
          }
        } else {
          const funnelTrackData = funnelsTracks.find(
            (funnelTrack) =>
              funnelTrack.ip === ip &&
              funnelTrack.page === 'sales-funnel' &&
              funnelTrack.action === 'recurrent-offer' &&
              funnelTrack.origin === origin
          );
          if (!funnelTrackData) {
            api
              .post('funnels-tracks', {
                page: 'sales-funnel',
                action: 'recurrent-offer',
                origin,
                country,
              })
              .then(() => {
                setFunnelTrack({
                  ip,
                  page: 'sales-funnel',
                  action: 'recurrent-offer',
                  origin,
                  country,
                });
              });
          }
        }
      }

      const newCartProducts = cartProducts.slice();
      const cartProductIndex = newCartProducts.findIndex(
        (cartProduct) => cartProduct.id === product.id
      );
      if (cartProductIndex >= 0) {
        newCartProducts[cartProductIndex] = product;
      } else {
        newCartProducts.push(product);
      }

      setProductSelected(product);
      setCartProducts(newCartProducts);
      handleShowStep();
    },
    [
      cartProducts,
      funnelsTracks,
      handleShowStep,
      ip,
      setFunnelTrack,
      origin,
      country,
    ]
  );

  const handleClickAddToCard = useCallback(
    (product) => {
      let inCart = true;
      let newCartProducts = cartProducts.slice();
      const cartProductCheck = newCartProducts.find(
        (cartProduct) => cartProduct.id === product.id
      );
      if (cartProductCheck) {
        newCartProducts = newCartProducts.filter(
          (cartProduct) => cartProduct.id !== product.id
        );
        inCart = false;
      } else {
        newCartProducts.push(product);
        inCart = true;
      }

      const productIndex = products.findIndex(
        (productData) => productData.id === product.id
      );
      if (productIndex >= 0) {
        products[productIndex].in_cart = inCart;
      }

      setCartProducts(newCartProducts);
    },
    [cartProducts, products]
  );

  const handleSetStep = useCallback(
    (num) => {
      switch (num) {
        case 1:
          if (origin) {
            const funnelTrackData = funnelsTracks.find(
              (funnelTrack) =>
                funnelTrack.ip === ip &&
                funnelTrack.page === 'sales-funnel' &&
                funnelTrack.action === 'init-payment-data' &&
                funnelTrack.origin === origin
            );
            if (!funnelTrackData) {
              api
                .post('funnels-tracks', {
                  page: 'sales-funnel',
                  action: 'init-payment-data',
                  origin,
                  country,
                })
                .then(() => {
                  setFunnelTrack({
                    ip,
                    page: 'sales-funnel',
                    action: 'init-payment-data',
                    origin,
                    country,
                  });
                });
            }
          }
          setStep(num + 1);

          break;
        case 2:
          if (origin) {
            const funnelTrackData = funnelsTracks.find(
              (funnelTrack) =>
                funnelTrack.ip === ip &&
                funnelTrack.page === 'sales-funnel' &&
                funnelTrack.action === 'init-confirm-payment-data' &&
                funnelTrack.origin === origin
            );
            if (!funnelTrackData) {
              api
                .post('funnels-tracks', {
                  page: 'sales-funnel',
                  action: 'init-confirm-payment-data',
                  origin,
                  country,
                })
                .then(() => {
                  setFunnelTrack({
                    ip,
                    page: 'sales-funnel',
                    action: 'init-confirm-payment-data',
                    origin,
                    country,
                  });
                });
            }
          }
          setStep(num + 1);

          break;
        default:
          setStep(1);
          break;
      }
    },
    [funnelsTracks, ip, setFunnelTrack, origin, country]
  );

  const handleChangeAddress = useCallback((addressData: IAddress) => {
    const {
      zipCode,
      country: countryAddress,
      street,
      number,
      neighborhood,
      state,
      city,
      complement,
    } = addressData;
    setZipCodeData(zipCode);
    setCountryData(countryAddress);
    setStreetData(street);
    setNumberData(number);
    setNeighborhoodData(neighborhood);
    setStateData(state);
    setCityData(city);
    setComplementData(complement);
  }, []);

  const handleSubmitPersonalDetails = useCallback(
    async (data: IPersonalDetailsData) => {
      // setLoading(true);
      try {
        formPersonalDetailsRef.current?.setErrors({});
        const schema = Yup.object().shape({
          first_name: Yup.string().required(
            language.modal_sales_funnel_component.schema_first_name
          ),
          last_name: Yup.string().required(
            language.modal_sales_funnel_component.schema_last_name
          ),
          email: Yup.string().required(
            language.modal_sales_funnel_component.schema_email
          ),
          phone: Yup.string().required(
            language.modal_sales_funnel_component.schema_phone
          ),
          address: Yup.string().when('$address', {
            is: (addressCheck: boolean) => addressCheck,
            then: Yup.string()
              .url('O endereço precisa ser completo')
              .required('O endereço precisa ser completo'),
            otherwise: Yup.string().required('O endereço precisa ser completo'),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            address: !(
              zipCodeData &&
              countryData &&
              streetData &&
              numberData &&
              neighborhoodData &&
              stateData &&
              cityData
            ),
          },
        });

        const { first_name, last_name, email, phone } = data;

        const formData = {
          name: `${first_name} ${last_name}`,
          email,
          phone,
        };

        const response = await api.post('students/funnel', formData);

        const formAddressData = {
          student_id: response.data.id,
          zip_code: zipCodeData,
          country: countryData,
          street: streetData,
          number: numberData,
          neighborhood: neighborhoodData,
          city: cityData,
          complement: complementData,
          state: stateData,
        };

        await api.post('adresses/funnel', formAddressData);

        if (origin) {
          const funnelTrackData = funnelsTracks.find(
            (funnelTrack) =>
              funnelTrack.ip === ip &&
              funnelTrack.page === 'sales-funnel' &&
              funnelTrack.action === 'end-personal-data'
          );
          if (!funnelTrackData) {
            api
              .post('funnels-tracks', {
                page: 'sales-funnel',
                action: 'end-personal-data',
                origin,
                country,
              })
              .then(() => {
                setFunnelTrack({
                  ip,
                  page: 'sales-funnel',
                  action: 'end-personal-data',
                  origin,
                  country,
                });
              });
          }
        }

        setUser(response.data);
        setStudentId(response.data.id);
        handleSetStep(step);
      } catch (error) {
        console.log(error);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationsErrors(error);
          formPersonalDetailsRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            language.modal_sales_funnel_component.swal_error,
            'error'
          );
        }
      } finally {
        // setLoading(false);
      }
    },
    [
      cityData,
      complementData,
      countryData,
      funnelsTracks,
      handleSetStep,
      ip,
      language.modal_sales_funnel_component.schema_email,
      language.modal_sales_funnel_component.schema_first_name,
      language.modal_sales_funnel_component.schema_last_name,
      language.modal_sales_funnel_component.schema_phone,
      language.modal_sales_funnel_component.swal_error,
      neighborhoodData,
      numberData,
      setFunnelTrack,
      setUser,
      stateData,
      step,
      streetData,
      zipCodeData,
      origin,
      country,
    ]
  );

  const handleSubmitPaymentMethod = useCallback(
    async (data: IPaymentMethod) => {
      // setLoading(true);
      try {
        formPersonalDetailsRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required(
            language.modal_sales_funnel_component.schema_credit_name
          ),
          number: Yup.string().required(
            language.modal_sales_funnel_component.schema_credit_number
          ),
          expiration: Yup.string().required(
            language.modal_sales_funnel_component.schema_credit_expiration
          ),
          cvc: Yup.string().required(
            language.modal_sales_funnel_component.schema_credit_cvc
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { name, number, expiration, cvc } = data;

        const [exp_month, exp_year] = expiration.split('/');

        const formData = {
          student_id: studentId,
          name,
          number,
          exp_month,
          exp_year,
          cvc,
          primary_card: true,
        };

        const creditCardResponse = await api.post(
          'payments/students/credit-card',
          formData
        );

        if (origin) {
          const funnelTrackData = funnelsTracks.find(
            (funnelTrack) =>
              funnelTrack.ip === ip &&
              funnelTrack.page === 'sales-funnel' &&
              funnelTrack.action === 'end-payment-data' &&
              funnelTrack.origin === origin
          );
          if (!funnelTrackData) {
            api
              .post('funnels-tracks', {
                page: 'sales-funnel',
                action: 'end-payment-data',
                origin,
                country,
              })
              .then(() => {
                setFunnelTrack({
                  ip,
                  page: 'sales-funnel',
                  action: 'end-payment-data',
                  origin,
                  country,
                });
              });
          }
        }

        setCreditCard(creditCardResponse.data);
        handleSetStep(step);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationsErrors(error);
          formPersonalDetailsRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            language.modal_sales_funnel_component.swal_error,
            'error'
          );
        }
      } finally {
        // setLoading(false);
      }
    },
    [
      funnelsTracks,
      handleSetStep,
      ip,
      language.modal_sales_funnel_component.schema_credit_cvc,
      language.modal_sales_funnel_component.schema_credit_expiration,
      language.modal_sales_funnel_component.schema_credit_name,
      language.modal_sales_funnel_component.schema_credit_number,
      language.modal_sales_funnel_component.swal_error,
      setFunnelTrack,
      step,
      studentId,
      origin,
      country,
    ]
  );

  const handleSubmitOffer = useCallback(async () => {
    try {
      setProcessing(true);
      const stripe = await stripePromise;
      if (stripe) {
        const cartResponse = await api.post(`carts`, {
          student_id: user.id,
          price: total,
        });

        const cartProductsNoRecurrent = cartProducts.filter(
          (cartProduct) => !cartProduct.is_recurrent
        );
        if (cartProductsNoRecurrent.length > 0) {
          const cartProductsNoRecurrentPromise = new Promise<void>(
            (resolve) => {
              cartProductsNoRecurrent.forEach(async (cartProduct, index) => {
                const formData = {
                  cart_id: cartResponse.data.id,
                  product_id: cartProduct.id,
                  price: cartProduct.price,
                  shipping_price: cartProduct.shipping_price,
                };

                await api.post('carts-products', formData);

                if (cartProductsNoRecurrent.length === index + 1) {
                  resolve();
                }
              });
            }
          );

          await cartProductsNoRecurrentPromise;
        }

        const payment_method = creditCard.card.id;
        const response = await api.post(`payments/credit-cards/${user.id}`, {
          product_id: cartResponse.data.id,
          payment_method,
        });

        const result = await stripe.confirmCardPayment(
          response.data.payment.client_secret
        );

        if (
          result.paymentIntent &&
          result.paymentIntent.status === 'succeeded'
        ) {
          await api.patch(`orders/close/${response.data.order.id}/${user.id}`);
        }

        if (result.error) {
          Swal.fire('Opss...', result.error.message, 'error');
        } else {
          const cartProductsRecurrent = cartProducts.filter(
            (cartProduct) => cartProduct.is_recurrent
          );

          if (cartProductsRecurrent.length > 0) {
            const cartProductsRecurrentPromise = new Promise<void>(
              (resolve) => {
                cartProductsRecurrent.forEach(async (cartProduct, index) => {
                  const responsePayment = await api.post(
                    `payments/credit-cards/${user.id}`,
                    {
                      product_id: cartProduct.id,
                      payment_method,
                    },
                    {
                      params: {
                        price: cartProduct.price,
                      },
                    }
                  );

                  const resultRecurrent = await stripe.confirmCardPayment(
                    responsePayment.data.payment.client_secret
                  );

                  if (
                    resultRecurrent.paymentIntent &&
                    resultRecurrent.paymentIntent.status === 'succeeded'
                  ) {
                    await api.patch(
                      `orders/close/${responsePayment.data.order.id}/${user.id}`
                    );
                  }

                  if (resultRecurrent.error) {
                    Swal.fire(
                      'Opss...',
                      resultRecurrent.error.message,
                      'error'
                    );
                  }

                  if (cartProductsRecurrent.length === index + 1) {
                    resolve();
                  }
                });
              }
            );

            await cartProductsRecurrentPromise;
          }

          if (origin) {
            const funnelTrackData = funnelsTracks.find(
              (funnelTrack) =>
                funnelTrack.ip === ip &&
                funnelTrack.page === 'sales-funnel' &&
                funnelTrack.action === 'end-confirm-payment-data' &&
                funnelTrack.origin === origin
            );
            if (!funnelTrackData) {
              api
                .post('funnels-tracks', {
                  page: 'sales-funnel',
                  action: 'end-confirm-payment-data',
                  origin,
                  country,
                })
                .then(() => {
                  setFunnelTrack({
                    ip,
                    page: 'sales-funnel',
                    action: 'end-confirm-payment-data',
                    origin,
                    country,
                  });
                });
            }
          }

          history.push(`${process.env.PUBLIC_URL}/sales-funnel/view-offer-2`);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setProcessing(false);
    }
  }, [
    cartProducts,
    creditCard,
    funnelsTracks,
    history,
    ip,
    setFunnelTrack,
    total,
    user.id,
    origin,
    country,
  ]);

  return (
    <>
      <Button
        type="button"
        className="btn font-weight-bold"
        onClick={handleShowChoice}
      >
        {language.modal_sales_funnel_component.button_1}
      </Button>
      <Modal
        className="modal-sales-funnel px-0"
        centered
        size="xl"
        show={showChoice}
        onHide={handleCloseChoice}
      >
        <Modal.Header className="border-0 pb-sm-0 pt-4 pt-sm-5 px-4 px-sm-5 justify-content-end">
          <button
            type="button"
            className="close-button border-0"
            onClick={handleCloseChoice}
          >
            <img src={closeIcon} alt="Close" />
          </button>
        </Modal.Header>
        <Modal.Body className="container py-0 px-3 px-sm-5">
          <div className="row">
            <div className="col-xl-8 my-4 my-lg-0">
              <h2 className="font-weight-600 mb-0">
                {language.modal_sales_funnel_component.h2_1}
              </h2>
            </div>

            <div className="col-lg-11 col-xl-7 mb-4 mb-lg-0">
              <p className="font-weight-300 my-4 my-xxl-5 h2-description">
                {language.modal_sales_funnel_component.p_1}
              </p>
            </div>
          </div>
          <div className="row mb-5 justify-content-around">
            <div className="col-lg-7">
              <div className="bg-blue text-white py-5 py-lg-4 px-4 px-sm-5">
                <u className="d-flex justify-content-end mb-5 mb-lg-0">
                  {language.modal_sales_funnel_component.u_1}
                </u>
                <h3 className="h2 h1-sm text-white font-weight-bold">
                  {price} {language.modal_sales_funnel_component.h3_1}
                </h3>
                <p className="h6 font-weight-400 text-white">
                  {language.modal_sales_funnel_component.p_2}
                </p>
                <div className="row mt-5">
                  <div className="col-xl-7">
                    <div className="d-flex mb-3 align-items-start">
                      <img src={checkWhite} alt="Check icon" className="mr-3" />
                      <p className="text-white mb-0">
                        {language.modal_sales_funnel_component.p_3}
                      </p>
                    </div>
                    <div className="d-flex mb-3 align-items-start">
                      <img src={checkWhite} alt="Check icon" className="mr-3" />
                      <p className="text-white mb-0">
                        {language.modal_sales_funnel_component.p_4}
                      </p>
                    </div>
                    <div className="d-flex mb-4 mb-xl-3 align-items-start">
                      <img src={checkWhite} alt="Check icon" className="mr-3" />
                      <p className="text-white mb-0">
                        {language.modal_sales_funnel_component.p_5}
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-5">
                    <div className="d-flex mb-3 align-items-start">
                      <img src={checkWhite} alt="Check icon" className="mr-3" />
                      <p className="text-white mb-0">
                        {language.modal_sales_funnel_component.p_6}
                      </p>
                    </div>
                    <div className="d-flex mb-3 align-items-start">
                      <img src={checkWhite} alt="Check icon" className="mr-3" />
                      <p className="text-white mb-0">
                        {language.modal_sales_funnel_component.p_7}
                      </p>
                    </div>
                    <div className="d-flex mb-3 align-items-start">
                      <img src={checkWhite} alt="Check icon" className="mr-3" />
                      <p className="text-white mb-0">
                        {language.modal_sales_funnel_component.p_8}
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-8 mt-3 mt-lg-0">
                    <button
                      type="button"
                      className="w-100 border-0 pad-button font-weight-bold text-black bg-white"
                      onClick={() =>
                        handleSelectProduct({
                          id: 'ccd6e6bc-ea1d-4408-ad0b-a722fd5873bf',
                          title: 'Franquicia Digital',
                          price: 97,
                          is_recurrent: false,
                        })
                      }
                    >
                      {language.modal_sales_funnel_component.button_2}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-xl-4 mt-5 mt-lg-0">
              <div className="bd-lg-black h-100 py-5 py-lg-4 px-4 px-sm-5 d-flex flex-column">
                <h3 className="h2 h1-sm text-black font-weight-bold">
                  {recurrence_price}{' '}
                  {language.modal_sales_funnel_component.h3_2}
                </h3>
                <p className="text-mob-gray">
                  {language.modal_sales_funnel_component.p_9}
                </p>
                <div className="d-flex mb-3 align-items-start mt-4 mt-xxl-5">
                  <img src={checkBlack} alt="Check icon" className="mr-3" />
                  <p className="mb-0">
                    {language.modal_sales_funnel_component.p_10}
                  </p>
                </div>
                <div className="d-flex mb-3 align-items-start">
                  <img src={checkBlack} alt="Check icon" className="mr-3" />
                  <p className="mb-0">
                    {language.modal_sales_funnel_component.p_11}
                  </p>
                </div>
                <div className="d-flex mb-0 align-items-start">
                  <img src={checkBlack} alt="Check icon" className="mr-3" />
                  <p className="mb-0">
                    {language.modal_sales_funnel_component.p_12}
                  </p>
                </div>
                <button
                  type="button"
                  className="w-100 border-0 pad-button font-weight-bold text-black bg-plan mt-5 mt-lg-auto"
                  onClick={() =>
                    handleSelectProduct({
                      id: 'ccd6e6bc-ea1d-4408-ad0b-a722fd5873bf',
                      title: 'Franquicia Digital',
                      price: 7,
                      is_recurrent: true,
                    })
                  }
                >
                  {language.modal_sales_funnel_component.button_3}
                </button>
              </div>
            </div>
          </div>
          <div className="row px-3 px-lg-0 pt-xl-5 justify-content-center">
            <div className="col-lg-11 py-5 py-lg-0 bd-mobile">
              <div className="row bg-gray padding align-items-center">
                <div className="col-12 d-lg-none px-0 text-right mb-5 px-sm-3">
                  <img src={checkGradient} alt="Check" />
                </div>

                <div className="col-lg-6 px-0 px-sm-3">
                  <h4 className="d-xl-flex mb-0 font-weight-bold">
                    {language.modal_sales_funnel_component.h4_1}
                    <span className="d-none d-xl-block">&nbsp;-&nbsp;</span>
                    <br className="d-xl-none" />
                    <br className="d-xl-none" />
                    <span className="text-mob-gray text-gradient-lg-table">
                      $297 {language.modal_sales_funnel_component.h4_1_span}
                    </span>
                  </h4>
                </div>
                <div className="col-lg-3 text-center d-none d-lg-block">
                  <img src={circleCheck} alt="Check icon" />
                </div>
                <div className="col-lg-3 text-center d-none d-lg-block">
                  <img src={circleCheck} alt="Check icon" />
                </div>
              </div>
              <div className="row padding align-items-center">
                <div className="col-lg-6 px-0 px-sm-3">
                  <h4 className="d-xl-flex mb-0 font-weight-bold">
                    {language.modal_sales_funnel_component.h4_2}
                    <span className="d-none d-xl-block">&nbsp;-&nbsp;</span>
                    <br className="d-xl-none" />
                    <br className="d-xl-none" />
                    <span className="text-mob-gray text-gradient-lg-table">
                      $47 {language.modal_sales_funnel_component.h4_2_span}
                    </span>
                  </h4>
                </div>
                <div className="col-lg-3 text-center d-none d-lg-block">
                  <img src={circleCheck} alt="Check icon" />
                </div>
                <div className="col-lg-3 text-center d-none d-lg-block">
                  <img src={circleCheck} alt="Check icon" />
                </div>
              </div>
              <div className="row bg-gray padding align-items-center">
                <div className="col-lg-6 px-0 px-sm-3">
                  <h4 className="d-xl-flex mb-0 font-weight-bold">
                    {language.modal_sales_funnel_component.h4_3}
                    <span className="d-none d-xl-block">&nbsp;-&nbsp;</span>
                    <br className="d-xl-none" />
                    <br className="d-xl-none" />
                    <span className="text-mob-gray text-gradient-lg-table">
                      $347 {language.modal_sales_funnel_component.h4_3_span}
                    </span>
                  </h4>
                </div>
                <div className="col-lg-3 text-center d-none d-lg-block">
                  <img src={circleCheck} alt="Check icon" />
                </div>
                <div className="col-lg-3 text-center d-none d-lg-block">
                  <img src={circleCheck} alt="Check icon" />
                </div>
              </div>
              <div className="row padding align-items-center">
                <div className="col-lg-6 px-0 px-sm-3">
                  <h4 className="d-xl-flex mb-0 font-weight-bold">
                    {language.modal_sales_funnel_component.h4_4}
                    <span className="d-none d-xl-block">&nbsp;-&nbsp;</span>
                    <br className="d-xl-none" />
                    <br className="d-xl-none" />
                    <span className="text-mob-gray text-gradient-lg-table">
                      $1,000 {language.modal_sales_funnel_component.h4_4_span}
                    </span>
                  </h4>
                </div>
                <div className="col-lg-3 text-center d-none d-lg-block">
                  <img src={circleCheck} alt="Check icon" />
                </div>
                <div className="col-lg-3 text-center d-none d-lg-block" />
              </div>
              <div className="row bg-gray padding align-items-center">
                <div className="col-lg-6 px-0 px-sm-3">
                  <h4 className="mb-0 font-weight-bold">
                    {language.modal_sales_funnel_component.h4_5}
                  </h4>
                </div>
                <div className="col-lg-3 text-center d-none d-lg-block">
                  <img src={circleCheck} alt="Check icon" />
                </div>
                <div className="col-lg-3 text-center d-none d-lg-block" />
              </div>
            </div>
          </div>
          <div className="row justify-content-lg-end mt-5 mb-4">
            <div className="col-lg-10 text-center text-xl-left col-xl-7">
              <button
                type="button"
                className="btn-blue border-0 text-white mr-lg-5"
                onClick={() =>
                  handleSelectProduct({
                    id: 'ccd6e6bc-ea1d-4408-ad0b-a722fd5873bf',
                    title: 'Franquicia Digital',
                    price: 97,
                    is_recurrent: false,
                  })
                }
              >
                {language.modal_sales_funnel_component.button_4}
              </button>
              <button
                type="button"
                className="btn-continue border-0 bg-transparent font-weight-bold mt-4 mt-xl-0"
                onClick={() =>
                  handleSelectProduct({
                    id: 'ccd6e6bc-ea1d-4408-ad0b-a722fd5873bf',
                    title: 'Franquicia Digital',
                    price: 7,
                    is_recurrent: true,
                  })
                }
              >
                {language.modal_sales_funnel_component.button_5}
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0" />
      </Modal>
      <ModalStep
        className="modal-sales-funnel px-0"
        centered
        size="xl"
        show={showStep}
        onHide={handleCloseStep}
      >
        <ModalStep.Header className="d-block border-0 pb-0 pt-5 px-0 px-lg-5">
          <div className="w-100 text-right">
            <button
              type="button"
              className="close-button border-0 pr-5 pr-lg-0"
              onClick={handleCloseStep}
            >
              <img src={closeIcon} alt="Close" />
            </button>
          </div>
          <div className="container">
            <div className="row mt-4 mb-5 row-padding pt-2 justify-content-center">
              <div
                className={`${step === 1 ? 'selected-step' : 'step'} ${
                  step > 1 ? 'active-step' : ''
                } col col-sm-3 col-lg-2 px-0 px-sm-3 text-center`}
              >
                <div
                  className={` height mx-auto rounded-circle d-flex align-items-center justify-content-center mb-4`}
                >
                  <span className="font-weight-bold">1</span>
                </div>
                <p>{language.modal_sales_funnel_component.p_13}</p>
              </div>
              <div className="col-2 col-sm-1 px-0 px-lg-3 pt-1 pt-lg-2">
                <hr className={step > 1 ? 'active-hr' : ''} />
              </div>
              <div
                className={`${step === 2 ? 'selected-step' : 'step'} ${
                  step > 2 ? 'active-step' : ''
                } col col-sm-3 col-lg-2 px-0 px-sm-3 text-center`}
              >
                <div
                  className={` height mx-auto rounded-circle d-flex align-items-center justify-content-center mb-4`}
                >
                  <span className="font-weight-bold">2</span>
                </div>
                <p>{language.modal_sales_funnel_component.p_14}</p>
              </div>
              <div className="col-2 col-sm-1 px-0 px-lg-3 pt-1 pt-lg-2">
                <hr className={step > 2 ? 'active-hr' : ''} />
              </div>
              <div
                className={`${
                  step === 3 ? 'selected-step' : 'step'
                } col col-sm-3 col-lg-2 px-0 px-sm-3 text-center`}
              >
                <div
                  className={` height mx-auto rounded-circle d-flex align-items-center justify-content-center mb-4`}
                >
                  <span className="font-weight-bold">3</span>
                </div>
                <p>{language.modal_sales_funnel_component.p_15}</p>
              </div>
            </div>
          </div>
        </ModalStep.Header>
        <ModalStep.Body className="container py-0 px-4 px-lg-5">
          {step === 1 && (
            <Form
              ref={formPersonalDetailsRef}
              onSubmit={handleSubmitPersonalDetails}
            >
              <div className="row justify-content-center">
                <div className="col-lg-11 col-xl-8">
                  <h2 className="font-weight-500 mb-5 mb-lg-4">
                    {language.modal_sales_funnel_component.h2_2}
                  </h2>
                  <div className="row">
                    <div className="col-lg-6 mb-4">
                      <label htmlFor="name">
                        {language.modal_sales_funnel_component.label_1}
                      </label>
                      <Input
                        name="first_name"
                        id="first_name"
                        className="bg-input"
                      />
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label htmlFor="name">
                        {language.modal_sales_funnel_component.label_2}
                      </label>
                      <Input
                        name="last_name"
                        id="last_name"
                        className="bg-input"
                      />
                    </div>
                    <div className="col-12 mb-4">
                      <label htmlFor="email">
                        {language.modal_sales_funnel_component.label_3}
                      </label>
                      <Input
                        type="email"
                        name="email"
                        id="email"
                        className="bg-input"
                      />
                    </div>
                    <div className="col-12 mb-4">
                      <label htmlFor="phone">
                        {language.modal_sales_funnel_component.label_4}
                      </label>
                      <Input name="phone" id="phone" className="bg-input" />
                    </div>
                    <div className="col-12 mb-4">
                      <h2 className="font-weight-600">
                        {language.modal_sales_funnel_component.h2_3}
                      </h2>
                    </div>
                    <div className="col-12 mb-5">
                      <label htmlFor="phone">
                        {language.modal_sales_funnel_component.label_5}
                      </label>
                      <InputAddress
                        name="address"
                        onChange={handleChangeAddress}
                        className="bg-input"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-11 col-xl-8">
                  <button
                    type="submit"
                    className="w-100 border-0 btn-black font-weight-600 text-white"
                  >
                    {language.modal_sales_funnel_component.button_6}
                  </button>
                </div>
              </div>
            </Form>
          )}
          {step === 2 && (
            <Form ref={formPaymentMethod} onSubmit={handleSubmitPaymentMethod}>
              <div className="row justify-content-center">
                <div className="col-lg-11 col-xl-8 my-5">
                  <h2 className="font-weight-500 mb-4">
                    {language.modal_sales_funnel_component.h2_4}
                  </h2>
                  <div className="row">
                    <div className="col-12 mb-4">
                      <label htmlFor="credit_card">
                        {language.modal_sales_funnel_component.label_6}
                      </label>
                      <Input name="name" className="bg-input" />
                    </div>
                    <div className="col-12 mb-4">
                      <label htmlFor="number">
                        {language.modal_sales_funnel_component.label_7}
                      </label>
                      <InputMask
                        kind="credit-card"
                        name="number"
                        id="number"
                        className="bg-input"
                      />
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label htmlFor="name">
                        {language.modal_sales_funnel_component.label_8}
                      </label>
                      <InputMask
                        kind="datetime"
                        options={{
                          format: 'MM/YY',
                        }}
                        name="expiration"
                        id="expiration"
                        className="bg-input"
                      />
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label htmlFor="name">CVC*</label>
                      <Input name="cvc" id="cvc" className="bg-input" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-11 col-xl-8">
                  <button
                    type="submit"
                    className="w-100 border-0 btn-black font-weight-600 text-white"
                  >
                    {language.modal_sales_funnel_component.button_7}
                  </button>
                </div>
              </div>
            </Form>
          )}
          {step === 3 && (
            <div className="row justify-content-center items px-4 px-sm-5 px-lg-0">
              <div className="col-lg-11 col-xl-8 my-5">
                <div className="row">
                  <div className="col-6 mb-4">
                    <h3 className="font-weight-600">
                      {language.modal_sales_funnel_component.h3_3}
                    </h3>
                  </div>
                  <div className="col-6 text-right mb-4">
                    <h3 className="font-weight-600">
                      {language.modal_sales_funnel_component.h3_4}
                    </h3>
                  </div>
                  <div className="col-3 col-sm-6 mb-4 d-flex">
                    <img src={circle} alt="Circle icon" className="mr-2" />{' '}
                    <span className="d-none d-lg-block font-weight-500">
                      {language.modal_sales_funnel_component.span_1}
                    </span>
                  </div>
                  <div className="col-9 col-sm-6 text-right mb-4">
                    <span className="font-weight-500">
                      {language.modal_sales_funnel_component.span_2}
                    </span>
                  </div>
                  {products.map((product) => (
                    <div
                      key={product.id}
                      className="col-12 py-5 px-4 p-sm-5 mt-5 bd-box"
                    >
                      <div className="row">
                        <div className="col-lg-3 text-center text-lg-left mb-4 mb-sm-0">
                          <img src={product.image} alt="Book" />
                        </div>
                        <p className="d-lg-none text-right w-100 my-4 pr-4">
                          <AiOutlinePlusCircle size={17} color="#2F80ED" />
                        </p>
                        <div className="col-lg-9 px-4 px-lg-5">
                          <h2 className="font-weight-500 mt-4 mb-1 mb-lg-3 text-center text-lg-left">
                            {product.title}
                          </h2>
                          <h4 className="gradient-items font-weight-600 mb-4 mb-lg-3 text-center text-lg-left">
                            {product.content.slice(0, 10)}
                            <span className="font-weight-300">
                              {' '}
                              {product.content.slice(11)}
                            </span>
                          </h4>
                          <p className="font-weight-400 mb-4">
                            {product.description}
                          </p>
                          <button
                            type="button"
                            className="btn-cart font-weight-900 border-0 w-100"
                            onClick={() => handleClickAddToCard(product)}
                          >
                            {product.in_cart
                              ? 'Remover do carrinho'
                              : language.modal_sales_funnel_component.button_8}
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="col-12 mt-5">
                    <div className="row">
                      <div className="col-6 mb-4">
                        <h3 className="font-weight-600">
                          {language.modal_sales_funnel_component.h3_5}
                        </h3>
                      </div>
                      <div className="col-6 text-right mb-4">
                        <h3 className="font-weight-600">
                          {language.modal_sales_funnel_component.h3_6}
                        </h3>
                      </div>
                    </div>
                    {cartProducts.map((cartProduct) => (
                      <div key={cartProduct.id} className="row mb-2">
                        <div className="col-6">
                          <span className="font-weight-500">
                            {cartProduct.title}
                          </span>
                        </div>
                        <div className="col-6 text-right">
                          <span className="font-weight-500">
                            {formatPrice(cartProduct.price)}
                            {cartProduct.is_recurrent && ' Por mes'}
                          </span>
                        </div>
                      </div>
                    ))}
                    <div className="row mb-2">
                      <div className="col-6">
                        <span className="font-weight-500">
                          {language.modal_sales_funnel_component.span_3}
                        </span>
                      </div>
                      <div className="col-6 text-right">
                        <span className="font-weight-500">
                          {language.modal_sales_funnel_component.span_4}
                        </span>
                      </div>
                    </div>
                    <div className="row mb-5">
                      <div className="col-6">
                        <span className="font-weight-500">
                          {language.modal_sales_funnel_component.span_5}
                        </span>
                      </div>
                      <div className="col-6 text-right">
                        <span className="font-weight-500">
                          {formatPrice(shippingPrice)}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <h3 className="font-weight-600">
                          {language.modal_sales_funnel_component.h3_7}
                        </h3>
                      </div>
                      <div className="col-6 text-right">
                        <h3 className="font-weight-600">{totalCart}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-11 col-xl-8">
                <button
                  type="submit"
                  className="w-100 border-0 btn-black font-weight-600 text-white"
                  onClick={handleSubmitOffer}
                >
                  {language.modal_sales_funnel_component.button_9}
                </button>
              </div>
            </div>
          )}
        </ModalStep.Body>
        <ModalStep.Footer className="container border-0">
          <div className="row w-100 justify-content-center">
            <div className="col-lg-11 col-xl-8 my-5 px-lg-4 px-xl-3">
              <div className="row align-items-center">
                <div className="col-sm-6 col-lg-4 text-center">
                  <img src={safe} alt="GUARANTEED SAFE & SECURE CHECKOUT" />
                </div>
                <div className="col-sm-6 col-lg-4 my-4 my-sm-0 text-center">
                  <img src={money} alt="MONEY-BACK GUARANTEE" />
                </div>
                <div className="col-lg-4 mt-sm-4 mt-lg-0 text-center">
                  <img src={privacy} alt="PRIVACY PROTECED" />
                </div>
                <div className="col-12 terms mt-5 pb-5">
                  <h4 className="mb-1">
                    {language.modal_sales_funnel_component.h4_7}
                  </h4>
                  <p className="font-weight-200 mb-0">
                    {language.modal_sales_funnel_component.p_17}
                  </p>
                  <p className="font-weight-200 mb-0">
                    {language.modal_sales_funnel_component.p_18}
                  </p>
                  <p className="font-weight-200 mb-0">
                    {language.modal_sales_funnel_component.p_19}
                  </p>
                  <p className="font-weight-200 mb-0">
                    {language.modal_sales_funnel_component.p_20}
                  </p>
                  <p className="font-weight-200 mb-0">
                    {language.modal_sales_funnel_component.p_21}
                  </p>
                  <p className="font-weight-200 mb-0">
                    {language.modal_sales_funnel_component.p_22}
                  </p>
                  <p className="font-weight-200 mb-0">
                    {language.modal_sales_funnel_component.p_23}
                  </p>
                  <p className="font-weight-200 mb-0">
                    {language.modal_sales_funnel_component.p_24}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ModalStep.Footer>
      </ModalStep>
    </>
  );
};

export default ModalSalesFunnel;
