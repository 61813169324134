import styled from 'styled-components';

export const Container = styled.footer`
  font-family: 'Poppins', sans-serif;
  background: #f5f3ed;

  padding: 0 23px 46px 23px;
  h2 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #f3f4f8;
  }

  a,
  button {
    background: transparent;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #b1b1b5;
    margin-bottom: 6px;
    transition-duration: 0.3s;
    :hover {
      opacity: 0.8;
    }
  }

  hr {
    margin: 50px 0;
    border-top: 1px solid #64646c;
  }

  span,
  span a {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: #dadada;
  }

  .container-fluid {
    background: #18191a;
    border-radius: 40px;
  }

  .logo-footer {
    width: 106px;
    height: 131px;
  }

  @media screen and (max-width: 767px) {
    a img {
      width: 46px;
    }
  }

  @media screen and (max-width: 575px) {
    padding: 0 10px 20px 10px;

    a,
    button {
      line-height: 36px;
    }
  }

  @media screen and (min-width: 768px) {
    h2 {
      font-size: 16px;
    }
    a,
    button {
      font-size: 12px;
    }

    span,
    span a {
      font-size: 12px;
    }
  }

  @media screen and (min-width: 1400px) {
    h2 {
      font-size: 20px;
    }
    a,
    button {
      font-size: 16px;
    }

    span,
    span a {
      font-size: 16px;
    }
  }

  @media screen and (min-width: 1600px) {
    .container-fluid {
      max-width: 1500px !important;
    }
  }
`;
