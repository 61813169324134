import React from 'react';

import { LanguageProvider } from './Language';
import { FunnelProvider } from './Funnel';
import { FunnelTrackProvider } from './FunnelTrack';

const AppProvider: React.FC = ({ children }) => (
  <LanguageProvider>
    <FunnelTrackProvider>
      <FunnelProvider>{children}</FunnelProvider>
    </FunnelTrackProvider>
  </LanguageProvider>
);

export default AppProvider;
