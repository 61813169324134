/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import * as Yup from 'yup';
import { loadStripe } from '@stripe/stripe-js';
import Swal from 'sweetalert2';

import { formatPrice } from '~/utils/format';
import { useLanguage } from '~/hooks/Language';
import { useFunnel } from '~/hooks/Funnel';

import { Modal, Button } from './styles';
import Input from '../Input';
import AddCreditCard from '~/components/AddCreditCard';

import visa from '~/assets/icons/cc-visa.svg';
import mastercard from '~/assets/icons/cc-mastercard.svg';
import discover from '~/assets/icons/cc-discover.svg';
import amex from '~/assets/icons/cc-amex.svg';
import ccDefault from '~/assets/icons/cc-default.svg';
import safe from '~/assets/defaults/safe_secure.svg';
import money from '~/assets/defaults/money_back.svg';
import privacy from '~/assets/defaults/privacy_proteced.svg';
import wallet from '~/assets/icons/wallet.svg';
import closeIcon from '~/assets/icons/close.svg';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import { useFunnelTrack } from '~/hooks/FunnelTrack';

interface IBuyCreditCardProps {
  product_id: string;
  onSucceededPaid?(data?: any): void;
  text_button: string;
  price?: number;
  installments?: number;
  page: string;
  action: string;
}

interface IDataPayment {
  credit_card: string;
}

interface ICreditCard {
  id: string;
  name: string;
  number: string;
  expirity: string;
  brand: string;
  brandIcon: string;
  primary_card: boolean;
}

interface ICreditCardResponse {
  id: string;
  name: string;
  number: string;
  expirity: string;
  brand: string;
  primary_card: boolean;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN as string);

const CreditCardPay: React.FC<IBuyCreditCardProps> = ({
  text_button,
  price = 0,
  product_id,
  onSucceededPaid,
  installments,
  page,
  action,
}: IBuyCreditCardProps) => {
  const {
    funnelsTracks,
    setFunnelTrack,
    ip,
    origin,
    country,
  } = useFunnelTrack();
  const formRef = useRef<FormHandles>(null);
  const { language } = useLanguage();
  const { user } = useFunnel();
  const [showCreditCard, setShowCreditCard] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<ICreditCard[]>([]);
  const [cardSelected, setCardSelected] = useState('');
  const [processing, setProcessing] = useState(false);
  const [showAdd, setShowAdd] = useState(false);

  useEffect(() => {
    api
      .get<ICreditCardResponse[]>(`payments/credit-cards/${user.id}`)
      .then((response) => {
        const data: ICreditCard[] = response.data.map((creditCard) => {
          let brandIcon;
          switch (creditCard.brand) {
            case 'Visa':
              brandIcon = visa;
              break;

            case 'MasterCard':
              brandIcon = mastercard;
              break;

            case 'Discover':
              brandIcon = discover;
              break;

            case 'American Express':
              brandIcon = amex;
              break;

            default:
              brandIcon = ccDefault;
              break;
          }
          if (creditCard.primary_card) {
            setCardSelected(creditCard.id);
          }
          return {
            id: creditCard.id,
            name: creditCard.name,
            number: creditCard.number,
            expirity: creditCard.expirity,
            primary_card: creditCard.primary_card,
            brand: creditCard.brand,
            brandIcon,
          };
        });
        setPaymentMethods(data);
      });
  }, [user.id]);

  const handleCreditCardClose = useCallback(() => {
    if (origin) {
      const funnelTrackData = funnelsTracks.find(
        (funnelTrack) =>
          funnelTrack.ip === ip &&
          funnelTrack.page === page &&
          funnelTrack.action === `close-${action}` &&
          funnelTrack.origin === origin
      );
      if (!funnelTrackData) {
        api
          .post('funnels-tracks', {
            page,
            action: `close-${action}`,
            origin,
            country,
          })
          .then(() => {
            setFunnelTrack({
              ip,
              page,
              action: `close-${action}`,
              origin,
              country,
            });
          });
      }
    }
    setShowCreditCard(false);
  }, [action, funnelsTracks, ip, page, setFunnelTrack, origin, country]);

  const handleCreditCardShow = useCallback(() => {
    if (origin) {
      const funnelTrackData = funnelsTracks.find(
        (funnelTrack) =>
          funnelTrack.ip === ip &&
          funnelTrack.page === page &&
          funnelTrack.action === action &&
          funnelTrack.origin === origin
      );
      if (!funnelTrackData) {
        api
          .post('funnels-tracks', {
            page,
            action,
            origin,
            country,
          })
          .then(() => {
            setFunnelTrack({
              ip,
              page,
              action,
              country,
              origin,
            });
          });
      }
    }
    setShowCreditCard(true);
  }, [action, country, funnelsTracks, ip, origin, page, setFunnelTrack]);

  const handleClick = useCallback((id) => {
    setCardSelected(id);
  }, []);

  const handleClickCreatedCard = useCallback(() => {
    setShowAdd(true);
  }, []);

  const handleAddCardClose = useCallback(
    (active) => {
      if (active) {
        setShowAdd(false);
      }
      if (paymentMethods.length <= 0) {
        setShowCreditCard(false);
      }
    },
    [paymentMethods.length]
  );

  const handleCreatedCard = useCallback((card) => {
    let brandIcon;
    switch (card.flag) {
      case 'Visa':
        brandIcon = visa;
        break;

      case 'MasterCard':
        brandIcon = mastercard;
        break;

      case 'Discover':
        brandIcon = discover;
        break;

      case 'American Express':
      case 'Amex':
        brandIcon = amex;
        break;

      default:
        brandIcon = ccDefault;
        break;
    }

    const data = {
      id: card.id,
      name: card.name,
      number: card.number,
      expirity: card.expirity,
      primary_card: card.primary_card,
      brand: card.flag,
      brandIcon,
    };
    setPaymentMethods((state) => [...state, data]);
    setShowAdd(false);
  }, []);

  const handlePay = useCallback(
    async (data: IDataPayment) => {
      setProcessing(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          credit_card: Yup.string().required(language.buy_component.yup),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const payment_method = data.credit_card;
        const stripe = await stripePromise;

        if (stripe) {
          const response = await api.post(`payments/credit-cards/${user.id}`, {
            product_id,
            payment_method,
            installments,
          });

          if (response.data) {
            const result = await stripe.confirmCardPayment(
              response.data.payment.client_secret
            );

            if (
              result.paymentIntent &&
              result.paymentIntent.status === 'succeeded'
            ) {
              const responseOrder = await api.patch(
                `orders/close/${response.data.order.id}/${user.id}`
              );

              if (origin) {
                const funnelTrackData = funnelsTracks.find(
                  (funnelTrack) =>
                    funnelTrack.ip === ip &&
                    funnelTrack.page === page &&
                    funnelTrack.action === `${action}-paid` &&
                    funnelTrack.origin === origin
                );
                if (!funnelTrackData) {
                  api
                    .post('funnels-tracks', {
                      page,
                      action: `${action}-paid`,
                      origin,
                      country,
                    })
                    .then(() => {
                      setFunnelTrack({
                        ip,
                        page,
                        action: `${action}-paid`,
                        origin,
                        country,
                      });
                    });
                }
              }

              if (onSucceededPaid) {
                onSucceededPaid(responseOrder.data);
              }
            }

            if (result.error) {
              Swal.fire('Opss...', result.error.message, 'error').then(() =>
                setProcessing(false)
              );
            }
          }
        }
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErros(err);
          formRef.current?.setErrors(errors);
          setProcessing(false);
        } else {
          Swal.fire(
            'Opss...',
            language.error_component.error_message,
            'error'
          ).then(() => setProcessing(false));
        }
      }
    },
    [
      language.buy_component.yup,
      language.error_component.error_message,
      user.id,
      product_id,
      installments,
      funnelsTracks,
      onSucceededPaid,
      ip,
      page,
      action,
      setFunnelTrack,
      origin,
      country,
    ]
  );

  return (
    <>
      <Button type="button" onClick={handleCreditCardShow}>
        {text_button}
      </Button>

      <Modal
        className="modal-sales-funnel"
        centered
        size="lg"
        show={showCreditCard}
        onHide={handleCreditCardClose}
      >
        <Modal.Header className="border-0 p-4 justify-content-end">
          <button
            type="button"
            className="border-0 bg-transparent"
            onClick={handleCreditCardClose}
          >
            <img src={closeIcon} alt="Close" />
          </button>
        </Modal.Header>
        <Modal.Body className="mx-auto w-100">
          <h3 className="text-center payment-color">
            <img src={wallet} alt="wallet" className="mr-4" />
            {language.credit_card_pay_component.h3}
          </h3>
          <div className="row justify-content-center">
            <div className="col-lg-10 px-3">
              <Form ref={formRef} className="mb-2 mx-auto" onSubmit={handlePay}>
                <div className="pt-2 pb-5">
                  {paymentMethods.map((card, index) => (
                    <label
                      key={card.id}
                      htmlFor={`credit-card-${index + 1}`}
                      onClick={() => handleClick(card.id)}
                      className={`${
                        cardSelected === card.id ? 'bg-darken' : ''
                      } bg-payment-cards px-4 px-lg-0 py-4 mt-4 d-block`}
                    >
                      <div className="row">
                        <div className="col-3 pad-mobile text-center d-flex align-items-center justify-content-sm-center">
                          <img
                            src={card.brandIcon}
                            alt={card.brand}
                            className="pl-2 pl-sm-0"
                          />
                        </div>
                        <div className="col px-0 px-sm-3 d-flex align-items-center">
                          <p className="mb-0 pl-2 pl-sm-0">
                            {card.brand}:&nbsp;&nbsp;****-{card.number}
                          </p>
                        </div>
                        <div className="col-2 text-center pl-0 pr-2 px-sm-3 d-flex align-items-center justify-content-end justify-content-sm-center">
                          <HiOutlineDotsVertical size={25} color="#303030" />
                        </div>
                      </div>
                      <Input
                        type="radio"
                        id={`credit-card-${index + 1}`}
                        name="credit_card"
                        value={card.id}
                        className="d-none"
                        checked={cardSelected === card.id}
                      />
                    </label>
                  ))}
                </div>
                <div className="row px-4 px-lg-0 d-flex justify-content-sm-between">
                  <div className="col-sm-7 text-center text-sm-left">
                    <AddCreditCard
                      className="h6 border-0 bg-transparent"
                      onOpen={handleClickCreatedCard}
                      onClose={handleAddCardClose}
                      active={showAdd}
                      onCreatedCard={handleCreatedCard}
                    />
                  </div>

                  <div className="col-sm-5 mt-4 mt-sm-0 text-center text-sm-right">
                    {formatPrice(price)}
                  </div>
                </div>
                <div className="w-100 px-sm-4 d-flex justify-content-center">
                  <button
                    type="submit"
                    className="btn-black border-0 px-5 py-3 mt-5 w-100"
                  >
                    <span className="text-white font-weight-bold">
                      {processing ? (
                        <div className="spinner" id="spinner" />
                      ) : (
                        language.credit_card_pay_component.button
                      )}
                    </span>
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="container border-0">
          <div className="row w-100 justify-content-center">
            <div className="col-sm-11 my-lg-5">
              <div className="row align-items-center">
                <div className="col-lg-4 text-center mb-4 mb-lg-0">
                  <img src={safe} alt="GUARANTEED SAFE & SECURE CHECKOUT" />
                </div>
                <div className="col-sm-6 col-lg-4 text-center mb-4 mb-sm-0">
                  <img src={money} alt="MONEY-BACK GUARANTEE" />
                </div>
                <div className="col-sm-6 col-lg-4 text-center">
                  <img src={privacy} alt="PRIVACY PROTECED" />
                </div>
                <div className="col-12 terms mt-5 pb-5">
                  <h4 className="mb-1">
                    {language.credit_card_pay_component.h4}
                  </h4>
                  <p className="font-weight-200 mb-0">
                    {language.credit_card_pay_component.p_1}
                  </p>
                  <p className="font-weight-200 mb-0">
                    {language.credit_card_pay_component.p_2}
                  </p>
                  <p className="font-weight-200 mb-0">
                    {language.credit_card_pay_component.p_3}
                  </p>
                  <p className="font-weight-200 mb-0">
                    {language.credit_card_pay_component.p_4}
                  </p>
                  <p className="font-weight-200 mb-0">
                    {language.credit_card_pay_component.p_5}
                  </p>
                  <p className="font-weight-200 mb-0">
                    {language.credit_card_pay_component.p_6}
                  </p>
                  <p className="font-weight-200 mb-0">
                    {language.credit_card_pay_component.p_7}
                  </p>
                  <p className="font-weight-200 mb-0">
                    {language.credit_card_pay_component.p_8}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreditCardPay;
