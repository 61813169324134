/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';

interface ILanguage {
  head: {
    title: string;
    description: string;
  };

  menu_mobile: {
    a_1: string;
    a_2: string;
    a_3: string;
    a_4: string;
    a_5: string;
    a_6: string;
    a_7: string;
  };

  home: {
    h1_span_1: string;
    h1_span_2: string;
    h1_span_3: string;
    p_1: string;
    a_1: string;
    //
    h2_1: string;
    h2_span_1: string;
    p_2: string;
    a_2: string;
    p_3: string;
    p_4: string;
    //
    h2_2: string;
    h2_2_1: string;
    h2_span_2: string;
    button_1: string;
    button_2: string;
    p_5: string;
    p_6: string;
    a_3: string;
    //
    h2_3_1: string;
    h2_3_2: string;
    h2_span_3: string;
    p_7: string;
    p_8: string;
    p_9: string;
    //
    h2_4_1: string;
    h2_span_4: string;
    h3_1: string;
    p_10: string;
    h3_2: string;
    p_11: string;
    h3_3: string;
    p_12: string;
    p_span_1: string;
    p_span_2: string;
    p_span_3: string;
    //
    h2_5_1: string;
    p_13: string;
    span_1: string;
    span_2: string;
    span_3: string;
    p_14: string;
    p_15: string;
    p_16: string;
    p_17: string;
    p_18: string;
    p_19: string;
    p_20: string;
  };

  book: {
    h1_1: string;
    h1_strong_1: string;
    h1_2: string;
    h1_strong_2: string;
    p_1_1: string;
    p_strong_1: string;
    p_1_2: string;
    p_1_3: string;
    p_strong_2: string;
    p_1_4: string;
    p_1_5: string;
    a: string;
    p_2: string;
    p_3: string;
    p_4_1: string;
    p_4_2: string;
  };

  signUp: {
    h1: string;
    h1_span_1: string;
    h1_span_2: string;
    h1_span_2_1: string;
    p: string;
    label_1: string;
    label_2: string;
    label_3: string;
    label_4_1: string;
    label_4_2: string;
    button: string;
    require_1: string;
    require_2: string;
    require_3: string;
  };

  video: {
    h1_span: string;
    h1: string;
    h2_strong_1: string;
    h2: string;
    h2_strong_2: string;
    video: string;
    h4_1: string;
    h4_2: string;
    p_1: string;
    p_2: string;
    p_3: string;
    p_4_span_1: string;
    p_4_span_2: string;
    button: string;
  };

  antiSpan: {
    h1: string;
    h2: string;
    p_1: string;
    p_2: string;
    p_3: string;
    p_4: string;
    p_5: string;
    p_6_1: string;
    p_6_2: string;
  };

  copyRight: {
    h1: string;
    h2: string;
    p_1: string;
    p_2: string;
    p_3: string;
    li_1: string;
    li_2: string;
    li_3: string;
    li_4: string;
    li_5: string;
    li_6: string;
    p_4: string;
    p_5: string;
  };

  dmca: {
    h1: string;
    h2_1: string;
    p_1: string;
    p_2: string;
    p_3: string;
    li_1: string;
    li_2: string;
    p_4: string;
    h2_2: string;
    p_5: string;
    p_6: string;
    p_7: string;
    p_8: string;
    p_9: string;
    h2_3: string;
    p_10: string;
    p_11: string;
    p_12: string;
    p_13: string;
    li_3: string;
    li_4: string;
    li_5: string;
    li_6_1: string;
    li_6_2: string;
    li_7: string;
    h2_4: string;
    p_14: string;
    p_15: string;
    p_16: string;
    p_17: string;
    p_18: string;
    p_19: string;
  };

  privacyPolicy: {
    h1: string;
    h2_1: string;
    p_1: string;
    p_2: string;
    p_3: string;
    p_4: string;
    h2_2: string;
    p_5: string;
    li_1: string;
    li_2: string;
    li_3: string;
    li_4: string;
    h2_3: string;
    p_6: string;
    p_7: string;
    p_8: string;
    p_9: string;
    p_10: string;
    h2_4: string;
    p_11: string;
    li_5: string;
    li_6: string;
    li_7: string;
    li_8: string;
    li_9: string;
    li_10: string;
    li_11: string;
    li_12: string;
    li_13: string;
    li_14: string;
    li_15: string;
    li_16: string;
    h2_5: string;
    p_12: string;
    p_13: string;
    p_14: string;
    p_15: string;
    p_16: string;
    p_17: string;
    p_18: string;
    p_19: string;
    h2_6: string;
    p_20: string;
    p_21: string;
    p_22: string;
    p_23: string;
    h2_7: string;
    p_24: string;
    h2_8: string;
    p_25: string;
    h2_9: string;
    p_26: string;
    p_27_u: string;
    p_27: string;
    p_28_u: string;
    p_28: string;
    p_29_u: string;
    p_29: string;
    p_30_u: string;
    p_30: string;
    p_31_u: string;
    p_31: string;
    p_32: string;
    h2_10: string;
    p_33: string;
    p_34: string;
    p_35: string;
    h2_11: string;
    p_36: string;
    h2_12: string;
    p_37: string;
    h2_13: string;
    p_38: string;
    p_39: string;
    p_40: string;
    h2_14: string;
    p_41: string;
  };

  refundPolicy: {
    h1: string;
    h2_1: string;
    p_1_1: string;
    p_1_u: string;
    p_1_2: string;
    p_2_1: string;
    p_2_u: string;
    p_2_2: string;
    p_3_1: string;
    p_3_u: string;
    p_3_2: string;
    p_4_1: string;
    p_4_2: string;
    p_5: string;
    p_6: string;
    li_1: string;
    h2_2: string;
    p_7: string;
    p_8_1: string;
    p_8_u: string;
    p_8_2: string;
    p_9: string;
    li_2: string;
    li_3: string;
    li_4: string;
    li_5: string;
    li_6: string;
    li_7: string;
    li_8: string;
    li_9: string;
  };

  smsPolicy: {
    h1: string;
    h2_1: string;
    p_1: string;
    p_2: string;
    h2_2: string;
    p_3: string;
    p_4: string;
    p_5: string;
    p_6: string;
    p_7: string;
    p_8: string;
    h2_3: string;
    p_9: string;
  };

  termsUse: {
    h1: string;
    h2_1: string;
    p_1: string;
    p_2: string;
    p_3: string;
    p_4: string;
    p_5: string;
    h2_2: string;
    p_6: string;
    h2_3: string;
    p_7: string;
    p_8: string;
    p_9: string;
    h2_4: string;
    p_10: string;
    h2_5: string;
    p_11: string;
    p_12: string;
    p_13: string;
    h2_6: string;
    p_14: string;
    p_15: string;
    p_16: string;
    p_17: string;
    h2_7: string;
    p_18: string;
    li_1: string;
    li_2: string;
    li_3: string;
    li_4: string;
    li_5: string;
    li_6: string;
    li_7: string;
    li_8: string;
    li_9: string;
    li_10: string;
    li_11: string;
    li_12: string;
    p_19: string;
    p_20: string;
    p_21: string;
    p_22: string;
    p_23: string;
    h2_8: string;
    p_24: string;
    h2_9: string;
    p_25: string;
    h2_10: string;
    p_26: string;
    h2_11: string;
    p_27: string;
    p_28: string;
    h2_12: string;
    p_29: string;
    h2_13: string;
    p_30: string;
    h2_14: string;
    p_31: string;
    h2_15: string;
    p_32: string;
    h2_16: string;
    p_33: string;
    p_34: string;
    p_35: string;
    p_36: string;
    p_37: string;
    h2_17: string;
    p_38_b: string;
    p_38: string;
    p_39_b: string;
    p_39: string;
    p_40_b: string;
    p_40: string;
    p_41_b: string;
    p_41: string;
    p_42_b: string;
    p_42: string;
    p_43_b: string;
    p_43: string;
  };

  footer: {
    p: string;
    a_1: string;
    a_2: string;
    a_3: string;
    a_4: string;
    a_5: string;
    a_6: string;
    a_7: string;
  };
  buy_component: {
    button_1: string;
    button_2: string;
    h4_1: string;
    button_3: string;
    button_5: string;
    h4_2: string;
    erro_1: string;
    erro_2: string;
    erro_3: string;
    erro_4: string;
    erro_5: string;
    yup: string;
  };
  error_component: {
    error_message_axios: string;
    error_message: string;
    verification_message: string;
    all_right_message: string;
  };
  contact_component: {
    h2: string;
    h2_span: string;
    p: string;
    label_1: string;
    label_2: string;
    label_3: string;
    placeholder: string;
    button: string;
  };

  credit_card_component: {
    input_1: string;
    input_2: string;
    input_3: string;
    input_4: string;
    input_5: string;
  };
  select_component: {
    input: string;
  };
  password_component: {
    button_1: string;
    button_2: string;
  };
  sales_funnel: {
    h1_1: string;
    h1_2: string;
    h1_3: string;
    h1_span: string;
    p_1: string;
    p_2_1: string;
    p_2_2: string;
    p_2_3: string;
    div: string;
    h2_1: string;
    h2_1_span: string;
    h3_1: string;
    h3_2: string;
    h3_2_span: string;
    p_3: string;
    p_4_1: string;
    b_1: string;
    p_4_2: string;
    b_2: string;
    p_4_3: string;
    p_4_4: string;
    b_3: string;
    p_4_5: string;
    p_5_1: string;
    p_5_2: string;
    p_5_3: string;
    p_6_1: string;
    p_6_2: string;
    p_6_3: string;
    h2_2_1: string;
    h2_2_2: string;
    h2_2_span: string;
    p_7_b: string;
    p_7: string;
    p_8: string;
    p_9: string;
    h2_span: string;
    h2_3: string;
    h2_4: string;
    p_10: string;
    h3_3: string;
    p_11: string;
    h3_4: string;
    p_12: string;
    h3_5: string;
    p_13: string;
    h3_6: string;
    p_14: string;
    h3_7: string;
    p_15: string;
    h2_5: string;
    h2_5_span: string;
    h3_8: string;
    p_16: string;
    h3_9: string;
    p_17: string;
    h3_10: string;
    p_18: string;
    h2_6: string;
    p_19: string;
    p_20: string;
    p_21_1: string;
    p_21_2: string;
    p_21_3: string;
    p_22_1: string;
    p_22_2: string;
    h2_7: string;
    h2_7_span: string;
    p_23: string;
    h3_11: string;
    p_24_1: string;
    p_24_span: string;
    p_24_2: string;
    h3_12: string;
    p_25: string;
    h3_13: string;
    p_26: string;
    p_27: string;
    p_28: string;
    p_29: string;
  };
  modal_sales_funnel_component: {
    schema_first_name: string;
    schema_last_name: string;
    schema_email: string;
    schema_phone: string;
    schema_address: string;
    swal_error: string;
    schema_credit_name: string;
    schema_credit_number: string;
    schema_credit_expiration: string;
    schema_credit_cvc: string;
    button_1: string;
    h2_1: string;
    p_1: string;
    u_1: string;
    h3_1: string;
    p_2: string;
    p_2_1: string;
    p_3: string;
    p_4: string;
    p_5: string;
    p_6: string;
    p_7: string;
    p_8: string;
    button_2: string;
    h3_2: string;
    p_9: string;
    p_10: string;
    p_11: string;
    p_12: string;
    button_3: string;
    h4_1: string;
    h4_1_span: string;
    h4_2: string;
    h4_2_span: string;
    h4_3: string;
    h4_3_span: string;
    h4_4: string;
    h4_4_span: string;
    h4_5: string;
    button_4: string;
    button_5: string;
    p_13: string;
    p_14: string;
    p_15: string;
    h2_2: string;
    label_1: string;
    label_2: string;
    label_3: string;
    label_4: string;
    h2_3: string;
    label_5: string;
    button_6: string;
    h2_4: string;
    label_6: string;
    label_7: string;
    label_8: string;
    button_7: string;
    h3_3: string;
    h3_4: string;
    span_1: string;
    span_2: string;

    h2_5: string;
    h4_6: string;
    h4_6_span: string;
    p_16: string;
    button_8: string;

    h3_5: string;
    h3_6: string;
    span_3: string;
    span_4: string;
    span_5: string;
    h3_7: string;
    button_9: string;
    h4_7: string;
    p_17: string;
    p_18: string;
    p_19: string;
    p_20: string;
    p_21: string;
    p_22: string;
    p_23: string;
    p_24: string;
  };

  attention_component: {
    h2_b_1: string;
    h2_1: string;
    h2_b_u: string;
    h2_2: string;
  };

  view_offer: {
    div_1: string;
    div_2: string;
    h1: string;
    h1_span: string;
    button: string;
  };

  modal_no_thanks: {
    button_1: string;
    h2_1: string;
    p_1: string;
    p_2_1: string;
    p_2_span: string;
    p_2_2: string;
    p_3: string;
    p_4: string;
    p_5: string;
    p_6: string;
    text_button_1: string;
    a_1: string;
    p_7: string;
    p_8: string;
    p_9: string;
    h2_2: string;
    label_1: string;
    label_2: string;
    label_3: string;
    label_4: string;
    h2_3: string;
    label_5: string;
    button_2: string;
    h2_4: string;
    h4_1: string;
    h4_1_span: string;
    p_10: string;
    text_button_2: string;
    button_3: string;
    h3_1: string;
    h3_2: string;
    div_span_1: string;
    div_span_2: string;
    h2_5: string;
    h4_2: string;
    h4_2_span: string;
    p_11: string;
    button_4: string;
    h3_3: string;
    h3_4: string;
    div_span_3: string;
    div_span_4: string;
    div_span_5: string;
    h3_5: string;
    button_5: string;
    h4_3: string;
    p_12: string;
    p_13: string;
    p_14: string;
    p_15: string;
    p_16: string;
    p_17: string;
    p_18: string;
    p_19: string;
  };

  credit_card_pay_component: {
    h3: string;
    button: string;
    h4: string;
    p_1: string;
    p_2: string;
    p_3: string;
    p_4: string;
    p_5: string;
    p_6: string;
    p_7: string;
    p_8: string;
  };

  add_credit_card: {
    schema_name: string;
    schema_number: string;
    schema_expiration: string;
    schema_cvc: string;
    schema_flag: string;
    swal_error: string;
    button: string;
    title: string;
    button_span: string;
  };

  congratulations: {
    h2_b_1: string;
    h2_1_1: string;
    h2_b_2: string;
    h2_1_2: string;
    h1_span: string;
    h1: string;
    h2_2: string;
    p_1: string;
    h2_3: string;
    h2_3_span: string;
    p_2_b: string;
    p_2_1: string;
    p_2_2: string;
    p_3_b: string;
    p_3_1: string;
    h2_4: string;
    h2_4_span: string;
    h3_1: string;
    p_4: string;
    h3_2: string;
    p_5: string;
    h3_3: string;
    p_6: string;
    h3_4: string;
    p_7_1: string;
    p_7_2: string;
    p_8: string;
  };
  swal_message: {
    good_job: string;
    successfully: string;
    referral_link: string;
    try_again: string;
    valid_indication: string;
  };
}

interface LanguageContextData {
  language: ILanguage;
  handleSelectIdiom(idiom: string): void;
  idiom: string;
}

export const LanguageContext = createContext<LanguageContextData>(
  {} as LanguageContextData
);

export const LanguageProvider: React.FC = ({ children }) => {
  const [idiom, setIdiom] = useState(
    localStorage.getItem('@Wiserr:language') || 'en'
  );

  const handleSelectIdiom = useCallback((idiomSelected) => {
    setIdiom(idiomSelected);
  }, []);

  const language = useMemo(() => {
    localStorage.setItem('@Wiserr:language', idiom);
    return require(`./languages/${idiom}`);
  }, [idiom]);

  return (
    <LanguageContext.Provider value={{ language, handleSelectIdiom, idiom }}>
      {children}
    </LanguageContext.Provider>
  );
};

export function useLanguage(): LanguageContextData {
  const context = useContext(LanguageContext);

  if (!context) {
    throw new Error('useLanguage must be used within an LanguageProvider');
  }

  return context;
}
