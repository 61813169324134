import styled from 'styled-components';

export const Container = styled.div`
  background: #ff1a50;
  box-shadow: 0px 4px 4px rgba(255, 26, 80, 0.15);

  svg {
    height: 35px;
    width: 35px;
  }

  @media screen and (max-width: 991px) {
    margin-top: 64px;

    svg {
    height: 30px;
    width: 30px;
  }

  @media screen and (max-width: 767px) {
    svg {
    height: 25px;
    width: 25px;
  }
  }
`;
