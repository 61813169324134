import React from 'react';
import { Container } from './styles';
import thumb from '~/assets/defaults/thumb-funnel.png';

interface IProps {
  videoPlay?: () => void;
  videoEnded: () => void;
  src: string;
}

const Video: React.FC<IProps> = ({ videoPlay, videoEnded, src }) => {
  return (
    <Container
      src={src}
      controls
      id="video"
      poster={thumb}
      onPlay={() => {
        console.log(videoPlay);
        videoPlay && videoPlay();
      }}
      onEnded={() => {
        videoEnded();
      }}
    >
      <track
        default
        kind="captions"
        srcLang="pt-BR"
        src="https://www.w3schools.com/html/mov_bbb.mp4"
      />
    </Container>
  );
};

export default Video;
