import styled from 'styled-components';

export const Container = styled.div`
  h1,
  h2,
  u {
    font-weight: bold !important;
  }

  h1 {
    color: #000;
  }

  h2,
  p,
  a,
  li {
    color: #151515;
  }

  ul,
  ol {
    list-style-position: inside;
    padding-left: 15px;
  }

  a {
    text-decoration: underline;
  }

  .word-break {
    overflow-wrap: break-word;
    word-wrap: break-word;
  }
`;
